import styled from 'styled-components';
import theme from '../../../../theme/theme';


export const EditButtonContainer = styled.div`
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  gap: 14px;
  margin-right: 20px;
`

export const UserProfileIconContainer = styled.div`
`

export const Header = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${theme.colors.green};
  margin-bottom: 16px;

  & svg {
    margin-right: 10px;

    path {

      stroke: ${theme.colors.green};
      margin-right: 10px;
    }
`

export const ProfileElement = styled.p`
  margin-top: 6px;
  margin-bottom: 6px;
  font-weight: 300;
`

export const Container = styled.div`
  width: 530px;
  margin: auto 0;
  border-radius: 10px;
  background: linear-gradient(360deg, rgba(255, 255, 255, 0.00) 0%, #FFF 65.62%);
  padding: 36px 44px 0 44px;

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    color: ${theme.colors.abbvie}
  }

`
export const ResetLinkContainer = styled.p`
  text-decoration: underline;
  margin-bottom: 18px;
`

export const ResetLink = styled.span`
  text-decoration: underline;
  margin-bottom: 18px;
  font-weight: 700;
  cursor: pointer;
`

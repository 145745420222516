import React, { FC } from 'react';
import { Box, Grid, TableBody, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from "@mui/utils";
import { CellsSwitcher } from "../cells/cellsSwitcher/CellsSwitcher";
import { useTableController } from "../hook/useTableController";
import { TopActions } from "../topActions/TopActions";
import { GlobalLoader } from "../../appLayer/globalLoader/GlobalLoader";
import { IAction, IHeadCell, ITableConfig, ITableData, TableSortOrder } from '../../../../interfaces/common/table';
import {
  ExcelCell,
  ExcelContainer,
  ParagraphNoData,
  TableCellActions,
  TableCellActionsHead,
  TableCellHeader,
  TableContainerCustom,
  TableCustom
} from "./CustomTable.styled";
import { ActionButton } from "../button/ActionButton";
import { ReactComponent as DownloadExcel } from './../../../../assets/icon/getExcel.svg'

interface IProps<Value> {
  // fetched list we pass to component
  tableDataRows: ITableData[];
  // boolean value checking if data was fetched
  isDataDownloaded: boolean;
  // list of names of column headerCustomTable
  headCells: IHeadCell[];
  // object specifying settings for table like order, rows per page etc.
  customTableConfig?: ITableConfig;
  //list of actions
  actions?: IAction[];
  // show/hidden search bar
  isSearchBar?: boolean;
  //added content for search bar section
  searchBarAddedContent?: JSX.Element,
  // custom cell switcher if data are more complex
  cellsSwitcher?: (keyName: string, value: Value, onClick?: () => void, id?: number) => null | JSX.Element;
  minHeight?: string;
  isYearSelect?: boolean
  isDownloadExcel?: boolean
  hideAction?: boolean
  excelHandler?: () => void
}

export const CustomTable: FC<IProps<any>> = ({
                                               hideAction,
                                               tableDataRows,
                                               isDataDownloaded,
                                               headCells,
                                               customTableConfig,
                                               actions,
                                               isSearchBar,
                                               searchBarAddedContent,
                                               cellsSwitcher,
                                               minHeight,
                                               isYearSelect,
                                               isDownloadExcel,
                                               excelHandler
                                             }): JSX.Element => {

  const {
    tableConfig,
    filterChangeHandler,
    createSortHandler,
    drawArray,
    widthActionsHead,
    filter,
    switchFilters
  } = useTableController(headCells, tableDataRows, customTableConfig, actions)

  return isDataDownloaded ? (
    <Grid
      container
      flexDirection={'column'}
      flexWrap={'nowrap'}
      minHeight={minHeight}
    >
      {(isSearchBar || searchBarAddedContent) && <TopActions
        selectedYear={filter}
        isYearSelect={isYearSelect}
        switchFilters={switchFilters}
        isSearchBar={isSearchBar}
        filterChangeHandler={filterChangeHandler}
        filterValue={tableConfig.searchValue}
        searchBarAddedContent={searchBarAddedContent}
      />}
      <TableContainerCustom>
        {drawArray.length > 0 ? <TableCustom>
          <TableHead>
            <TableRow>
              {
                headCells && headCells.length > 0 && headCells.map((headCell, index) => (
                  headCell.label ? <TableCellHeader
                    key={headCell.id}
                    align='left'
                    sortDirection={tableConfig.order === headCell.id ? tableConfig.order : false}
                  >

                    <TableSortLabel
                      active={tableConfig.orderBy === headCell.id}
                      direction={tableConfig.orderBy === headCell.id ? tableConfig.order : TableSortOrder.Ascending}
                      onClick={() => createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {
                        tableConfig.orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {tableConfig.order === "desc" ? "sorted descending" : "sorted ascending"}
                            </Box>
                          )
                          : null
                      }
                    </TableSortLabel>
                  </TableCellHeader> : null
                ))
              }
              {isDownloadExcel ? <ExcelCell>
                  <ExcelContainer onClick={excelHandler}><DownloadExcel/></ExcelContainer>
                </ExcelCell> :

                actions && actions.length > 0 ? <TableCellActionsHead width={widthActionsHead}/> : null
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              drawArray && drawArray.length > 0 && drawArray.map(row => (
                <TableRow key={row.id}>
                  {
                    Object.keys(row).map((key, index) =>
                      <CellsSwitcher
                        id={+row.id}
                        key={row[key]?.toString() + index.toString()}
                        keyName={key?.toString()}
                        value={row[key] ?? ''}
                        type={row.type as string}
                        cellsSwitcher={cellsSwitcher}
                      />
                    )
                  }
                  <TableCellActions> {
                    actions && actions.length > 0 && (
                      <>{actions.map(action => action.custom ?
                        <React.Fragment key={row.id}>{action.custom(row.id, +row.user)}</React.Fragment> :
                        <ActionButton isNotVisible={hideAction && !!row.isVisible} key={action.key}
                                      id={row.id}
                                      userContract={+row[action.actionId as keyof IAction] ?? undefined} {...action} />)}</>)
                  } </TableCellActions>
                </TableRow>
              ))
            }
          </TableBody>
        </TableCustom> : <ParagraphNoData>Brak danych</ParagraphNoData>}
      </TableContainerCustom>

    </Grid>
  ) : <GlobalLoader/>;
}

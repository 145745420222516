import { DrugContainer, BinButton, DrugData } from './Drugs.styled'
import theme from "../../../../../../../theme/theme";
import { Input } from "../../../../../../shared/ui/input/Input";
import React from "react";
import { ReactComponent as Bin } from './../../../../../../../assets/icon/bin.svg'
import { useDrugsVm } from "./useDrugs.vm";
import { IDrug } from "../../../../../../../interfaces/user/draftCase/secondStage";
import { observer } from "mobx-react-lite";

interface IDrugs {
  addDrug: (drug: IDrug) => void
  deleteDrug: (id: string) => void
  id?: string
  isGreyBackground?: boolean
  drugs: IDrug[]
  length?: number
}

export const Drugs: React.FC<IDrugs> = observer(({
                                                   addDrug,
                                                   id,
                                                   isGreyBackground = false,
                                                   deleteDrug,
                                                   drugs,
                                                   length
                                                 }) => {

  const {formik, handleBlur} = useDrugsVm(addDrug, drugs, id)
  return (
    <DrugContainer $isGreyBackground={isGreyBackground}>
      <DrugData>
        <Input
          disabled={isGreyBackground}
          name={"drugName"}
          type={"text"}
          isUpperCase={true}
          label={"Lek:"}
          colorLabel={theme.colors.grey1}
          value={formik.values.drugName}
          onChange={formik.handleChange}
          onBlur={handleBlur}
          borderColor={theme.colors.grey5}
          placeholderColor={theme.colors.grey4}
          background={theme.colors.white}
          placeholder={"Wpisz lek..."}
          isError={formik.touched.drugName && Boolean(formik.errors.drugName) && length === 0}
        />
        <Input
          disabled={isGreyBackground}
          name={"drugDose"}
          type={"text"}
          isUpperCase={true}
          label={"Dawka:"}
          colorLabel={theme.colors.grey1}
          value={formik.values.drugDose}
          onChange={formik.handleChange}
          onBlur={handleBlur}
          borderColor={theme.colors.grey5}
          placeholderColor={theme.colors.grey4}
          background={theme.colors.white}
          placeholder={"Wpisz dawkę leku..."}
          isError={formik.touched.drugDose && Boolean(formik.errors.drugDose) && length === 0}
        />
      </DrugData>
      {id && <BinButton onClick={() => deleteDrug(id)}><Bin/></BinButton>}
    </DrugContainer>
  )
})
import styled from 'styled-components';
import theme from '../../../../../../theme/theme';


export const WritingCommentMode = styled.div`
  position: fixed;
  top:0;
  left: 0;
  z-index: 1099;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
`

export const WritingCommentContainer = styled.div`
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 24px 0;
  z-index: 1100;
  cursor: default;
`

export const WritingCommentForm = styled.div`
  width: 1080px;
  margin: auto;
`

export const WritingCommentTitle = styled.h1`
  font-size: 20px;
  font-weight: 700;
  line-height: 120%; 
  text-transform: uppercase;
  color: ${theme.colors.abbvie};
  margin-bottom: 8px;
  
  & span{
    color: ${theme.colors.orange};
  }
`
export const WritingCommentSubTitle = styled.p`
  color: ${theme.colors.abbvie};
  font-size: 14px;
  font-weight: 500;
  line-height: 150%; 
  letter-spacing: 1.4px;
`

export const WritingCommentValidationInfo = styled.p`
  color: ${theme.colors.orange};
  font-size: 10px;
  font-weight: 700;
  line-height: 120%; 
`
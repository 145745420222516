import {ContainerSixthStage, ParagraphSixthStage, StyledImage} from "./SixthStage.styled";
import {observer} from "mobx-react-lite";
import {useProgressBarStore} from "../../../../../../store/hooks";
import {ReactComponent as Success} from "../../../../../../assets/icon/thick.svg";
import {Grid} from "@mui/material";

export const SixthStage = observer(() => {
  const progressBarStore = useProgressBarStore()
  const isDraft = progressBarStore.typeOfCase === 'draft'
  
  return (
    <Grid container maxWidth={'714px'} justifyContent={'center'} display={'flex'}
          margin={'auto auto'} mt={7}>
      <ContainerSixthStage>
        <StyledImage $isDraft={isDraft}><Success/></StyledImage>
        {isDraft ?
          <ParagraphSixthStage><strong>Szkic opisu przypadku został poprawnie zapisany.</strong> <br/>
            Możesz zobaczyć ten opis <br/> w zakładce MOJE OPISY PRZYPADKÓW {">"} SZKICE
          </ParagraphSixthStage>
          :
          <ParagraphSixthStage><strong>Opis przypadku został wysłany do akceptacji.</strong> <br/>
            Po zaakceptowaniu będzie widoczny dla innych użytkowników serwisu. <br/>
            Zarówno opis "zaakceptowany" jak i "do edycji" będzie dla Ciebie widoczny w zakładce MOJE OPISY PRZYPADKÓW.
          </ParagraphSixthStage>
        }
      </ContainerSixthStage>
    </Grid>
  )
});

import React, { FC } from 'react';
import { Header } from './header/Header';
import { Footer } from './footer/Footer';
import { ContainerLayout, Doctor, UserLayoutStyled } from './UserLayout.styled';
import doctor from './../../../../assets/img/doctor.png'
import { Grid } from "@mui/material";

interface IProps {
  children: React.ReactNode;
  isDoctor?: boolean
}

export const UserLayout: FC<IProps> = ({children, isDoctor = false}) => {

  return (<UserLayoutStyled>
    <ContainerLayout>
      <Header/>
      {children}
    </ContainerLayout>
    <Grid item xs={6}>
      {isDoctor && <Doctor src={doctor}/>}
    </Grid>
    <Footer/>
  </UserLayoutStyled>)
}
import React from "react";
import { observer } from "mobx-react-lite";


import { AppRouting } from "./appRouting/AppRouting";
import { useAuthStore } from '../../../store/hooks';
import { Layer } from "./AppLayer.styled";
import { CheckUser } from "./checkUser/CheckUser";
import { GlobalStyle } from "./globalStyle/GlobalStyle";
import { Notifications } from "./notifications/Notifications";
import { GlobalLoader } from './globalLoader/GlobalLoader';
import { WindowDimensionsController } from "./WindowDimensionController";
import { createTheme, ThemeProvider } from "@mui/material";

export const AppLayer = observer(() => {
  const authStore = useAuthStore();
  const {isUserLoad} = authStore;
  const theme = createTheme({
    palette: {
      error: {
        main: "#A41313"
      }
    }
  })
  return (
    <ThemeProvider theme={theme}>
      <Layer>
        <WindowDimensionsController/>
        <GlobalStyle/>
        <CheckUser/>
        {isUserLoad && <AppRouting/>}
        <Notifications/>
        <GlobalLoader/>
      </Layer>
    </ThemeProvider>
  )
})
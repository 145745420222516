import {makeAutoObservable} from "mobx";
import {ICase, ICaseCard} from "../interfaces/admin/cases/cases";
import {CaseStatus} from "../interfaces/user/draftCase/draft";
import {filterByCoinfection, filterByCurrentPage, filterByStatus, searchByFilter} from "../utils/searchHelpers";

export class CasesStore {
  filters: CaseStatus[] = [];
  coinfections: number[] = []
  cases: ICase[] | null = null;
  listOfMyCases: ICaseCard[] | null = null;
  listOfOtherCases: ICaseCard[] | null = null;
  listOfLast3Cases: ICaseCard[] | null = null;

  isCasesLoaded: boolean = false;
  isListOfMyCasesLoaded: boolean = false;
  isListOfOtherCasesLoaded: boolean = false;
  isListOfOfLast3CasesLoaded: boolean = false;

  cardsPerPage = 12;
  currentPage = 1;
  otherCardsPerPage = 12;

  searchValue: string = ''


  constructor() {
    makeAutoObservable(this);
  }

  setCases(cases: ICase[]) {
    this.cases = cases;
    this.isCasesLoaded = true;
  }

  setListOfMyCases(listOfMyCases: ICaseCard[]) {
    this.listOfMyCases = listOfMyCases
    this.isListOfMyCasesLoaded = true;
    this.currentPage = 1;
  }

  setListOfOtherCases(listOfOtherCases: ICaseCard[]) {
    this.listOfOtherCases = listOfOtherCases;
    this.isListOfOtherCasesLoaded = true;
    this.currentPage = 1;
  }

  setListOfLast3Cases(listOfOfLast3Cases: ICaseCard[]) {
    this.listOfLast3Cases = listOfOfLast3Cases;
    this.isListOfOfLast3CasesLoaded = true;
    this.currentPage = 1;
  }

  resetListOfOfLast3Cases() {
    this.isListOfOfLast3CasesLoaded = false;
    this.currentPage = 1;
  }

  resetCases() {
    this.cases = null
    this.isCasesLoaded = false;
    this.currentPage = 1;
  }

  resetListOfMyCases() {
    this.isListOfMyCasesLoaded = false;
    this.searchValue = ''
    this.filters = []
    this.coinfections = []
    this.currentPage = 1;
  }

  resetListOfOtherCases() {
    this.isListOfOtherCasesLoaded = false;
    this.searchValue = ''
    this.filters = []
    this.coinfections = []
    this.currentPage = 1;
  }

  resetTableConfig() {
    this.cardsPerPage = 8;
    this.currentPage = 1;
    this.otherCardsPerPage = 12;
    this.setFilters([])
  }

  setSearchValue(searchValue: string) {
    this.searchValue = searchValue
    this.resetCurrentPage();
  }

  setFilters = (id: CaseStatus[]) => {
    this.filters = [...id];
    this.resetCurrentPage();
  };

  setCoinfections = (coinfection: number[]) => {
    this.coinfections = [...coinfection];
    this.resetCurrentPage();
  };

  switchFilters = (status: CaseStatus) => {
    if (this.filters.includes(status)) {
      this.setFilters([...this.filters.filter((el: CaseStatus) => el !== status)]);
    } else {
      this.setFilters([...this.filters, status]);
    }
    this.resetCurrentPage();
  };

  switchCoinfectionFilters = (coinfection: number[]) => {
    if (this.coinfections.some(item => coinfection.includes(item))) {
      this.setCoinfections([...this.coinfections.filter((el: number) => !coinfection.includes(el))])
    } else {
      this.setCoinfections([...this.coinfections, ...coinfection])
    }
    this.resetCurrentPage();
  }

  get filteredListOfCases() {

    return this.listOfMyCases
      ?.filter(filterByStatus(this.filters))
      .filter(filterByCoinfection(this.coinfections))
      .filter(searchByFilter(this.searchValue, ['title', 'id']))
  }

  get filteredListOfOtherCases() {

    return this.listOfOtherCases
      ?.filter(filterByCoinfection(this.coinfections))
      .filter(searchByFilter(this.searchValue, ['title', 'id']))
  }

  get filteredListOfCasesByCurrentPage() {
    return this.filteredListOfCases?.filter(filterByCurrentPage(this.currentPage, this.cardsPerPage));
  }

  get isFilteredCasesListNotEmpty() {
    return this.filteredListOfCases && this.filteredListOfCases.length !== 0;
  }

  setCurrentPage = (value: number) => {
    this.currentPage = value;
  };

  get pageCount() {
    return Math.ceil(
      this.filteredListOfCases && this.isFilteredCasesListNotEmpty ? this.filteredListOfCases?.length / this.cardsPerPage : 1
    );
  }

  get filteredListOfOtherCasesByCurrentPage() {
    return this.filteredListOfOtherCases?.filter(filterByCurrentPage(this.currentPage, this.otherCardsPerPage));
  }

  get isFilteredOtherCasesListNotEmpty() {
    return this.filteredListOfOtherCases && this.filteredListOfOtherCases.length !== 0;
  }

  get otherPageCount() {
    return Math.ceil(
      this.filteredListOfOtherCases && this.isFilteredOtherCasesListNotEmpty ? this.filteredListOfOtherCases?.length / this.otherCardsPerPage : 1
    );
  }

  resetCurrentPage = () => (this.currentPage = 1);

}

import * as yup from "yup";

export const therapyArray = [
  {
    id: 1,
    name: 'therapy',
    value: 'nawrót'
  }, {
    id: 2,
    name: 'therapy',
    value: 'całkowity brak odpowiedzi'
  },
  {
    id: 3,
    name: 'therapy',
    value: 'przełom wirusologiczny'
  },
  {
    id: 4,
    name: 'therapy',
    value: 'osiągnięcie SVR a następnie reinfekcja'
  },
  {
    id: 5,
    name: 'therapy',
    value: 'brak odpowiedzi z powodu przerwania terapii z powodu AEs'
  }
]
export const validationSchema = yup.object().shape({
  rbc1: yup
    .string(),
  hgb1: yup
    .string(),
  htc1: yup
    .string(),
  plt1: yup
    .string(),
  wbc1: yup
    .string(),
  mcv1: yup
    .string(),
  mch1: yup
    .string(),
  mchc1: yup
    .string(),
  hcvRna1: yup
    .string(),
  aiat1: yup
    .string(),
  albumin1: yup
    .string(),
  bilirubin1: yup
    .string(),
  simplifiedDiagram: yup
    .string()
    .required(),
  durationOfTherapy: yup
    .string()
    .required(),
  rbc4: yup
    .string(),
  hgb4: yup
    .string(),
  htc4: yup
    .string(),
  plt4: yup
    .string(),
  wbc4: yup
    .string(),
  mcv4: yup
    .string(),
  mch4: yup
    .string(),
  mchc4: yup
    .string(),
  aiat4: yup
    .string(),
  bilirubin4: yup
    .string(),
  bilirubinMonitoring4: yup
    .string(),
  rbc8: yup
    .string(),
  hgb8: yup
    .string(),
  htc8: yup
    .string(),
  plt8: yup
    .string(),
  wbc8: yup
    .string(),
  mcv8: yup
    .string(),
  mch8: yup
    .string(),
  mchc8: yup
    .string(),
  aiat8: yup
    .string(),
  bilirubin8: yup
    .string(),
  bilirubinMonitoring8: yup
    .string()
    .test('isSelectedbilirubinMonitoring8', 'Pole bilirubinMonitoring8 jest wymagane, gdy isSelectedbilirubinMonitoring4 jest tak', function (value) {
      const bilirubinMonitoring4 = this.parent.bilirubinMonitoring4;
      const durationOfTherapy = this.parent.durationOfTherapy;
      if (bilirubinMonitoring4 === 'tak' && (durationOfTherapy === '12 tygodni' || durationOfTherapy === '16 tygodni')) {
        return !!value;
      }
      return true;
    }),
  rbc12: yup
    .string(),
  hgb12: yup
    .string(),
  htc12: yup
    .string(),
  plt12: yup
    .string(),
  wbc12: yup
    .string(),
  mcv12: yup
    .string(),
  mch12: yup
    .string(),
  mchc12: yup
    .string(),
  aiat12: yup
    .string(),
  bilirubin12: yup
    .string(),
  bilirubinMonitoring12: yup
    .string()
    .test('isSelectedbilirubinMonitoring12', 'Pole bilirubinMonitoring12 jest wymagane, gdy isSelectedbilirubinMonitoring8 jest tak', function (value) {
      const bilirubinMonitoring8 = this.parent.bilirubinMonitoring8;
      const durationOfTherapy = this.parent.durationOfTherapy;
      if (bilirubinMonitoring8 === 'tak' && durationOfTherapy === '16 tygodni') {
        return !!value;
      }
      return true;
    }),
  hcvRnaAfterTreatment: yup
    .string(),
  hcvRnaAfterTreatmentDesc: yup
    .string()
    .test('ishcvRnaAfterTreatment', 'Pole hcvRnaAfterTreatmentDesc jest wymagane, gdy hcvRnaAfterTreatment jest wykrywalne.', function (value) {
      const hcvRnaAfterTreatment = this.parent.hcvRnaAfterTreatment;
      if (hcvRnaAfterTreatment === 'wykrywalne') {
        return !!value;
      }
      return true;
    }),
  aiatAfterTreatment: yup
    .string(),
  abdominalUltrasoundAfterTreatment: yup
    .string(),
  abdominalUltrasoundAfterTreatmentCheckbox: yup
    .boolean(),
  hcvRnaAfterTreatment12: yup
    .string()
    .required(),
  abdominalUltrasoundAfterTreatment12: yup
    .string()
    .required(),
})

import * as yup from 'yup'
import { useFormik } from 'formik';
import { useRegisterForm } from "../../form/useRegisterForm.vm";
import { useNavigate, useParams } from "react-router-dom";
import { GeneralLink } from '../../../../../../utils';
import { validatePWZ } from "../../../../../../utils/validatePWZ";
import { ValidationMessages } from "../../../../../../constants/validationMessages";
import { useAuthApi } from "../../../../../../apiHooks";
import { useEffect } from "react";
import { useAuthStore } from "../../../../../../store/hooks";

const containLetterE = (string: string) => /^((?!(0))[0-9^e]{7})$/g.test(string)
export const validationSchema = yup.object().shape({
  fullName: yup
    .string()
    .required(ValidationMessages.EMPTY_FIELD),
  affiliation: yup
    .string()
    .required(ValidationMessages.EMPTY_FIELD),
  pwz: yup
    .string()
    .test('withoutE', 'Numer PWZ powinien składać się z 7 cyfr.', (value) => containLetterE(value ?? ''))
    .test('validatePWZ', ValidationMessages.INVALID_PWZ, (value?: string | number) => validatePWZ(value?.toString() ?? ''))
    .required(ValidationMessages.EMPTY_FIELD),
  password: yup
    .string()
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, ValidationMessages.WEAK_PASSWORD)
    .required(ValidationMessages.EMPTY_FIELD),
  repeatPassword: yup
    .string()
    .test('passwords-match', ValidationMessages.INVALID_PASSWORD, function (value) {
      return this.parent.password === value
    })
    .required(ValidationMessages.EMPTY_FIELD),
  termsAndConditions: yup.boolean().oneOf([true])
})

export const useRegisterViewingForm = () => {
  const {registerViewingUser} = useAuthApi()
  const authStore = useAuthStore()
  const navigate = useNavigate()
  const params = useParams()
  const {verifyToken} = useAuthApi()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      affiliation: '',
      fullName: '',
      pwz: '',
      password: '',
      repeatPassword: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!isErrorCheckbox) {

        const data = {...values, repeatPassword: undefined, pwz: Number(values.pwz)}
        const res = await registerViewingUser(data)
        if (res) {
          navigate(GeneralLink.LOGIN)
        }
      }
    }
  })
  const emailInfo = authStore.emailInfo

  useEffect(() => {
    if (params.token) {
      void verifyToken(params.token)
    }
  }, [verifyToken, params.token])

  const {
    isChecked,
    setChecked,
    isErrorCheckbox,
    confirmForm
  } = useRegisterForm(formik)

  return {formik, isChecked, setChecked, isErrorCheckbox, confirmForm, emailInfo}
}
  
  


import React from 'react'
import { IOption } from '../../../../interfaces/common/form/option';
import { RequiredStar, SelectContainer, StyledSelect } from "./Select.styled";
import { Label } from '../label/Label';

interface IProps {
  selectValue: number;
  elementName: string;
  setValue: (value: number) => void;
  optionsArray: IOption[];
  defaultValue?: string;
  label?: string;
  isRequired?: boolean;
  multiple?: boolean;
  isError?: boolean;
  disabled?: boolean;
  color?: string;
  colorLabel?: string;
  borderColor?: string;
  background?: string;
  placeholderColor?: string;
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
}

export const Select: React.FC<IProps> = ({
                                           multiple,
                                           label,
                                           isError,
                                           isRequired,
                                           selectValue,
                                           elementName,
                                           defaultValue,
                                           setValue,
                                           optionsArray,
                                           color,
                                           colorLabel,
                                           borderColor,
                                           background,
                                           onBlur,
                                           disabled, placeholderColor
                                         }) => {
  return (
    <SelectContainer>
      {label && (
        <Label color={colorLabel} size={'small'} label={<>
          {label}
          {isRequired && <RequiredStar color={colorLabel}>*</RequiredStar>}
        </>}/>
      )}
      <StyledSelect
        multiple={multiple}
        required
        placeholder={defaultValue}
        name={elementName}
        value={selectValue}
        onBlur={onBlur}
        disabled={disabled}
        onChange={(e) => setValue(+e.target.value)}
        id={elementName}
        $border={borderColor}
        $placeholderColor={placeholderColor}
        $background={background}
        $color={color}
        $isError={isError || false}
      >

        <option disabled value={-1}>{defaultValue}</option>
        {optionsArray?.map((option, index: number) => (
          <option key={index} value={option.id}>
            {option.title}
          </option>
        ))}
      </StyledSelect>
    </SelectContainer>
  )
}

import { stages } from "./ProgressBar.helper";

import { useProgressBarStore } from "../../../../../store/hooks";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { GlobalLoader } from "../../../../shared/appLayer/globalLoader/GlobalLoader";
import { DraftLayout } from "../draftLayout/DraftLayout";


export const ProgressBar = observer(() => {
  const progressBarStore = useProgressBarStore();

  const stage = stages.find(stageElement => stageElement.stage === progressBarStore.currentStage);

  useEffect(() => {
    return () => {
      progressBarStore.resetProgressBar()
    }
  }, [progressBarStore])

  if (!stage) {
    return <GlobalLoader/>;
  }

  return (
    <DraftLayout stage={stage} validNext={progressBarStore.maxEditStage > stage.stage}/>
  );

})

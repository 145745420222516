import { Grid } from '@mui/material';
import { Label, NormalText } from '../../ViewCase.styled';
import { checkValueIsEmpty } from '../../ViewCase.helper';
import React, { FC } from 'react';

export interface ResearchWeeksProps {
  week: number;
  rbc: string;
  hgb: string;
  htc: string;
  plt: string;
  wbc: string;
  mcv: string;
  mch: string;
  mchc: string;
  aiat: string;
  bilirubin: string;
  bilirubinMonitoring?: string;
}

export const ResearchWeeks: FC<ResearchWeeksProps> = ({
                                                        week,
                                                        rbc,
                                                        hgb,
                                                        htc,
                                                        plt,
                                                        wbc,
                                                        mcv,
                                                        mch,
                                                        mchc,
                                                        aiat,
                                                        bilirubin,
                                                        bilirubinMonitoring
                                                      }) => {
  return (
    <Grid item xs={12} mt={4}>
      <Grid container borderRadius={1} bgcolor={'white'} p={2}>
        <Grid item xs={12} mb={4}>
          <Label $size={'large'}>
            W {week} TYGODNIU
          </Label>
        </Grid>
        <Grid item xs={12} mb={3}>
          <Label $mb={0}>
            MORFOLOGIA KRWI
          </Label>
        </Grid>
        <Grid item xs={1.5}>
          <Label $mb={0}>
            RBC <span className={'unit'}>[mln/µl]</span>
          </Label>
          <NormalText>
            {checkValueIsEmpty(rbc)}
          </NormalText>
        </Grid>
        <Grid item xs={1.5}>
          <Label $mb={0}>
            HGB <span className={'unit'}>[g/dl]</span>
          </Label>
          <NormalText>
            {checkValueIsEmpty(hgb)}
          </NormalText>
        </Grid>
        <Grid item xs={1.5}>
          <Label $mb={0}>
            HCT <span className={'unit'}>[%]</span>
          </Label>
          <NormalText>
            {checkValueIsEmpty(htc)}
          </NormalText>
        </Grid>
        <Grid item xs={1.5}>
          <Label $mb={0}>
            PLT <span className={'unit'}>[tys/µl]</span>
          </Label>
          <NormalText>
            {checkValueIsEmpty(plt)}
          </NormalText>
        </Grid>
        <Grid item xs={1.5}>
          <Label $mb={0}>
            WBC <span className={'unit'}>[tys/µl]</span>
          </Label>
          <NormalText>
            {checkValueIsEmpty(wbc)}
          </NormalText>
        </Grid>
        <Grid item xs={1.5}>
          <Label $mb={0}>
            MCV <span className={'unit'}>[fl]</span>
          </Label>
          <NormalText>
            {checkValueIsEmpty(mcv)}
          </NormalText>
        </Grid>
        <Grid item xs={1.5}>
          <Label $mb={0}>
            MCH <span className={'unit'}>[pg]</span>
          </Label>
          <NormalText>
            {checkValueIsEmpty(mch)}
          </NormalText>
        </Grid>
        <Grid item xs={1.5}>
          <Label $mb={0}>
            MCHC <span className={'unit'}>[g/dl]</span>
          </Label>
          <NormalText>
            {checkValueIsEmpty(mchc)}
          </NormalText>
        </Grid>

        <Grid item xs={12} mb={2} mt={3}>
          <Label $mb={0}>
            OZNACZENIE AKTYWNOŚCI AIAT <span className={'unit'}>[U/I]</span>
          </Label>
          <NormalText>
            {checkValueIsEmpty(aiat)}
          </NormalText>
        </Grid>
        <Grid item xs={12}>
          <Label>
            OZNACZENIE STĘŻENIA BILIRUBINY <span className={'unit'}>[mg/dl]</span>
          </Label>
          <NormalText>
            {checkValueIsEmpty(bilirubin)}
          </NormalText>
        </Grid>
        {bilirubinMonitoring && <Grid item xs={12}>
          <Label>
            Kontynuacja monitorowania bilirubiny?
          </Label>
          <NormalText>
            {checkValueIsEmpty(bilirubinMonitoring)}
          </NormalText>
        </Grid>}
      </Grid>
    </Grid>
  )
}
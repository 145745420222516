import {useCasesApi, useHomeApi} from '../../../../apiHooks'
import {useEffect} from "react";
import {useAuthStore, useCasesStore, useHomeStore} from '../../../../store/hooks'
import {useNavigate} from "react-router-dom";
import {UserLink} from "../../../../utils";
import {RoleFunction} from "../../../../constants/projectUserRoles";

export const useHome = () => {
  const navigate = useNavigate();
  const {getListOfLastCases} = useHomeApi()
  const {getListOfOtherCases} = useCasesApi()
  const homeStore = useHomeStore()
  const casesStore = useCasesStore()
  const {listOfLastCases, isListOfLastCasesLoaded} = homeStore
  const {listOfLast3Cases, isListOfOfLast3CasesLoaded} = casesStore
  const authStore = useAuthStore();
  const {fullName, affiliation, roleFunction} = authStore;

  const isParticipant = RoleFunction.ROLE_PARTICIPANT === roleFunction

  const addCase = () => {
    navigate(UserLink.ADD_CASE);
  }

  useEffect(() => {
    if (isParticipant) {
      if (!isListOfOfLast3CasesLoaded) {
        void getListOfOtherCases(listOfLast3Cases === null, 3)
      }
    } else {
      if (!isListOfLastCasesLoaded) {
        void getListOfLastCases(listOfLastCases === null)
      }
    }
  }, [getListOfLastCases, getListOfOtherCases, isListOfLastCasesLoaded, isListOfOfLast3CasesLoaded, isParticipant, listOfLast3Cases, listOfLastCases])

  useEffect(() => {
    return () => {
      homeStore.resetListOfLastCases();
      casesStore.resetListOfOfLast3Cases()
    }
  }, [homeStore, casesStore])

  const navigateToOthersCases = () => {
    navigate(UserLink.OTHER_CASES);
  }
  return {
    addCase,
    listOfLastCases,
    listOfLast3Cases,
    fullName,
    affiliation,
    navigateToOthersCases,
    isParticipant
  }
}
import { MouseEvent, useState } from "react";
import { FormikValues } from "formik";

export const useRegisterForm = (formik: FormikValues) => {
  const [isChecked, setIsChecked] = useState(false);

  const setChecked = (checked: boolean) => {
    setIsChecked(checked)
  }

  const confirmForm = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    formik.handleSubmit()
  }

  const isErrorCheckbox = formik.submitCount > 0 && !isChecked

  return {isChecked, setChecked, isErrorCheckbox, confirmForm}
}
  
  


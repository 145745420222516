import { Grid } from "@mui/material";
import { useFirstStageVm } from "./useFirestStage.vm";
import { Input } from "../../../../../shared/ui/input/Input";
import theme from "../../../../../../theme/theme";
import { SelectContainer, RadioForm } from './FirstStage.styled'
import { RadioBox } from "../../../../../shared/ui/radioBox/RadioBox";
import { Label } from "../../../../../shared/ui/label/Label";
import React from "react";
import { FormikProvider } from 'formik';
import { selectOptions } from "./firstStage.helper";
import { Select } from "../../../../../shared/ui/select/Select";
import { observer } from "mobx-react-lite";

export const FirstStage = observer(() => {
  const {formik, setOption} = useFirstStageVm()

  return (
    <Grid container maxWidth={'714px'} justifyContent={'flex-end'} display={'flex'} margin={'0 auto'} mt={7} mb={7}>
      <Grid item xs={12} alignSelf={'center'}>
        <Input isRequired={true}
               size={'small'}
               label={"Tytuł"}
               isUpperCase={true}
               name={"title"}
               type={"text"}
               borderColor={theme.colors.grey5}
               placeholderColor={theme.colors.grey4}
               colorLabel={theme.colors.grey1}
               background={theme.colors.white}
               value={formik.values.title}
               onChange={formik.handleChange}
               onBlur={formik.handleBlur}
               placeholder={"Wpisz tytuł opisu przypadku..."}
               isError={formik.touched.title && Boolean(formik.errors.title)}
        />
      </Grid>
      <Grid item xs={12} display={'flex'} mt={1} gap={'20px'} flexWrap={'nowrap'}>
        <Grid item xs={3}>
          <Input isRequired={true}
                 label={"Inicjały pacjenta"}
                 isUpperCase={true}
                 name={"initials"}
                 type={"text"}
                 maxLength={3}
                 colorLabel={theme.colors.grey1}
                 value={formik.values.initials}
                 onChange={formik.handleChange}
                 onBlur={formik.handleBlur}
                 borderColor={theme.colors.grey5}
                 placeholderColor={theme.colors.grey4}
                 background={theme.colors.white}
                 placeholder={"Wpisz inicjały..."}
                 isError={formik.touched.initials && Boolean(formik.errors.initials)}
          />
        </Grid>
        <Grid item xs={3}>
          <Input isRequired={true}
                 label={"Wiek"}
                 isUpperCase={true}
                 name={"age"}
                 type={"text"}
                 colorLabel={theme.colors.grey1}
                 value={formik.values.age as string}
                 onChange={formik.handleChange}
                 onBlur={formik.handleBlur}
                 borderColor={theme.colors.grey5}
                 placeholderColor={theme.colors.grey4}
                 background={theme.colors.white}
                 placeholder={"Wpisz wiek..."}
                 isError={formik.touched.age && Boolean(formik.errors.age)}
          />
        </Grid>
        <Grid item xs={6}>
          <Label size={'small'} isUpperCase={true} color={theme.colors.grey1} label={'płeć'}
                 isRequired={true}/>
          <RadioForm>
            <RadioBox isError={formik.touched.gender && Boolean(formik.errors.gender)} id={'gender1'} name={'gender'}
                      value={'kobieta'} checked={formik.values.gender === 'kobieta'}
                      onChange={formik.handleChange}/>
            <RadioBox isError={formik.touched.gender && Boolean(formik.errors.gender)} id={'gender2'} name={'gender'}
                      value={'mężczyzna'} checked={formik.values.gender === 'mężczyzna'}
                      onChange={formik.handleChange}/>
          </RadioForm>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={1}>
        <SelectContainer $isError={formik.touched.coInfection && Boolean(formik.errors.coInfection)}
                         $isSelected={formik.values.coInfection < 0}>
          <FormikProvider value={formik}>
            <Select
              isRequired={true}
              onBlur={formik.handleBlur}
              color={formik.values.coInfection < 0 ? theme.colors.grey2 : theme.colors.black}
              colorLabel={theme.colors.grey1}
              borderColor={theme.colors.grey1}
              background={'white'}
              defaultValue={'Wybierz'}
              label={'Koinfekcja'}
              selectValue={formik.values.coInfection as number}
              elementName={'coInfection'}
              setValue={setOption}
              optionsArray={selectOptions}/>
          </FormikProvider>
        </SelectContainer>
      </Grid>
      <Grid item xs={12}>
        <Label size={'small'} isUpperCase={true} color={theme.colors.grey1} label={'PACJENT LECZONY PIERWSZORAZOWO'}
               isRequired={true}/>
        <RadioForm>
          <RadioBox isError={formik.touched.firstTreatment && Boolean(formik.errors.firstTreatment)}
                    id={'patientTreatedOnce1'}
                    name={'firstTreatment'} value={'tak'}
                    checked={formik.values.firstTreatment === 'tak'}
                    onChange={formik.handleChange}/>
          <RadioBox isError={formik.touched.firstTreatment && Boolean(formik.errors.firstTreatment)}
                    id={'patientTreatedOnce2'}
                    name={'firstTreatment'} value={'nie'}
                    checked={formik.values.firstTreatment === 'nie'}
                    onChange={formik.handleChange}/>
        </RadioForm>
      </Grid>
    </Grid>
  )
})
import { useUserTop } from './uerUserTop';
import { ButtonProfile, ButtonsContainer, ButtonTop, StyledLink, TextTop } from './UserTop.styled';
import logout from './../../../../assets/icon/logout.svg'
import userProfile from './../../../../assets/icon/userProfile.svg'
import home from './../../../../assets/icon/home.svg'
import { UserLink } from "../../../../utils";
import { observer } from "mobx-react-lite";

export const UserTop = observer(() => {
  const {isUser, isActiveLink, profileHandler, logoutHandler, fullName, isActiveHome} = useUserTop();

  const content = <>{fullName.slice(0, 35)}{fullName.length >= 35 && '...'}
    <img src={userProfile} alt={'user-profile'}/></>

  return (
    <ButtonsContainer>
      {isUser && <ButtonTop $isActive={isActiveHome} onClick={profileHandler}>
        <img src={home} alt={'home'}/>
      </ButtonTop>}
      <ButtonProfile $isActive={isActiveLink}>
        {isUser ? <StyledLink to={UserLink.PROFILE}>{content}</StyledLink> : <TextTop>{content}</TextTop>}
      </ButtonProfile>
      <ButtonTop onClick={logoutHandler}>
        <img src={logout} alt={'logout'}/>
      </ButtonTop>
    </ButtonsContainer>
  )
})

import {useFormik} from 'formik';
import {RegexRules} from '../../../../../utils';
import * as yup from 'yup';
import {useProfileApi} from "../../../../../apiHooks/useProfileApi";
import {useProfileStore} from "../../../../../store/hooks";
import {IProfile} from "../../../../../interfaces/user/profile/profile";
import {validatePWZ} from "../../../../../utils/validatePWZ";
import {ValidationMessages} from "../../../../../constants/validationMessages";

export const validationSchema = yup.object().shape({
  fullName: yup
    .string()
    .required(),
  affiliation: yup
    .string()
    .required(),
  pwz: yup
    .string()
    .matches(/^((?!(0))[0-9]{7})$/g)
    .required(),
  phone: yup
    .string()
    .matches(/^(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}$/u)
    .required(),
  email: yup
    .string()
    .matches(RegexRules.EMAIL)
    .required(),
})

export const useEditProfile = (closeModal: () => void) => {
  const {editProfile} = useProfileApi();
  const {profile} = useProfileStore();

  const formik = useFormik<IProfile>({
    enableReinitialize: true,
    initialValues: {
      affiliation: profile ? profile.affiliation : '',
      fullName: profile ? profile.fullName : '',
      pwz: profile ? profile.pwz : '',
      workPlace: profile ? profile.workPlace : '',
      email: profile ? profile.email : '',
      phone: profile ? profile.phone : ''
    },
    validationSchema: validationSchema,
    validate: (values) => {
      const errors: Partial<IProfile> = {};
      if (values.pwz && !validatePWZ(values.pwz.toString())) {
        errors.pwz = ValidationMessages.INVALID_PWZ;
      }
      return errors;
    },
    onSubmit: async (values) => {
      const res = await editProfile(values)
      if (res) {
        closeModal()
      }
    }
  })
  
  return {formik}
}
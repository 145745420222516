import {useEffect, useState} from 'react';

import {createTableRows} from '../../../shared/customTables/utils/createTableRows';
import {useModal} from '../../../shared/modal/useModal.vm';
import {IUserForm} from '../../../../interfaces/admin/user/userForm';
import {IHeadCell} from '../../../../interfaces/common/table';
import {RoleFunction} from '../../../../constants/projectUserRoles';
import {ModalType} from '../../../../constants/modalType';
import {checkPermission} from '../../../../utils/checkPermission';
import {useAuthStore, useUsersStore} from '../../../../store/hooks';
import {useUsersApi} from '../../../../apiHooks';
import {ReactComponent as DeleteIcon} from '../../../../assets/icon/delete.svg';
import {ReactComponent as EditIcon} from '../../../../assets/icon/edit.svg';
import theme from '../../../../theme/theme';

export const useViewingUser = () => {
  const {roleFunction} = useAuthStore();
  const [actionId, setActionId] = useState<number | null>(null);
  const {getViewingUsers, deleteUser, getUser, editUser} = useUsersApi();
  const usersStore = useUsersStore();
  const {viewingUsers, userEdit, isViewingUsersLoaded} = usersStore;
  const {handleModal, isModalOpened, closeModal, modalType} = useModal();

  useEffect(() => {
    if (!isViewingUsersLoaded) {
      if (viewingUsers === null) {
        void getViewingUsers()
      } else {
        void getViewingUsers(false)
      }
    }
  }, [getViewingUsers, viewingUsers, isViewingUsersLoaded])

  useEffect(() => {
    return () => usersStore.resetViewingUsers();
  }, [usersStore])


  const editModalHandler = async (id: number | string) => {
    const user = await getUser(+id);
    if (user) {
      handleModal(ModalType.EDIT_MODAL);
    }
  }

  const editHandler = async (userForm: IUserForm) => {
    const isEdit = await editUser(userForm);

    if (isEdit) {
      await handleModal(ModalType.EDIT_CONFIRMATION_MODAL);
      setActionId(null);
      usersStore.clearUserEdit();
      await getViewingUsers();
    }
    return null;
  }

  const deleteHandler = (id: number | string) => {
    handleModal(ModalType.DELETE_MODAL);
    setActionId(+id);
  }

  const deleteHandlerYes = async () => {
    if (viewingUsers && actionId) {
      const isDelete = await deleteUser(actionId);
      if (isDelete) {
        await getViewingUsers();
        setActionId(null);
        handleModal(ModalType.DELETE_CONFIRMATION_MODAL);
      }
    }
  }

  const generateActions = () => {
    if (roleFunction && checkPermission(roleFunction, [RoleFunction.ROLE_ADMIN])) {
      return [
        {
          key: "edit",
          actionHandler: editModalHandler,
          background: theme.colors.abbvie,
          actionElement: <EditIcon/>,
          width: 52,
        },
        {
          key: "delete",
          actionHandler: deleteHandler,
          background: theme.colors.blue2,
          actionElement: <DeleteIcon/>,
          width: 52,
        }
      ]
    }
    return [];
  }

  const headCells: IHeadCell[] = [
    {id: 'fullName', numeric: false, label: 'Imię i nazwisko'},
    {id: 'phone', numeric: false, label: 'Numer kontaktowy'},
    {id: 'email', numeric: false, label: 'Email',}
  ]

  const tableDataRows = createTableRows(viewingUsers ?? [], headCells, false)

  return {
    viewingUsersListIsNotNull: viewingUsers !== null,
    headCells,
    generateActions,
    tableDataRows,
    modalType,
    isModalOpened,
    editModalHandler,
    editHandler,
    userEdit,
    deleteHandlerYes,
    closeModal
  }
}

export const convertFirstTreatment = (value: boolean | string | null) => {
  switch (value) {
    case 'tak' :
      return true
    case 'nie' :
      return false
    case '' :
      return null
    case true:
      return 'tak'
    case false:
      return 'nie'
    case null:
      return ''
    default:
      return ''
  }
}
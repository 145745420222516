import React, { ReactNode } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  InputLabel,
  SelectChangeEvent, checkboxClasses
} from '@mui/material';

import { MultipleSelectStyled } from './MultipleSelect.styled';
import { IOption } from "../../../../interfaces/common/form/option";
import { ReactComponent as SelectDropdown } from './../../../../assets/icon/selectDropdown.svg'

interface IProps {
  selectedValues: number[]
  elementName: string;
  title?: string;
  subtitle?: string;
  options: IOption[],
  handleChange: (event: SelectChangeEvent<number[]>, child: ReactNode) => void
  subtitleLength?: number
  isError?: boolean
}

export const MultipleSelect: React.FC<IProps> = ({
                                                   isError,
                                                   subtitle,
                                                   selectedValues,
                                                   elementName,
                                                   options,
                                                   handleChange,
                                                   title,
                                                   subtitleLength
                                                 }) => {

  const renderedValue = (selectedValues: number[]) => {
    if (subtitleLength) {
      if (selectedValues.includes(0)) {
        return '';
      } else {
        return ` (${subtitleLength})`;
      }
    } else {
      if (selectedValues.includes(0)) {
        return 'Wszystko';
      } else {
        return selectedValues.map(id => options.find((option: IOption) => option.id === id)?.title).join(', ');
      }
    }
  };

  return (
    <MultipleSelectStyled>
      <FormControl fullWidth={true}>
        {title && <h5>{title}</h5>}
        {selectedValues.length === 0 && <InputLabel sx={{
          paddingBottom: '4px',
          '&.MuiFormLabel-root': {
            transform: 'translate(14px, 7px)'
          }
        }} id={elementName}>{subtitle}</InputLabel>}
        <Select
          IconComponent={SelectDropdown}
          error={isError}
          labelId={elementName}
          id={elementName}
          multiple
          value={selectedValues}
          onChange={handleChange}
          renderValue={(selected: number[]) => (
            <div>
              {subtitleLength && subtitle ? subtitle : subtitle ? subtitle + ': ' : ''}
              {renderedValue(selected)}
            </div>
          )}
          sx={{
            color: '#646464',
            borderRadius: '10px',
            padding: '0 10px',
            fontSize: '16px',
            '.MuiOutlinedInput-notchedOutline': {border: '2px solid #E0E0E0;'},
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: 'none',
              },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
              {
                border: '2px solid #E0E0E0;',
              },
          }}
        >
          {options.map((option: IOption) => (
            <MenuItem key={option.id} value={option.id}
                      sx={{padding: '10px 16px', height: '28px', margin: '4px 0'}}>
              <ListItemText primary={option.title}/>
              <Checkbox
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: '#071C47'
                  }
                }}
                checked={selectedValues.includes(option.id) || selectedValues.includes(0)}/>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </MultipleSelectStyled>
  );
};
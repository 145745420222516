import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Stages } from "../../progressBar/ProgressBar.helper";
import { useProgressBarStore } from "../../../../../../store/hooks";
import { IThirdStage } from "../../../../../../interfaces/user/draftCase/thirdStage";
import { useParams } from "react-router-dom";
import { validationSchema } from "./thirdStage.helper";
import { useCaseApi } from '../../../../../../apiHooks/useCaseApi';
import { setAllFieldsTouched } from "../stages.helper";

export const useThirdStageVm = () => {
  const [formInjection, setFormInjection] = useState<boolean | null>(null);
  const progressBarStore = useProgressBarStore();
  const {thirdStage, isClicked} = progressBarStore;
  const {
    postAbdominalFileAfterTreatmentThirdStage,
    deleteAbdominalFileAfterTreatmentThirdStage,
    postAbdominalFileAfterTreatment12ThirdStage,
    deleteAbdominalFileAfterTreatment12ThirdStage
  } = useCaseApi()
  const params = useParams()
  const {id} = params
  const formik = useFormik<IThirdStage>({
    enableReinitialize: true,
    initialValues: {
      rbc1: '',
      hgb1: '',
      htc1: '',
      plt1: '',
      wbc1: '',
      mcv1: '',
      mch1: '',
      mchc1: '',
      hcvRna1: '',
      aiat1: '',
      albumin1: '',
      bilirubin1: '',
      simplifiedDiagram: '',
      durationOfTherapy: '',
      rbc4: '',
      hgb4: '',
      htc4: '',
      plt4: '',
      wbc4: '',
      mcv4: '',
      mch4: '',
      mchc4: '',
      aiat4: '',
      bilirubin4: '',
      bilirubinMonitoring4: '',
      rbc8: '',
      hgb8: '',
      htc8: '',
      plt8: '',
      wbc8: '',
      mcv8: '',
      mch8: '',
      mchc8: '',
      aiat8: '',
      bilirubin8: '',
      bilirubinMonitoring8: '',
      rbc12: '',
      hgb12: '',
      htc12: '',
      plt12: '',
      wbc12: '',
      mcv12: '',
      mch12: '',
      mchc12: '',
      aiat12: '',
      bilirubin12: '',
      bilirubinMonitoring12: '',
      hcvRnaAfterTreatment: '',
      hcvRnaAfterTreatmentDesc: '',
      aiatAfterTreatment: '',
      abdominalUltrasoundAfterTreatment: '',
      abdominalUltrasoundAfterTreatmentCheckbox: false,
      hcvRnaAfterTreatment12: '',
      abdominalUltrasoundAfterTreatment12: '',
      abdominalUltrasoundAfterTreatmentCheckbox12: false
    },
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
    }
  })

  const {errors, setFieldValue, setFieldTouched, validateForm, values} = formik

  useEffect(() => {
    if (formInjection) {
      if (Object.keys(errors).length === 0) {
        progressBarStore.setMaxEditStage(Stages.FOURTH);
      } else {
        progressBarStore.setMaxEditStage(Stages.THIRD);
      }
    }
  }, [errors, formInjection, progressBarStore])

  useEffect(() => {
    if (isClicked) {
      setAllFieldsTouched(formik.values, setFieldTouched)
      void validateForm()
    }
  }, [isClicked, validateForm, setFieldTouched, formik.values])

  useEffect(() => {
    if (progressBarStore.thirdStage && id) {
      setFieldValue('rbc1', progressBarStore.thirdStage.rbc1 ?? '')
      setFieldValue('hgb1', progressBarStore.thirdStage.hgb1 ?? '')
      setFieldValue('htc1', progressBarStore.thirdStage.htc1 ?? '')
      setFieldValue('plt1', progressBarStore.thirdStage.plt1 ?? '')
      setFieldValue('wbc1', progressBarStore.thirdStage.wbc1 ?? '')
      setFieldValue('mcv1', progressBarStore.thirdStage.mcv1 ?? '')
      setFieldValue('mch1', progressBarStore.thirdStage.mch1 ?? '')
      setFieldValue('mchc1', progressBarStore.thirdStage.mchc1 ?? '')
      setFieldValue('hcvRna1', progressBarStore.thirdStage.hcvRna1 ?? '')
      setFieldValue('albumin1', progressBarStore.thirdStage.albumin1 ?? '')
      setFieldValue('aiat1', progressBarStore.thirdStage.aiat1 ?? '')
      setFieldValue('bilirubin1', progressBarStore.thirdStage.bilirubin1 ?? '')
      setFieldValue('simplifiedDiagram', progressBarStore.thirdStage.simplifiedDiagram ?? '')
      setFieldValue('durationOfTherapy', progressBarStore.thirdStage.durationOfTherapy ?? '')
      setFieldValue('rbc4', progressBarStore.thirdStage.rbc4 ?? '')
      setFieldValue('hgb4', progressBarStore.thirdStage.hgb4 ?? '')
      setFieldValue('htc4', progressBarStore.thirdStage.htc4 ?? '')
      setFieldValue('plt4', progressBarStore.thirdStage.plt4 ?? '')
      setFieldValue('wbc4', progressBarStore.thirdStage.wbc4 ?? '')
      setFieldValue('mcv4', progressBarStore.thirdStage.mcv4 ?? '')
      setFieldValue('mch4', progressBarStore.thirdStage.mch4 ?? '')
      setFieldValue('mchc4', progressBarStore.thirdStage.mchc4 ?? '')
      setFieldValue('aiat4', progressBarStore.thirdStage.aiat4 ?? '')
      setFieldValue('bilirubin4', progressBarStore.thirdStage.bilirubin4 ?? '')
      setFieldValue('bilirubinMonitoring4', progressBarStore.thirdStage.bilirubinMonitoring4 ?? '')
      setFieldValue('rbc8', progressBarStore.thirdStage.rbc8 ?? '')
      setFieldValue('hgb8', progressBarStore.thirdStage.hgb8 ?? '')
      setFieldValue('htc8', progressBarStore.thirdStage.htc8 ?? '')
      setFieldValue('plt8', progressBarStore.thirdStage.plt8 ?? '')
      setFieldValue('wbc8', progressBarStore.thirdStage.wbc8 ?? '')
      setFieldValue('mcv8', progressBarStore.thirdStage.mcv8 ?? '')
      setFieldValue('mch8', progressBarStore.thirdStage.mch8 ?? '')
      setFieldValue('mchc8', progressBarStore.thirdStage.mchc8 ?? '')
      setFieldValue('aiat8', progressBarStore.thirdStage.aiat8 ?? '')
      setFieldValue('bilirubin8', progressBarStore.thirdStage.bilirubin8 ?? '')
      setFieldValue('bilirubinMonitoring8', progressBarStore.thirdStage.bilirubinMonitoring8 ?? '')
      setFieldValue('rbc12', progressBarStore.thirdStage.rbc12 ?? '')
      setFieldValue('hgb12', progressBarStore.thirdStage.hgb12 ?? '')
      setFieldValue('htc12', progressBarStore.thirdStage.htc12 ?? '')
      setFieldValue('plt12', progressBarStore.thirdStage.plt12 ?? '')
      setFieldValue('wbc12', progressBarStore.thirdStage.wbc12 ?? '')
      setFieldValue('mcv12', progressBarStore.thirdStage.mcv12 ?? '')
      setFieldValue('mch12', progressBarStore.thirdStage.mch12 ?? '')
      setFieldValue('mchc12', progressBarStore.thirdStage.mchc12 ?? '')
      setFieldValue('aiat12', progressBarStore.thirdStage.aiat12 ?? '')
      setFieldValue('bilirubin12', progressBarStore.thirdStage.bilirubin12 ?? '')
      setFieldValue('bilirubinMonitoring12', progressBarStore.thirdStage.bilirubinMonitoring12 ?? '')
      setFieldValue('hcvRnaAfterTreatment', progressBarStore.thirdStage.hcvRnaAfterTreatment ?? '')
      setFieldValue('hcvRnaAfterTreatmentDesc', progressBarStore.thirdStage.hcvRnaAfterTreatmentDesc ?? '')
      setFieldValue('aiatAfterTreatment', progressBarStore.thirdStage.aiatAfterTreatment ?? '')
      setFieldValue('abdominalUltrasoundAfterTreatment', progressBarStore.thirdStage.abdominalUltrasoundAfterTreatment ?? '')
      setFieldValue('abdominalUltrasoundAfterTreatmentCheckbox', progressBarStore.thirdStage.abdominalUltrasoundAfterTreatmentCheckbox)
      setFieldValue('hcvRnaAfterTreatment12', progressBarStore.thirdStage.hcvRnaAfterTreatment12 ?? '')
      setFieldValue('abdominalUltrasoundAfterTreatment12', progressBarStore.thirdStage.abdominalUltrasoundAfterTreatment12 ?? '')
      setFieldValue('abdominalUltrasoundAfterTreatmentCheckbox12', progressBarStore.thirdStage.abdominalUltrasoundAfterTreatmentCheckbox12)
    }
  }, [progressBarStore, errors, setFieldValue, id])

  useEffect(() => {
    if (formik.dirty || Object.keys(formik.touched).length !== 0 || Object.keys(formik.errors).length === 0) {
      const thirdStage: IThirdStage = {
        rbc1: formik.values.rbc1,
        hgb1: formik.values.hgb1,
        htc1: formik.values.htc1,
        plt1: formik.values.plt1,
        wbc1: formik.values.wbc1,
        mcv1: formik.values.mcv1,
        mch1: formik.values.mch1,
        mchc1: formik.values.mchc1,
        hcvRna1: formik.values.hcvRna1,
        albumin1: formik.values.albumin1,
        aiat1: formik.values.aiat1,
        bilirubin1: formik.values.bilirubin1,
        simplifiedDiagram: formik.values.simplifiedDiagram,
        durationOfTherapy: formik.values.durationOfTherapy,
        rbc4: formik.values.rbc4,
        hgb4: formik.values.hgb4,
        htc4: formik.values.htc4,
        plt4: formik.values.plt4,
        wbc4: formik.values.wbc4,
        mcv4: formik.values.mcv4,
        mch4: formik.values.mch4,
        mchc4: formik.values.mchc4,
        aiat4: formik.values.aiat4,
        bilirubin4: formik.values.bilirubin4,
        bilirubinMonitoring4: formik.values.bilirubinMonitoring4,
        rbc8: formik.values.rbc8,
        hgb8: formik.values.hgb8,
        htc8: formik.values.htc8,
        plt8: formik.values.plt8,
        wbc8: formik.values.wbc8,
        mcv8: formik.values.mcv8,
        mch8: formik.values.mch8,
        mchc8: formik.values.mchc8,
        aiat8: formik.values.aiat8,
        bilirubin8: formik.values.bilirubin8,
        bilirubinMonitoring8: formik.values.bilirubinMonitoring8,
        rbc12: formik.values.rbc12,
        hgb12: formik.values.hgb12,
        htc12: formik.values.htc12,
        plt12: formik.values.plt12,
        wbc12: formik.values.wbc12,
        mcv12: formik.values.mcv12,
        mch12: formik.values.mch12,
        mchc12: formik.values.mchc12,
        aiat12: formik.values.aiat12,
        bilirubin12: formik.values.bilirubin12,
        bilirubinMonitoring12: formik.values.bilirubinMonitoring12,
        hcvRnaAfterTreatment: formik.values.hcvRnaAfterTreatment,
        hcvRnaAfterTreatmentDesc: formik.values.hcvRnaAfterTreatmentDesc,
        aiatAfterTreatment: formik.values.aiatAfterTreatment,
        abdominalUltrasoundAfterTreatment: formik.values.abdominalUltrasoundAfterTreatment,
        abdominalUltrasoundAfterTreatmentFiles: progressBarStore.thirdStage ? progressBarStore.thirdStage.abdominalUltrasoundAfterTreatmentFiles : [],
        abdominalUltrasoundAfterTreatmentCheckbox: formik.values.abdominalUltrasoundAfterTreatmentCheckbox,
        hcvRnaAfterTreatment12: formik.values.hcvRnaAfterTreatment12,
        abdominalUltrasoundAfterTreatment12: formik.values.abdominalUltrasoundAfterTreatment12,
        abdominalUltrasoundAfterTreatmentFiles12: progressBarStore.thirdStage ? progressBarStore.thirdStage.abdominalUltrasoundAfterTreatmentFiles12 : [],
        abdominalUltrasoundAfterTreatmentCheckbox12: formik.values.abdominalUltrasoundAfterTreatmentCheckbox12,
      }
      setFormInjection(true);
      progressBarStore.setThirdStage(thirdStage);
    } else {
      progressBarStore.setMaxEditStage(Stages.THIRD);
    }
  }, [formik.errors, formik.values, progressBarStore, formik.touched, formik.dirty])

  const addAbdominalUltrasoundAfterTreatmentFile = async (file: File | null) => {
    if (file && id) {
      await postAbdominalFileAfterTreatmentThirdStage(+id, file);
    }
  }

  const removeAbdominalUltrasoundAfterTreatmentFile = async (idFile: number | string) => {
    if (idFile && id) {
      await deleteAbdominalFileAfterTreatmentThirdStage(+id, idFile);
    }
  }

  const addAbdominalUltrasoundAfterTreatmentFile12 = async (file: File | null) => {
    if (file && id) {
      await postAbdominalFileAfterTreatment12ThirdStage(+id, file);
    }
  }

  const removeAbdominalUltrasoundAfterTreatmentFile12 = async (idFile: number | string) => {
    if (idFile && id) {
      await deleteAbdominalFileAfterTreatment12ThirdStage(+id, idFile);
    }
  }

  useEffect(() => {
    if (values.simplifiedDiagram === 'tak') {
      setFieldValue('rbc4', '')
      setFieldValue('hgb4', '')
      setFieldValue('htc4', '')
      setFieldValue('plt4', '')
      setFieldValue('wbc4', '')
      setFieldValue('mcv4', '')
      setFieldValue('mch4', '')
      setFieldValue('mchc4', '')
      setFieldValue('aiat4', '')
      setFieldValue('bilirubin4', '')
      setFieldValue('bilirubinMonitoring4', '')
    }
    if (values.durationOfTherapy === '8 tygodni' || values.simplifiedDiagram === 'tak') {
      setFieldValue('rbc8', '')
      setFieldValue('hgb8', '')
      setFieldValue('htc8', '')
      setFieldValue('plt8', '')
      setFieldValue('wbc8', '')
      setFieldValue('mcv8', '')
      setFieldValue('mch8', '')
      setFieldValue('mchc8', '')
      setFieldValue('aiat8', '')
      setFieldValue('bilirubin8', '')
      setFieldValue('bilirubinMonitoring8', '')
    }
    if (values.durationOfTherapy === '8 tygodni' || values.durationOfTherapy === '12 tygodni' || values.simplifiedDiagram === 'tak') {
      setFieldValue('rbc12', '')
      setFieldValue('hgb12', '')
      setFieldValue('htc12', '')
      setFieldValue('plt12', '')
      setFieldValue('wbc12', '')
      setFieldValue('mcv12', '')
      setFieldValue('mch12', '')
      setFieldValue('mchc12', '')
      setFieldValue('aiat12', '')
      setFieldValue('bilirubin12', '')
      setFieldValue('bilirubinMonitoring12', '')
    }
    if (values.hcvRnaAfterTreatment === 'niewykrywalne') {
      setFieldValue('hcvRnaAfterTreatmentDesc', '')
    }

  }, [setFieldValue, values])

  return {
    formik,
    abdominalUltrasoundAfterTreatmentFiles: thirdStage && thirdStage.abdominalUltrasoundAfterTreatmentFiles ? thirdStage.abdominalUltrasoundAfterTreatmentFiles : [],
    abdominalUltrasoundAfterTreatmentFiles12: thirdStage && thirdStage.abdominalUltrasoundAfterTreatmentFiles12 ? thirdStage.abdominalUltrasoundAfterTreatmentFiles12 : [],
    addAbdominalUltrasoundAfterTreatmentFile,
    removeAbdominalUltrasoundAfterTreatmentFile,
    addAbdominalUltrasoundAfterTreatmentFile12,
    removeAbdominalUltrasoundAfterTreatmentFile12
  }

}
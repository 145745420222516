import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useProgressBarStore } from "../../../../../../store/hooks";
import { Stages } from "../../progressBar/ProgressBar.helper";
import { useParams } from "react-router-dom";
import { IFirstStage } from "../../../../../../interfaces/user/draftCase/firstStage";
import { RegexRules } from "../../../../../../utils";
import { setAllFieldsTouched } from "../stages.helper";

export const validationSchema = yup.object().shape({
  title: yup
    .string()
    .required(),
  age: yup
    .number()
    .required(),
  gender: yup
    .string()
    .required(),
  initials: yup
    .string()
    .max(3)
    .matches(RegexRules.INITIALS)
    .required(),
  coInfection: yup
    .number()
    .min(0)
    .required(),
  firstTreatment: yup
    .string()
    .required(),
})

export const useFirstStageVm = () => {
  const progressBarStore = useProgressBarStore();
  const {isClicked} = progressBarStore;
  const [formInjection, setFormInjection] = useState<boolean | null>(null);

  const params = useParams()
  const {id} = params
  const formik = useFormik<IFirstStage>({
    enableReinitialize: true,
    initialValues: {
      title: '',
      age: '',
      gender: '',
      initials: '',
      coInfection: -1,
      firstTreatment: '',
    },
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
    }
  })

  const {setFieldValue, errors, validateForm, setFieldTouched} = formik

  useEffect(() => {
    if (formInjection) {
      if (Object.keys(errors).length === 0) {
        progressBarStore.setMaxEditStage(Stages.SECOND);
      } else if (Object.keys(formik.touched).length !== 0 && Object.keys(errors).length === 0) {
        progressBarStore.setMaxEditStage(Stages.FIRST);
      }
    }
  }, [errors, formInjection, progressBarStore, formik.touched])

  useEffect(() => {
    if (isClicked) {
      setAllFieldsTouched(formik.values, setFieldTouched)
      void validateForm()
    }
  }, [isClicked, validateForm, setFieldTouched, formik.values])

  useEffect(() => {
    if (progressBarStore.firstStage && id) {
      setFieldValue('title', progressBarStore.firstStage.title ?? '')
      setFieldValue('age', progressBarStore.firstStage.age ?? '')
      setFieldValue('gender', progressBarStore.firstStage.gender ?? '')
      setFieldValue('initials', progressBarStore.firstStage.initials ?? '')
      setFieldValue('coInfection', progressBarStore.firstStage.coInfection ?? -1)
      setFieldValue('firstTreatment', progressBarStore.firstStage.firstTreatment ?? '')
    }
  }, [progressBarStore, setFieldValue, id])

  useEffect(() => {
    if ((formik.isValid)) {
      const firstStage: IFirstStage = {
        title: formik.values.title,
        age: formik.values.age,
        gender: formik.values.gender,
        initials: formik.values.initials,
        coInfection: formik.values.coInfection,
        firstTreatment: formik.values.firstTreatment
      }
      setFormInjection(true);
      progressBarStore.setFirstStage({...firstStage});
    } else {
      progressBarStore.setMaxEditStage(Stages.FIRST);
    }


  }, [formik.errors, formik.values, progressBarStore, formik.touched, formik.isValid])

  const setOption = (value: number) => {
    setFieldValue('coInfection', value)
  }

  return {
    formik,
    setOption
  }
}
import { useApiCall } from "./useApiCall";
import { useCallback } from "react";
import { IUserLoginResponse } from "../interfaces/admin/user/userLoginResponse";
import { IUserForm } from "../interfaces/admin/user/userForm";
import { IRole } from "../interfaces/common/roles"
import { IResponse } from "../interfaces/common/response";
import { IViewingUser } from "../interfaces/admin/user/viewingUser";
import { IPublishingUser } from "../interfaces/admin/user/publishingUser";
import { IOthersAdmins } from "../interfaces/admin/user/othersAdmins";
import { useUsersStore } from '../store/hooks/useUsersStore';

export const useUsersApi = () => {
  const {api} = useApiCall();
  const usersStore = useUsersStore()

  const addUser = useCallback(
    async (userForm: IUserForm) => {
      const res: IUserLoginResponse = await api.apiInstance.post('user', userForm, true);
      return !!res;
    },
    [api.apiInstance]
  );

  const getPublishingUsers = useCallback(async (isLoading: boolean = true) => {
    const res: IResponse<IPublishingUser> = await api.apiInstance.get('users/publishing', isLoading);
    if (res) {
      usersStore.setPublishingUsers(res.items)
    }
  }, [api.apiInstance, usersStore])

  const getViewingUsers = useCallback(async (isLoading: boolean = true) => {
    const res: IResponse<IViewingUser> = await api.apiInstance.get('users/participant', isLoading);
    if (res) {
      usersStore.setViewingUsers(res.items)
    }
  }, [api.apiInstance, usersStore])

  const getOtherUsers = useCallback(async (roles: IRole[], isLoading: boolean = true) => {
    const res: IResponse<IOthersAdmins> = await api.apiInstance.get('users', isLoading);
    if (res) {
      usersStore.setOtherUsers(res.items, roles)
    }
  }, [api.apiInstance, usersStore])

  const resendActivateEmail = useCallback(async (id: number) => {
    const res = await api.apiInstance.post(`/user/${id}/resend-activate-email`, {});
    return !!res;
  }, [api.apiInstance])

  const deleteUser = useCallback(async (id: number) => {
    const res = await api.apiInstance.delete(`/user/${id}`);
    return !!res;
  }, [api.apiInstance])

  const editUser = useCallback(async (userForm: IUserForm) => {
    if (userForm.id) {
      const res = await api.apiInstance.put(`/user/${userForm.id}`, userForm);
      return !!res;
    }

  }, [api.apiInstance])

  const getUser = useCallback(async (id: number) => {
    const res: IUserForm = await api.apiInstance.get(`/user/${id}`);
    if (res) {
      usersStore.setUserEdit(res, id)
    }
    return !!res;
  }, [api.apiInstance, usersStore])


  return {
    getUser,
    deleteUser,
    editUser,
    resendActivateEmail,
    addUser,
    getPublishingUsers,
    getViewingUsers,
    getOtherUsers
  }
}
import {useCasesStore} from '../store/hooks';
import {useCallback} from "react";

import {ICases} from "../interfaces/admin/cases/cases";
import {useApiCall} from "./useApiCall";

export const useCasesApi = () => {
  const {api} = useApiCall();
  const casesStore = useCasesStore();

  const getCases = useCallback(
    async (isLoading: boolean = true) => {
      const res: ICases = await api.apiInstance.get(`situations`, isLoading);
      casesStore.setCases(res.items)
      return !!res
    },
    [api.apiInstance, casesStore]
  );

  const acceptCase = useCallback(
    async (id: number, isPublic: boolean) => {
      const res: ICases = await api.apiInstance.put(`situation/${id}/accept`, {isPublic});
      casesStore.setCases(res.items)
      return !!res
    },
    [api.apiInstance, casesStore]
  );

  const editStatusCase = useCallback(
    async (id: number, isPublic: boolean) => {
      const res: ICases = await api.apiInstance.put(`situation/${id}/is-public`, {isPublic});
      return !!res
    },
    [api.apiInstance]
  );
  
  const rejectCase = useCallback(
    async (id: number, comment: string) => {
      const res: ICases = await api.apiInstance.put(`situation/${id}/reject`, {comment});
      if (res) {
        casesStore.setCases(res.items)
        return !!res
      }

    },
    [api.apiInstance, casesStore]
  );

  const getListOfMyCases = useCallback(
    async (isLoading: boolean = true) => {
      const res = await api.apiInstance.get('situations/my', isLoading);
      if (res) {
        casesStore.setListOfMyCases(res.items)
      }

    },
    [api.apiInstance, casesStore]
  );

  const getListOfOtherCases = useCallback(
    async (isLoading: boolean = true, limit?: number) => {
      const res = await api.apiInstance.get(limit ? `situations/public?limit=${limit}` : 'situations/public', isLoading);
      if (res) {
        if (limit) {
          casesStore.setListOfLast3Cases(res.items)
        } else {
          casesStore.setListOfOtherCases(res.items)
        }
      }
    },
    [api.apiInstance, casesStore]
  );


  return {
    getCases,
    acceptCase,
    editStatusCase,
    rejectCase,
    getListOfMyCases,
    getListOfOtherCases
  }
}
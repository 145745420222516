import { IFirstStage } from "../interfaces/user/draftCase/firstStage";
import { ICase } from "../interfaces/user/draftCase/draft";
import { switchGender } from "./switchGender";
import { convertFirstTreatment } from "./convertFirstTreatment";
import { convertAge } from "./convertAge";

export const convertFirstStageToRequest = (firstStage: IFirstStage): IFirstStage => {
  return {
    ...firstStage,
    firstTreatment: convertFirstTreatment(firstStage.firstTreatment),
    age: convertAge(firstStage.age),
    gender: switchGender(firstStage.gender)
  }
}

export const convertResponseToFirstStage = (draftCase: ICase): IFirstStage => {
  return {
    title: draftCase.title,
    age: draftCase.age ? String(draftCase.age) : '',
    initials: draftCase.initials,
    coInfection: draftCase.coInfection,
    firstTreatment: convertFirstTreatment(draftCase.firstTreatment),
    gender: switchGender(draftCase.gender)
  }
}

export const convertResponseToSecondStage = (draftCase: ICase) => {
  return {
    dateOfDetection: draftCase.dateOfDetection,
    placeOfDetection: draftCase.placeOfDetection,
    routeOfInfection: draftCase.routeOfInfection,
    routeOfInfectionDesc: draftCase.routeOfInfectionDesc,
    scaleScore: draftCase.scaleScore,
    metavirDesc: draftCase.metavirDesc,
    apriDesc: draftCase.apriDesc,
    clinicalSignsOfCirrhosis: draftCase.clinicalSignsOfCirrhosis,
    extrahepaticManifestationsOfHCV: draftCase.extrahepaticManifestationsOfHCV,
    hcvRna: draftCase.hcvRna,
    hcvRnaUnit: draftCase.hcvRnaUnit,
    rbc: draftCase.rbc,
    hgb: draftCase.hgb,
    htc: draftCase.htc,
    plt: draftCase.plt,
    wbc: draftCase.wbc,
    mcv: draftCase.mcv,
    mch: draftCase.mch,
    mchc: draftCase.mchc,
    aiat: draftCase.aiat,
    albumin: draftCase.albumin,
    bilirubin: draftCase.bilirubin,
    prothrombin: draftCase.prothrombin,
    prothrombinUnit: draftCase.prothrombinUnit,
    creatinine: draftCase.creatinine,
    egfr: draftCase.egfr,
    antiHiv: draftCase.antiHiv,
    hbs: draftCase.hbs,
    abdominalUltrasound: draftCase.abdominalUltrasound,
    abdominalUltrasoundFiles: draftCase.abdominalUltrasoundFiles,
    abdominalUltrasoundCheckbox: draftCase.abdominalUltrasoundCheckbox,
    liverFibrosis: draftCase.liverFibrosis,
    fibroSkanDesc: draftCase.fibroSkanDesc,
    apriLiverDesc: draftCase.apriLiverDesc,
    fibLiverDesc: draftCase.fibLiverDesc,
    liverBiopsy: draftCase.liverBiopsy,
    liverBiopsyDesc: draftCase.liverBiopsyDesc,
    liverBiopsyFiles: draftCase.liverBiopsyFiles,
    liverBiopsyCheckbox: draftCase.liverBiopsyCheckbox,
    hcvGenotype: draftCase.hcvGenotype,
    hcvGenotypeMix: draftCase.hcvGenotypeMix,
    presenceOfComorbidities: draftCase.presenceOfComorbidities,
    dataHiv: draftCase.dataHiv,
    dataHbv: draftCase.dataHbv,
    previousHistory: draftCase.previousHistory,
    otherElements: draftCase.otherElements,
    otherElementsDesc: draftCase.otherElementsDesc,
    patientPreviouslyTreatedWithAntivirals: draftCase.patientPreviouslyTreatedWithAntivirals,
    patientPreviouslyTreatedCount: draftCase.patientPreviouslyTreatedCount,
    patientPreviouslyTreatedItems: draftCase.patientPreviouslyTreatedItems,
    drugs: draftCase.drugs,
    potentialDrugInteractions: draftCase.potentialDrugInteractions,
    potentialDrugInteractionFiles: draftCase.potentialDrugInteractionFiles,
  }
}

export const convertResponseToThirdStage = (draftCase: ICase) => {

  return {
    rbc1: draftCase.rbc1,
    hgb1: draftCase.hgb1,
    htc1: draftCase.htc1,
    plt1: draftCase.plt1,
    wbc1: draftCase.wbc1,
    mcv1: draftCase.mcv1,
    mch1: draftCase.mch1,
    mchc1: draftCase.mchc1,
    hcvRna1: draftCase.hcvRna1,
    albumin1: draftCase.albumin1,
    aiat1: draftCase.aiat1,
    bilirubin1: draftCase.bilirubin1,
    simplifiedDiagram: draftCase.simplifiedDiagram,
    durationOfTherapy: draftCase.durationOfTherapy,
    rbc4: draftCase.rbc4,
    hgb4: draftCase.hgb4,
    htc4: draftCase.htc4,
    plt4: draftCase.plt4,
    wbc4: draftCase.wbc4,
    mcv4: draftCase.mcv4,
    mch4: draftCase.mch4,
    mchc4: draftCase.mchc4,
    aiat4: draftCase.aiat4,
    bilirubin4: draftCase.bilirubin4,
    bilirubinMonitoring4: draftCase.bilirubinMonitoring4,
    rbc8: draftCase.rbc8,
    hgb8: draftCase.hgb8,
    htc8: draftCase.htc8,
    plt8: draftCase.plt8,
    wbc8: draftCase.wbc8,
    mcv8: draftCase.mcv8,
    mch8: draftCase.mch8,
    mchc8: draftCase.mchc8,
    aiat8: draftCase.aiat8,
    bilirubin8: draftCase.bilirubin8,
    bilirubinMonitoring8: draftCase.bilirubinMonitoring8,
    rbc12: draftCase.rbc12,
    hgb12: draftCase.hgb12,
    htc12: draftCase.htc12,
    plt12: draftCase.plt12,
    wbc12: draftCase.wbc12,
    mcv12: draftCase.mcv12,
    mch12: draftCase.mch12,
    mchc12: draftCase.mchc12,
    aiat12: draftCase.aiat12,
    bilirubin12: draftCase.bilirubin12,
    bilirubinMonitoring12: draftCase.bilirubinMonitoring12,
    hcvRnaAfterTreatment: draftCase.hcvRnaAfterTreatment,
    hcvRnaAfterTreatmentDesc: draftCase.hcvRnaAfterTreatmentDesc,
    aiatAfterTreatment: draftCase.aiatAfterTreatment,
    abdominalUltrasoundAfterTreatment: draftCase.abdominalUltrasoundAfterTreatment,
    abdominalUltrasoundAfterTreatmentFiles: draftCase.abdominalUltrasoundAfterTreatmentFiles,
    abdominalUltrasoundAfterTreatmentCheckbox: draftCase.abdominalUltrasoundAfterTreatmentCheckbox,
    hcvRnaAfterTreatment12: draftCase.hcvRnaAfterTreatment12,
    abdominalUltrasoundAfterTreatment12: draftCase.abdominalUltrasoundAfterTreatment12,
    abdominalUltrasoundAfterTreatmentFiles12: draftCase.abdominalUltrasoundAfterTreatmentFiles12,
    abdominalUltrasoundAfterTreatmentCheckbox12: draftCase.abdominalUltrasoundAfterTreatmentCheckbox12,
  }
}

export const convertResponseToFourthStage = (draftCase: ICase) => {
  return {
    infoAboutTherapy: draftCase.infoAboutTherapy,
    conclusion: draftCase.conclusion,
    conclusionFiles: draftCase.conclusionFiles,
    conclusionCheckbox: draftCase.conclusionCheckbox,
  }
}

export const convertYesNoOption = (value: boolean) => {
  return value ? 'Tak' : 'Nie';
}
import { SearchCustom } from './SearchBar.styled'
import { Input } from "../input/Input";
import React from "react";
import { ReactComponent as Search } from "../../../../assets/icon/search.svg";
import { observer } from "mobx-react-lite";

type SearchEvent = React.ChangeEvent<HTMLInputElement>;

interface IProps {
  filterValue: string;
  filterChangeHandler: (event: string) => void;
}

export const SearchBar: React.FC<IProps> = observer(({filterValue, filterChangeHandler}) => {
  return (
    <SearchCustom>
      <Input width={'341px'}
             placeholder={'Wyszukaj...'}
             type="text"
             margin={'0'}
             name={'searchBar'}
             borderColor={'rgba(7, 28, 71, 0.30)'}
             background={'#A6B2C4'}
             placeholderColor={'rgba(7, 28, 71, 0.70)'}
             value={filterValue}
             onChange={e => {
               const event = e as SearchEvent;
               filterChangeHandler(event.target.value)
             }}/>
      <Search/>
    </SearchCustom>

  )
})
import { stages } from "./progressBar/ProgressBar.helper";
import { useProgressBarStore } from "../../../../store/hooks";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const useDraftCaseVm = () => {
  const progressBarStore = useProgressBarStore()
  const [afterReset, setAfterReset] = useState(false);
  const {currentStage} = progressBarStore
  const {id} = useParams();

  const stageObject = stages.find(el => el.stage === currentStage) || undefined
  useEffect(() => {
    if (!afterReset) {
      setAfterReset(true)
      progressBarStore.resetProgressBar();
      progressBarStore.setIsLoaded(true);
    }
  }, [id, progressBarStore, afterReset])
  return {
    stageObject
  }
}

import { ButtonContainer, ButtonContainerCoinfection } from './MyCases.styled'
import { Checkbox, FormControlLabel } from "@mui/material";
import { CaseStatus } from "../../../../interfaces/user/draftCase/draft";
import React, { useEffect } from "react";
import { useCasesStore } from "../../../../store/hooks";
import { useCasesApi } from "../../../../apiHooks";
import { UserLink } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import { COINFECTIONTYPE } from "../../../../constants/coinfection";


export const useMyCases = () => {
  const casesStore = useCasesStore();
  const navigate = useNavigate();

  const {
    cases,
    isListOfMyCasesLoaded,
    switchFilters,
    filters,
    filteredListOfCasesByCurrentPage,
    coinfections,
    searchValue,
    pageCount,
    currentPage,
    setCurrentPage
  } = casesStore;

  const {getListOfMyCases} = useCasesApi();

  useEffect(() => {
    if (!isListOfMyCasesLoaded) {
      if (cases === null) {
        void getListOfMyCases()
      } else {
        void getListOfMyCases(false)
      }
    }
  }, [getListOfMyCases, cases, isListOfMyCasesLoaded])

  useEffect(() => {
    return () => {
      casesStore.resetListOfMyCases();
    }
  }, [casesStore])

  const addedContent = (
    <ButtonContainer>
      <span>{"Filtruj po:"}</span>
      <FormControlLabel
        control={
          <Checkbox
            onChange={() => switchFilters(CaseStatus.ACCEPT)}
            checked={filters.includes(CaseStatus.ACCEPT)}
          />
        }
        label={"zaakceptowany"}
      />

      <FormControlLabel
        control={
          <Checkbox
            onChange={() => switchFilters(CaseStatus.REJECT)}
            checked={filters.includes(CaseStatus.REJECT)}
          />
        }
        label={"do edycji"}
      />

      <FormControlLabel
        control={
          <Checkbox
            onChange={() => switchFilters(CaseStatus.DRAFT)}
            checked={filters.includes(CaseStatus.DRAFT)}
          />
        }
        label={"szkic"}
      />

      <FormControlLabel
        control={
          <Checkbox
            onChange={() => switchFilters(CaseStatus.SENT)}
            checked={filters.includes(CaseStatus.SENT)}
          />
        }
        label={'oczekujący'}
      />
    </ButtonContainer>
  );

  const addedCoinfection = (
    <ButtonContainerCoinfection>
      <FormControlLabel
        control={
          <Checkbox
            onChange={() => casesStore.switchCoinfectionFilters([COINFECTIONTYPE.NOCOINFECTION])}
            checked={coinfections.includes(COINFECTIONTYPE.NOCOINFECTION)}
          />
        }
        label={"bez koinfekcji"}
      />

      <FormControlLabel
        control={
          <Checkbox
            onChange={() => casesStore.switchCoinfectionFilters([COINFECTIONTYPE.HIVHCV, COINFECTIONTYPE.HBVHCV, COINFECTIONTYPE.HIVHBHCV])}
            checked={coinfections.some(type => [COINFECTIONTYPE.HIVHCV, COINFECTIONTYPE.HBVHCV, COINFECTIONTYPE.HIVHBHCV].includes(type))}
          />
        }
        label={"z koinfekcjami"}
      />
    </ButtonContainerCoinfection>
  );

  const addCase = () => {
    navigate(UserLink.ADD_CASE);
  }

  const onChangeSearchHandler = (value: string) => casesStore.setSearchValue(value);

  return {
    addedContent,
    filteredListOfCasesByCurrentPage,
    addedCoinfection,
    addCase,
    searchValue,
    onChangeSearchHandler,
    pageCount,
    currentPage,
    setCurrentPage
  }
}
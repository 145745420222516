import * as yup from 'yup';
import { generateNavigationLink, RegexRules } from '../../../../../utils';
import { useFormik } from 'formik';
import { MouseEvent } from 'react';
import { ValidationMessages } from '../../../../../constants/validationMessages';
import { useAuthApi } from '../../../../../apiHooks';
import { useNavigate } from 'react-router-dom';
import { validatePWZ } from '../../../../../utils/validatePWZ';
import { checkRoleCategory } from '../../../../../utils/checkPermission';

export const validationSchema = yup.object().shape({
  pwz: yup
    .string()
    .test('validatePWZ', ValidationMessages.INVALID_PWZ, (value?: string | number) => value ? validatePWZ(value?.toString()) : true),
  login: yup
    .string()
    .matches(RegexRules.EMAIL, ValidationMessages.INVALID_EMAIL)
    .required(ValidationMessages.EMPTY_FIELD),
  password: yup
    .string()
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, ValidationMessages.WEAK_PASSWORD)
    .required(ValidationMessages.EMPTY_FIELD),
});

export const useLoginForm = () => {
  const navigate = useNavigate()
  const authApi = useAuthApi();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      pwz: '',
      login: '',
      password: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const convertPWZ = formik.values.pwz === "" ? -1 : Number.parseInt(formik.values.pwz);
      const res = await authApi.loginUser(formik.values.login, formik.values.password, convertPWZ);
      if (res) {
        navigate(generateNavigationLink(res.roleFunction, checkRoleCategory(res.roleFunction)));
        window.location.reload();
      }
    }
  })

  const confirmForm = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    formik.handleSubmit()
  }

  return {formik, confirmForm}
}
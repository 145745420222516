import {UserLayout} from '../../../shared/user/layout/UserLayout';
import {SearchBar} from "../../../shared/ui/searchBar/SearchBar";
import {useMyCases} from "./useMyCases.vm";
import {observer} from "mobx-react-lite";

import {CaseCard} from "../../../shared/user/cards/CaseCard";

import {FilterContainer, NoCases, Section} from './MyCases.styled'
import React from "react";
import {Grid} from "@mui/material";
import {Button} from "../../../shared/button/Button";
import theme from "../../../../theme/theme";
import {MuiPagination} from "../../../shared/pagination/MuiPagination";

export const MyCases = observer(() => {
  const {
    addedContent,
    filteredListOfCasesByCurrentPage,
    addedCoinfection,
    addCase,
    searchValue,
    onChangeSearchHandler,
    pageCount,
    currentPage,
    setCurrentPage
  } = useMyCases()

  return (<UserLayout>
    <FilterContainer>
      {addedCoinfection}
      <Button
        margin={'auto auto  auto 20px'}
        clickHandler={addCase}
        text={'+ dodaj nowy opis przypadku'}
        backgroundColor={theme.colors.orange}
        color={theme.colors.b3}
        width={'263px'}
        size={'tiny'}/>
      {addedContent}
      <SearchBar filterValue={searchValue} filterChangeHandler={onChangeSearchHandler}/>
    </FilterContainer>
    <Section>
      {filteredListOfCasesByCurrentPage && filteredListOfCasesByCurrentPage.length > 0 ?
        <Grid container flexDirection={'column'} justifyContent={'space-between'} alignItems={'center'}>
          <Grid container gap={'20px'} display={'flex'}>
            {filteredListOfCasesByCurrentPage.map(({
                                                     status,
                                                     id,
                                                     customId,
                                                     coInfection,
                                                     title,
                                                     updatedAt
                                                   }) => (
              <Grid item width={'100%'} maxWidth={'15%'} key={id}>
                <CaseCard
                  key={id}
                  id={id}
                  status={status}
                  customId={customId}
                  updatedAt={updatedAt}
                  coInfection={coInfection}
                  title={title}/>
              </Grid>
            ))}
          </Grid>
          <MuiPagination
            count={pageCount}
            page={currentPage}
            onChange={(e, value: number) => {
              setCurrentPage(value);
            }}/>
        </Grid>
        : <NoCases>Nie ma jeszcze żadnych dodanych opisów przypadków.</NoCases>}
    </Section>
  </UserLayout>)
})
import { stages } from "../progressBar/ProgressBar.helper";
import { IStage } from "../../../../../interfaces/user/draftCase/progressBar";
import { useCaseStore, useProgressBarStore } from "../../../../../store/hooks";
import { useEffect, useState } from "react";
import { useCaseApi } from "../../../../../apiHooks/useCaseApi";
import { useNavigate, useParams } from "react-router-dom";
import { convertFirstStageToRequest } from "../../../../../utils/convertStageObjects";
import { generateLink, UserLink } from "../../../../../utils";


export interface IDraftLayout {
  validNext: boolean;
  stage: IStage;
}


export const useDraftLayout = ({validNext, stage}: IDraftLayout) => {
  const progressBarStore = useProgressBarStore()
  const {currentStage, firstStage, secondStage, thirdStage, fourthStage} = progressBarStore
  const stageObject = stages.find(el => el.stage === currentStage) || undefined
  const {stagesSequence, component} = stageObject as IStage
  const [prevArrow, nextArrow] = stagesSequence || undefined;
  const caseStore = useCaseStore();
  const navigate = useNavigate();
  const {
    postDraftCase,
    getCase,
    putFirstStageDraftCase,
    putSecondStageDraftCase,
    putThirdStageDraftCase,
    putFourthStageDraftCase
  } = useCaseApi();

  const {id} = useParams();

  const [initialConvert, setInitialConvert] = useState(false);

  useEffect(() => {
    if (id) {
      void getCase(+id);
      setInitialConvert(true);
    }
  }, [getCase, id])

  useEffect(() => {
    if (caseStore.case) {
      progressBarStore.convert(caseStore.case, initialConvert);
      setInitialConvert(false);
    }
  }, [caseStore.case, initialConvert, progressBarStore])

  useEffect(() => {
    return () => {
      caseStore.resetCase();
    }
  }, [caseStore])

  const prevArrowHandler = async () => {
    if (prevArrow.stage) {
      progressBarStore.setCurrentStage(prevArrow.stage);
    }
  }

  const nextArrowHandler = async () => {
    if (validNext && nextArrow.stage) {
      progressBarStore.setClicked(false)
      if (nextArrow.stage === 2 && firstStage) {
        if (!id) {
          const idParam = await postDraftCase(convertFirstStageToRequest(firstStage));
          if (idParam) {
            navigate(generateLink(UserLink.EDIT_CASE, idParam))
          }
        } else {
          await putFirstStageDraftCase(+id, convertFirstStageToRequest(firstStage));
        }
      } else if (nextArrow.stage === 3 && secondStage && id) {
        const res = await putSecondStageDraftCase(+id, secondStage);
        if (res) {
          await getCase(+id);
        }
      } else if (nextArrow.stage === 4 && thirdStage && id) {
        const res = await putThirdStageDraftCase(+id, thirdStage);
        if (res) {
          await getCase(+id);
        }
      } else if (nextArrow.stage === 5 && fourthStage && id) {
        const res = await putFourthStageDraftCase(+id, fourthStage);
        if (res) {
          await getCase(+id);
        }
      }
      progressBarStore.setCurrentStage(nextArrow.stage);
    } else {
      progressBarStore.setClicked(true)
    }
  }

  return {
    prevArrowHandler,
    isPrevVisible: prevArrow.access,
    isNextVisible: nextArrow.access,
    nextArrowHandler,
    currentStage,
    component
  }

}
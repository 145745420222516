import { makeAutoObservable } from 'mobx';
import { INavLink } from '../interfaces/common/link';
import { generateNavbar } from '../components/shared/admin/layout/AdminLayout.helper';
import { RoleCategory, RoleFunction } from '../constants/projectUserRoles';
import { IUserLoginResponse } from '../interfaces/admin/user/userLoginResponse';
import { AdminLink, GeneralLink, UserLink } from '../utils';
import { checkRoleCategory } from '../utils/checkPermission';

export class AuthStore {
  private _links: INavLink[] | null = null;
  private _isUserLoad: boolean = false;
  private _isLoggedIn = false;
  private _isSessionExpired = false;
  private _fullName: string = "";
  private _user: string = "";
  private _affiliation?: string = "";
  private _emailInfo: string = "";
  private _roleCategory: RoleCategory = RoleCategory.GUEST;
  private _roleFunction: RoleFunction = RoleFunction.ROLE_GUEST;
  private _navigationLink: GeneralLink | AdminLink | UserLink | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  appLogin(response: IUserLoginResponse) {
    this.isLoggedIn = true;
    this.roleFunction = response.roleFunction;
    this.fullName = response.fullName;
    this.affiliation = response.affiliation;
    this.user = response.user;
    this.roleCategory = checkRoleCategory(response.roleFunction);
    this.links = generateNavbar(response.roleFunction);
    this.isSessionExpired = false;
    this.isUserLoad = true
  }


  get isUserLoad(): boolean {
    return this._isUserLoad;
  }

  set isUserLoad(value: boolean) {
    this._isUserLoad = value;
  }

  get links(): INavLink[] | null {
    return this._links;
  }

  set links(value: INavLink[] | null) {
    this._links = value;
  }

  get agg(): boolean {
    return this._isLoggedIn;
  }

  get affiliation(): string | undefined {
    return this._affiliation;
  }

  set isLoggedIn(value: boolean) {
    this._isLoggedIn = value;
  }

  get isSessionExpired(): boolean {
    return this._isSessionExpired;
  }

  set isSessionExpired(value: boolean) {
    this._isSessionExpired = value;
  }

  get fullName(): string {
    return this._fullName;
  }

  set fullName(value: string) {
    this._fullName = value;
  }

  get user(): string {
    return this._user;
  }

  set user(value: string) {
    this._user = value;
  }

  set affiliation(value: string | undefined) {
    this._affiliation = value;
  }

  get roleCategory(): RoleCategory {
    return this._roleCategory;
  }

  set roleCategory(value: RoleCategory) {
    this._roleCategory = value;
  }

  get roleFunction(): RoleFunction {
    return this._roleFunction;
  }

  set roleFunction(value: RoleFunction) {
    this._roleFunction = value;
  }

  get navigationLink(): GeneralLink | AdminLink | UserLink | null {
    return this._navigationLink;
  }

  set navigationLink(value: GeneralLink | AdminLink | UserLink | null) {
    this._navigationLink = value;
  }

  emailInfoVerify(emailInfo: string) {
    this._emailInfo = emailInfo;
  }

  get emailInfo() {
    return this._emailInfo;
  }

}

export enum ModalType {
  DELETE_CONFIRMATION_MODAL,
  EDIT_CONFIRMATION_MODAL,
  EDIT_MODAL,
  DELETE_MODAL,
  ACCEPT_CASE_MODAL,
  EDIT_STATUS_CASE_MODAL,
  ACCEPT_CASE_CONFIRMATION_MODAL,
  REJECT_CASE_MODAL,
  REJECT_CASE_CONFIRMATION_MODAL,
  ADD_MODAL,
}
export const convertAge = (value: number | string | null) => {
  switch (typeof value) {
    case 'string':
      return value.trim() === '' ? null : +value;
    case 'number':
      return value;
    case 'object':
      return '';
    default:
      return null;
  }
};
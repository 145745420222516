import { useFormik } from "formik";
import * as yup from "yup";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { IDrug } from "../../../../../../../interfaces/user/draftCase/secondStage";
import { setAllFieldsTouched } from "../../stages.helper";
import { useProgressBarStore } from "../../../../../../../store/hooks";


export const validationSchema = yup.object().shape({
  drugName: yup
    .string()
    .required(),
  drugDose: yup
    .string()
    .required(),
})

export const useDrugsVm = (addDrug: (drug: IDrug) => void, drugs: IDrug[], id?: string) => {
  const myRef = useRef() as MutableRefObject<HTMLDivElement>
  const [clickedOutside, setClickedOutside] = useState(false);
  const progressBarStore = useProgressBarStore()
  const drugItem = drugs.find(el => el.id === id)

  const handleBlur = (e: any) => {
    if (drugName && drugDose) {
      addDrug({id: id || new Date().toISOString(), drugDose, drugName})
      setClickedOutside(true)
      setFieldValue('drugName', '')
      setFieldValue('drugDose', '')
      formik.resetForm()
      progressBarStore.setClicked(false)
    }
  };

  const formik = useFormik<IDrug>({
    enableReinitialize: true,
    initialValues: {
      drugName: '',
      drugDose: '',
    },
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
    }
  })

  const {setFieldValue, validateForm, setFieldTouched} = formik

  useEffect(() => {
    if (drugItem) {
      setFieldValue('drugName', drugItem.drugName)
      setFieldValue('drugDose', drugItem.drugDose)
    }
  }, [drugItem, setFieldValue])

  const {drugName, drugDose} = formik.values

  useEffect(() => {
    if (progressBarStore.isClicked && drugs.length === 0) {
      setAllFieldsTouched(formik.values, setFieldTouched)
      void validateForm()
    }
  }, [progressBarStore.isClicked, validateForm, setFieldTouched, formik.values, drugs.length])

  return {
    formik,
    myRef,
    clickedOutside,
    handleBlur
  }
}
import { Item } from './RadioBox.styled'
import React, { ChangeEvent } from "react";

interface IProps {
  id: string,
  name: string;
  value: string
  checked: boolean
  isError?: boolean
  isUpperCase?: boolean
  onChange: (e: ChangeEvent<any>) => void
}

export const RadioBox: React.FC<IProps> = ({id, isError, name, value, checked, onChange, isUpperCase = true}) => {
  return (
    <Item $isError={isError} $isUpperCase={isUpperCase}>
      <input
        type="radio"
        id={id}
        name={name}
        checked={checked}
        value={value}
        onChange={onChange}

      />
      <label htmlFor={id}>{value}</label>
    </Item>
  )
}
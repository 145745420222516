import styled from 'styled-components';

export const RoleSelectContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  
  span {
    margin-bottom: 15px;
  }
`
import { IFirstStage } from "../../../../../interfaces/user/draftCase/firstStage";
import { ISecondStage } from "../../../../../interfaces/user/draftCase/secondStage";
import { IThirdStage } from "../../../../../interfaces/user/draftCase/thirdStage";
import { IFourthStage } from "../../../../../interfaces/user/draftCase/fouthStage";
import { useCaseApi } from "../../../../../apiHooks";
import { useParams } from "react-router-dom";

export const useSaveDraftBtnVm = () => {
  const params = useParams()
  const {saveDraft} = useCaseApi()
  const saveCaseHandler = async (values: IFirstStage | ISecondStage | IThirdStage | IFourthStage) => {
    await saveDraft(values, params.id ? +params.id : undefined)
  }

  return {
    saveCaseHandler
  }
}

import React from 'react';
import { RouteObject } from 'react-router-dom';

import AddUser from '../../../../pages/admin/addUser/AddUser';
import { AdminLink } from '../../../../../utils';
import { generalLinks } from './RoutesGuest';
import { PublishingUsers } from '../../../../pages/admin/publishingUsers/PublishingUsers';
import { ViewingUsers } from '../../../../pages/admin/viewingUsers/ViewingUsers';
import { Others } from '../../../../pages/admin/others/Others';
import { OthersAdmins } from '../../../../pages/admin/othersAdmins/OthersAdmins';
import { Redirector } from '../../../../pages/admin/redirector/Redirector';

export const adminLinks: RouteObject[] = [
  {
    path: AdminLink.REDIRECTOR,
    element: <Redirector/>
  },
  {
    path: AdminLink.ADD_USER,
    element: <AddUser/>
  },
  {
    path: AdminLink.PUBLISHING_USERS,
    element: <PublishingUsers/>
  },
  {
    path: AdminLink.VIEWING_USERS,
    element: <ViewingUsers/>
  },
  {
    path: AdminLink.OTHERS_ADMINISTRATORS,
    element: <OthersAdmins/>
  },
  {
    path: AdminLink.OTHER,
    element: <Others/>
  }
]

export const adminLinksExtends = adminLinks.concat(generalLinks);
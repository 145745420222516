import { IAreas } from "../interfaces/common/areas";

export class AreasStore {
  areas: IAreas[] | null = null
  isAreasLoaded: boolean = false;

  setAreas(value: IAreas[]) {
    this.areas = value;
    this.isAreasLoaded = true
  }


  resetAreas() {
    this.areas = null
    this.isAreasLoaded = false;
  }
}
import { UserLayout } from "../../../shared/user/layout/UserLayout";
import { ProgressBar } from "./progressBar/ProgressBar";
import { observer } from "mobx-react-lite";
import { useDraftCaseVm } from "./useDraftCase.vm";

export const DraftCase = observer(() => {
  useDraftCaseVm()
  
  return (<UserLayout>
    <ProgressBar/>
  </UserLayout>)
})
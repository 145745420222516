import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { pl } from "date-fns/locale";
import { TextField, TextFieldProps } from "@mui/material";
import { dateStringify, yearStringify } from "../../../../utils";
import React, { FC } from "react";
import { CalendarPickerView } from "@mui/x-date-pickers/internals/models";
import { DatePickerCustomContainer } from "./Date.styled";
import { Label } from "../label/Label";

export type Size = 'small' | 'medium'

interface IProps {
  views: CalendarPickerView [];
  openTo: 'year';
  value?: string | null;
  name: string;
  inputFormat: string;
  onChange: (dateValue: string | null, keyboardInputValue?: string) => void;
  label?: string | JSX.Element;
  isRequired?: boolean;
  color?: string;
  isError?: boolean;
  textError?: string | boolean;
  placeholder?: string;
  onClose?: () => void
  onBlur?: ((e?: React.FocusEvent<any, Element>) => void) | undefined
  size?: Size;
  maxWidth?: string;
  disabledPast?: boolean
  disableFuture?: boolean
  minDate?: Date | null;
  isUpperCase?: boolean;
}

export const DateInput: FC<IProps> = ({
                                        views,
                                        openTo,
                                        value,
                                        name,
                                        inputFormat,
                                        onChange,
                                        isRequired = false,
                                        isError,
                                        label,
                                        textError,
                                        color = 'black',
                                        placeholder,
                                        onBlur,
                                        size = 'medium',
                                        maxWidth,
                                        disabledPast,
                                        disableFuture,
                                        minDate,
                                        isUpperCase,
                                        onClose
                                      }) => {

  const isDay = views.find(view => view === 'day');
  const dateFunction = views.length > 1 ? dateStringify : yearStringify

  const onChangeHandler = (value: any, keyboardInputValue?: string) => {
    onChange(value);
    onBlur && onBlur();
  }
  const content = <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
    <DatePicker
      onClose={onClose}
      inputFormat={inputFormat}
      disableFuture={disableFuture ?? false}
      disablePast={disabledPast ?? false}
      minDate={minDate}
      openTo={openTo}
      views={views}
      value={value ?? ""}
      onChange={onChangeHandler}
      renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
        if (params.inputProps) {
          if (value) {
            params.inputProps.value = dateFunction(value, !!isDay);
          }
          return <TextField error={isError} ref={params.inputRef} {...params}/>;
        }

        return <TextField error={isError} {...params} />;
      }}
    />
  </LocalizationProvider>;

  return (<DatePickerCustomContainer $isError={!!isError} $maxWidth={maxWidth}>
    <>{label ?
      <Label isUpperCase={isUpperCase} size={size} content={
        content
      } color={color} label={label} isRequired={isRequired}/>
      : <>{content}</>
    }</>
    {isError && <p>{textError}</p>}
  </DatePickerCustomContainer>);
}


import styled from "styled-components";
import theme from "../../../../../../../theme/theme";

export const DrugContainer = styled.div<{ $isGreyBackground?: boolean }>`
  margin: 16px auto;
  border-radius: 10px;
  width: 530px;
  height: 226px;
  background-color: ${props => props.$isGreyBackground ? 'rgba(7, 28, 71, 0.15)' : '#E8F0F8'};
  padding: 12px 20px 20px;
`

export const BinButton = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${theme.colors.abbvie};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0 auto;
  cursor: pointer;
`

export const DrugData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`
import styled from "styled-components";
import theme from "../../../../theme/theme";

interface IDatePickerCustom {
  $isError: boolean;
  $maxWidth?: string;
  $textAlign?: string;
}

export const DatePickerCustomContainer = styled.div<IDatePickerCustom>`
  width: 100%;
  text-align: ${props => props.$textAlign};
  margin: 0 0 15px 0;


  & div:nth-child(2) > div:first-of-type {
    color: ${theme.colors.black};
    height: 2.25rem;
    opacity: 0.7;
    border-radius: 10px;
    font-size: 16px;
    line-height: 22px;
    padding: 8px 22px;
    background: white;
    border: 2px solid rgb(223, 20, 20);
    color: ${theme.colors.black};
    border: 2px solid ${theme.colors.grey4};
    border: ${props => props.$isError && '2px solid #A41313 '};
    ${props => props.$maxWidth && `max-width: ${props.$maxWidth}`};
  }

  & fieldset {
    border: none;
  }

  & input {
    padding: 14px 0px;
  }
`

import { observer } from "mobx-react-lite";
import React from "react";
import { ReactComponent as Thick } from "../../../../../../assets/icon/thick.svg";
import { ReactComponent as Search } from "../../../../../../assets/icon/search.svg";
import { ReactComponent as SendCase } from "../../../../../../assets/icon/sendCase.svg";
import { Paragraph, Step } from './FifthStage.styled'
import { Grid } from "@mui/material";
import theme from "../../../../../../theme/theme";
import { Button } from "../../../../../shared/button/Button";
import { useFifthStage } from "./useFifthStage.vm";

export const FifthStage = observer(() => {
  const {clickButtonHandler, navigateToView} = useFifthStage();
  return (
    <Grid container maxWidth={'714px'} justifyContent={'center'} display={'flex'}
          margin={'auto auto'} mt={7}>
      <Grid item alignItems={'center'} xs={12} display={'flex'} flexDirection={'column'}>
        <Step><Thick/></Step>
        <Paragraph>Opis przypadku medycznego został ukończony. <br/>
          Klikinij WYŚLIJ DO ZATWIERDZENIA aby wysłać opis teraz lub ZAPISZ JAKO SZKIC i wyślij do akceptacji w innym
          terminie. Po wysłaniu do akceptacji w ciągu kilku dni opis przypadku zostanie zweryfikowany i po
          zaakceptowaniu będzie widoczny dla innych użytkowników. </Paragraph>
      </Grid>
      <Grid item xs={12} display={'flex'} gap={'16px'} mt={12}>
        <Button fontWeight={400} size={'tiny'} width={'280px'}
                backgroundColor={theme.colors.abbvie}
                hoverColor={theme.colors.lightAbbvie}
                icon={<Thick/>}
                color={theme.colors.white} clickHandler={() => clickButtonHandler('draft')}
                text={'zapisz jako szkic'}/>
        <Button fontWeight={400} size={'tiny'} width={'280px'}
                backgroundColor={theme.colors.abbvie}
                hoverColor={theme.colors.lightAbbvie}
                icon={<Search/>}
                color={theme.colors.white} clickHandler={navigateToView}
                text={'podgląd'}/>
        <Button fontWeight={400} size={'tiny'} width={'280px'}
                backgroundColor={theme.colors.abbvie}
                hoverColor={theme.colors.lightAbbvie}
                icon={<SendCase/>}
                color={theme.colors.white} clickHandler={() => clickButtonHandler('publish')}
                text={'wyślij do zatwierdzenia'}/>
      </Grid>
    </Grid>
  )
})
import { useModal } from '../../../shared/modal/useModal.vm';

import { IUserForm } from '../../../../interfaces/admin/user/userForm';
import { ModalType } from '../../../../constants/modalType';
import { ValidationMessages } from '../../../../constants/validationMessages';
import { useUsersApi } from '../../../../apiHooks';

export const useAddUser = () => {
  const usersApi = useUsersApi();
  const {handleModal, isModalOpened, closeModal} = useModal();

  const actionHandler = async (userForm: IUserForm) => {
    const isLogin = await usersApi.addUser(userForm);

    if (isLogin) {
      handleModal(ModalType.ADD_MODAL);
      return null;
    } else {
      return ValidationMessages.INVALID_USER_EXIST;
    }
  }

  return {isModalOpened, actionHandler, closeModal}
}
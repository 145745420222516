import styled from 'styled-components';
import { Grid } from '@mui/material';

interface IAdminLayoutContainer {
  $iscenter: boolean;
}

export const AdminLayoutContainer = styled(Grid)`
  background: linear-gradient(180deg, #E2EDFA 0%, #F6F6F6 100%);
`

export const AdminLayoutHeader = styled.header`
  position: fixed;
  width: 100%;
  top: 0;
  right: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100px;
  z-index: 1000;
`

export const AdminLayoutContentContainer = styled(Grid)<IAdminLayoutContainer>`
  flex-grow: 1;
  height: 100vh;
  max-height: 100vh;
  min-height: 800px;
  padding: 100px 16px 16px 16px;
  width: calc(100vw - 240px);
  display: flex;
  align-items: ${props => props.$iscenter ? 'center' : 'top'};
  justify-content: center;
  position: relative;
`
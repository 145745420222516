import { Grid } from "@mui/material";
import { Label } from "../../../../../shared/ui/label/Label";
import theme from "../../../../../../theme/theme";
import React from "react";
import { useThirdStageVm } from "./useThirdStage.vm";
import { Input } from "../../../../../shared/ui/input/Input";
import { RadioForm } from "../firstStage/FirstStage.styled";
import { RadioBox } from "../../../../../shared/ui/radioBox/RadioBox";
import { MultipleFileUpload } from "../../../../../shared/uploadFiles/fileUpload/multipleFileUpload/MultipleFileUpload";
import { FileSource } from "../../../../../shared/uploadFiles/FileUploadLayout";
import { Checkbox } from "../../../../../shared/ui/checkbox/Checkbox";
import { observer } from "mobx-react-lite";
import { SaveDraftBtn } from "../../saveDraftBtn/SaveDraftBtn";

export const ThirdStage = observer(() => {
  const {
    formik,
    addAbdominalUltrasoundAfterTreatmentFile,
    removeAbdominalUltrasoundAfterTreatmentFile,
    abdominalUltrasoundAfterTreatmentFiles,
    abdominalUltrasoundAfterTreatmentFiles12,
    addAbdominalUltrasoundAfterTreatmentFile12,
    removeAbdominalUltrasoundAfterTreatmentFile12
  } = useThirdStageVm()

  return (
    <Grid container maxWidth={'714px'} justifyContent={'flex-end'} display={'flex'} margin={'0 auto'} mt={7} mb={7}>
      <Grid item xs={12} alignSelf={'center'}>
        <Grid item xs={12}>
          <Label size={'medium'} isUpperCase={true} color={theme.colors.grey1}
                 label={'wyniki badań'}/>
        </Grid>
        <Grid item xs={12} mt={4}>
          <Label size={'medium'} isUpperCase={true} color={theme.colors.grey1}
                 label={'w 1 dniu, przed podaniem leków'}/>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Label size={'small'} isUpperCase={true} color={theme.colors.grey1} label={'morfologia krwi'}/>
        </Grid>
        <Grid container gap={'20px'} flexWrap={'nowrap'} mt={2}>
          <Grid item xs={3}>
            <Input
              name={"rbc1"}
              type={"text"}
              label={"RBC [mln/µl]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.rbc1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.rbc1 && Boolean(formik.errors.rbc1)}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              name={"hgb1"}
              type={"text"}
              label={"HGB [g/dl]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.hgb1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.hgb1 && Boolean(formik.errors.hgb1)}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              name={"htc1"}
              type={"text"}
              label={"HTC [%]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.htc1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.htc1 && Boolean(formik.errors.htc1)}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              name={"plt1"}
              type={"text"}
              label={"PLT [tys/µl]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.plt1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.plt1 && Boolean(formik.errors.plt1)}
            />
          </Grid>
        </Grid>
        <Grid container gap={'20px'} flexWrap={'nowrap'}>
          <Grid item xs={3}>
            <Input
              name={"wbc1"}
              type={"text"}
              label={"WBC [tys/µl]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.wbc1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.wbc1 && Boolean(formik.errors.wbc1)}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              name={"mcv1"}
              type={"text"}
              label={"MCV [fl]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.mcv1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.mcv1 && Boolean(formik.errors.mcv1)}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              name={"mch1"}
              type={"text"}
              label={"MCH [pg]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.mch1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.mch1 && Boolean(formik.errors.mch1)}
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              name={"mchc1"}
              type={"text"}
              label={"MCHC [g/dl]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.mchc1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.mchc1 && Boolean(formik.errors.mchc1)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} mt={3}>
          <Input
            maxWidth={'100%'}
            width={'100%'}
            name={"hcvRna1"}
            type={"text"}
            label={"OZNACZENIE HCV RNA METODĄ JAKOŚCIOWĄ LUB ILOŚCIOWĄ [IU/mL, kopii/ml]"}
            colorLabel={theme.colors.grey1}
            value={formik.values.hcvRna1}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz wynik..."}
            isError={formik.touched.hcvRna1 && Boolean(formik.errors.hcvRna1)}
          />
        </Grid>
        <Grid item xs={12} mt={3}>
          <Input
            name={"albumin1"}
            type={"text"}
            label={"OZNACZENIE STĘŻENIA ALBUMINY [g/dl]"}
            colorLabel={theme.colors.grey1}
            value={formik.values.albumin1}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            borderColor={theme.colors.grey5}
            placeholderColor={theme.colors.grey4}
            background={theme.colors.white}
            placeholder={"Wpisz wynik..."}
            isError={formik.touched.albumin1 && Boolean(formik.errors.albumin1)}
          />
        </Grid>
        <Grid container display={'flex'} alignItems={'center'} gap={'20px'} flexWrap={'nowrap'} mt={3}>
          <Grid item xs={6}>
            <Input
              name={"aiat1"}
              type={"text"}
              label={"OZNACZENIE AKTYWNOŚCI AIAT [U/l]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.aiat1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.aiat1 && Boolean(formik.errors.aiat1)}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              name={"bilirubin1"}
              type={"text"}
              label={"OZNACZENIE STĘŻENIA BILIRUBINY [mg/dl]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.bilirubin1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.bilirubin1 && Boolean(formik.errors.bilirubin1)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Label size={'small'} isUpperCase={true} color={theme.colors.grey1} isRequired={true}
                 label={'czy pacjent leczony w uproszczonym schemacie monitorowania terapii?'}/>
        </Grid>
        <Grid item xs={12}>
          <RadioForm>
            <RadioBox isError={formik.touched.simplifiedDiagram && Boolean(formik.errors.simplifiedDiagram)}
                      id={'simplifiedDiagram1'} name={'simplifiedDiagram'}
                      value={'nie'}
                      checked={formik.values.simplifiedDiagram === 'nie'}
                      onChange={formik.handleChange}/>
            <RadioBox isError={formik.touched.simplifiedDiagram && Boolean(formik.errors.simplifiedDiagram)}
                      id={'simplifiedDiagram2'} name={'simplifiedDiagram'}
                      value={'tak'}
                      checked={formik.values.simplifiedDiagram === 'tak'}
                      onChange={formik.handleChange}/>
          </RadioForm>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Label size={'small'} isUpperCase={true} color={theme.colors.grey1} isRequired={true}
                 label={'czas trwania terapii'}/>
        </Grid>
        <Grid item xs={12}>
          <RadioForm>
            <RadioBox isError={formik.touched.durationOfTherapy && Boolean(formik.errors.durationOfTherapy)}
                      id={'durationOfTherapy2'} name={'durationOfTherapy'}
                      value={'8 tygodni'}
                      checked={formik.values.durationOfTherapy === '8 tygodni'}
                      onChange={formik.handleChange}/>
            <RadioBox isError={formik.touched.durationOfTherapy && Boolean(formik.errors.durationOfTherapy)}
                      id={'durationOfTherapy3'} name={'durationOfTherapy'}
                      value={'12 tygodni'}
                      checked={formik.values.durationOfTherapy === '12 tygodni'}
                      onChange={formik.handleChange}/>
            <RadioBox isError={formik.touched.durationOfTherapy && Boolean(formik.errors.durationOfTherapy)}
                      id={'durationOfTherapy4'} name={'durationOfTherapy'}
                      value={'16 tygodni'}
                      checked={formik.values.durationOfTherapy === '16 tygodni'}
                      onChange={formik.handleChange}/>
          </RadioForm>
        </Grid>
        {formik.values.simplifiedDiagram !== 'tak' && <>
          <Grid item xs={12} mt={4}>
            <Label size={'medium'} isUpperCase={true} color={theme.colors.grey1}
                   label={'w 4 tygodniu:'}/>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Label size={'small'} isUpperCase={true} color={theme.colors.grey1} label={'morfologia krwi'}/>
          </Grid>
          <Grid container gap={'20px'} flexWrap={'nowrap'} mt={2}>
            <Grid item xs={3}>
              <Input
                name={"rbc4"}
                type={"text"}
                label={"RBC [mln/µl]"}
                colorLabel={theme.colors.grey1}
                value={formik.values.rbc4}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                borderColor={theme.colors.grey5}
                placeholderColor={theme.colors.grey4}
                background={theme.colors.white}
                placeholder={"Wpisz wynik..."}
                isError={formik.touched.rbc4 && Boolean(formik.errors.rbc4)}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                name={"hgb4"}
                type={"text"}
                label={"HGB [g/dl]"}
                colorLabel={theme.colors.grey1}
                value={formik.values.hgb4}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                borderColor={theme.colors.grey5}
                placeholderColor={theme.colors.grey4}
                background={theme.colors.white}
                placeholder={"Wpisz wynik..."}
                isError={formik.touched.hgb4 && Boolean(formik.errors.hgb4)}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                name={"htc4"}
                type={"text"}
                label={"HTC [%]"}
                colorLabel={theme.colors.grey1}
                value={formik.values.htc4}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                borderColor={theme.colors.grey5}
                placeholderColor={theme.colors.grey4}
                background={theme.colors.white}
                placeholder={"Wpisz wynik..."}
                isError={formik.touched.htc4 && Boolean(formik.errors.htc4)}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                name={"plt4"}
                type={"text"}
                label={"PLT [tys/µl]"}
                colorLabel={theme.colors.grey1}
                value={formik.values.plt4}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                borderColor={theme.colors.grey5}
                placeholderColor={theme.colors.grey4}
                background={theme.colors.white}
                placeholder={"Wpisz wynik..."}
                isError={formik.touched.plt4 && Boolean(formik.errors.plt4)}
              />
            </Grid>
          </Grid>
          <Grid container gap={'20px'} flexWrap={'nowrap'}>
            <Grid item xs={3}>
              <Input
                name={"wbc4"}
                type={"text"}
                label={"WBC [tys/µl]"}
                colorLabel={theme.colors.grey1}
                value={formik.values.wbc4}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                borderColor={theme.colors.grey5}
                placeholderColor={theme.colors.grey4}
                background={theme.colors.white}
                placeholder={"Wpisz wynik..."}
                isError={formik.touched.wbc4 && Boolean(formik.errors.wbc4)}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                name={"mcv4"}
                type={"text"}
                label={"MCV [fl]"}
                colorLabel={theme.colors.grey1}
                value={formik.values.mcv4}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                borderColor={theme.colors.grey5}
                placeholderColor={theme.colors.grey4}
                background={theme.colors.white}
                placeholder={"Wpisz wynik..."}
                isError={formik.touched.mcv4 && Boolean(formik.errors.mcv4)}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                name={"mch4"}
                type={"text"}
                label={"MCH [pg]"}
                colorLabel={theme.colors.grey1}
                value={formik.values.mch4}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                borderColor={theme.colors.grey5}
                placeholderColor={theme.colors.grey4}
                background={theme.colors.white}
                placeholder={"Wpisz wynik..."}
                isError={formik.touched.mch4 && Boolean(formik.errors.mch4)}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                name={"mchc4"}
                type={"text"}
                label={"MCHC [g/dl]"}
                colorLabel={theme.colors.grey1}
                value={formik.values.mchc4}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                borderColor={theme.colors.grey5}
                placeholderColor={theme.colors.grey4}
                background={theme.colors.white}
                placeholder={"Wpisz wynik..."}
                isError={formik.touched.mchc4 && Boolean(formik.errors.mchc4)}
              />
            </Grid>
          </Grid>
          <Grid container display={'flex'} alignItems={'center'} gap={'20px'} flexWrap={'nowrap'} mt={3}>
            <Grid item xs={6}>
              <Input
                name={"aiat4"}
                type={"text"}
                label={"OZNACZENIE AKTYWNOŚCI AIAT [U/l]"}
                colorLabel={theme.colors.grey1}
                value={formik.values.aiat4}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                borderColor={theme.colors.grey5}
                placeholderColor={theme.colors.grey4}
                background={theme.colors.white}
                placeholder={"Wpisz wynik..."}
                isError={formik.touched.aiat4 && Boolean(formik.errors.aiat4)}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                name={"bilirubin4"}
                type={"text"}
                label={"OZNACZENIE STĘŻENIA BILIRUBINY [mg/dl]"}
                colorLabel={theme.colors.grey1}
                value={formik.values.bilirubin4}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                borderColor={theme.colors.grey5}
                placeholderColor={theme.colors.grey4}
                background={theme.colors.white}
                placeholder={"Wpisz wynik..."}
                isError={formik.touched.bilirubin4 && Boolean(formik.errors.bilirubin4)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Label size={'small'} isUpperCase={true} color={theme.colors.grey1} isRequired={true}
                   label={'kontynuacja monitorowania bilirubiny?'}/>
          </Grid>
          <Grid item xs={12}>
            <RadioForm>
              <RadioBox isError={formik.touched.bilirubinMonitoring4 && Boolean(formik.errors.bilirubinMonitoring4)}
                        id={'bilirubinMonitoring1'} name={'bilirubinMonitoring4'}
                        value={'nie'}
                        checked={formik.values.bilirubinMonitoring4 === 'nie'}
                        onChange={formik.handleChange}/>
              <RadioBox isError={formik.touched.bilirubinMonitoring4 && Boolean(formik.errors.bilirubinMonitoring4)}
                        id={'bilirubinMonitoring2'} name={'bilirubinMonitoring4'}
                        value={'tak'}
                        checked={formik.values.bilirubinMonitoring4 === 'tak'}
                        onChange={formik.handleChange}/>
            </RadioForm>
          </Grid>
          {formik.values.durationOfTherapy !== '8 tygodni' && <>
            <Grid item xs={12} mt={4}>
              <Label size={'medium'} isUpperCase={true} color={theme.colors.grey1}
                     label={'w 8 tygodniu:'}/>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Label size={'small'} isUpperCase={true} color={theme.colors.grey1} label={'morfologia krwi'}/>
            </Grid>
            <Grid container gap={'20px'} flexWrap={'nowrap'} mt={2}>
              <Grid item xs={3}>
                <Input
                  name={"rbc8"}
                  type={"text"}
                  label={"RBC [mln/µl]"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.rbc8}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz wynik..."}
                  isError={formik.touched.rbc8 && Boolean(formik.errors.rbc8)}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  name={"hgb8"}
                  type={"text"}
                  label={"HGB [g/dl]"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.hgb8}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz wynik..."}
                  isError={formik.touched.hgb8 && Boolean(formik.errors.hgb8)}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  name={"htc8"}
                  type={"text"}
                  label={"HTC [%]"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.htc8}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz wynik..."}
                  isError={formik.touched.htc8 && Boolean(formik.errors.htc8)}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  name={"plt8"}
                  type={"text"}
                  label={"PLT [tys/µl]"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.plt8}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz wynik..."}
                  isError={formik.touched.plt8 && Boolean(formik.errors.plt8)}
                />
              </Grid>
            </Grid>
            <Grid container gap={'20px'} flexWrap={'nowrap'}>
              <Grid item xs={3}>
                <Input
                  name={"wbc8"}
                  type={"text"}
                  label={"WBC [tys/µl]"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.wbc8}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz wynik..."}
                  isError={formik.touched.wbc8 && Boolean(formik.errors.wbc8)}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  name={"mcv8"}
                  type={"text"}
                  label={"MCV [fl]"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.mcv8}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz wynik..."}
                  isError={formik.touched.mcv8 && Boolean(formik.errors.mcv8)}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  name={"mch8"}
                  type={"text"}
                  label={"MCH [pg]"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.mch8}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz wynik..."}
                  isError={formik.touched.mch8 && Boolean(formik.errors.mch8)}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  name={"mchc8"}
                  type={"text"}
                  label={"MCHC [g/dl]"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.mchc8}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz wynik..."}
                  isError={formik.touched.mchc8 && Boolean(formik.errors.mchc8)}
                />
              </Grid>
            </Grid>
            <Grid container display={'flex'} alignItems={'center'} gap={'20px'} flexWrap={'nowrap'} mt={3}>
              <Grid item xs={6}>
                <Input
                  name={"aiat8"}
                  type={"text"}
                  label={"OZNACZENIE AKTYWNOŚCI AIAT [U/l]"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.aiat8}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz wynik..."}
                  isError={formik.touched.aiat8 && Boolean(formik.errors.aiat8)}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  name={"bilirubin8"}
                  type={"text"}
                  label={"OZNACZENIE STĘŻENIA BILIRUBINY [mg/dl]"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.bilirubin8}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz wynik..."}
                  isError={formik.touched.bilirubin8 && Boolean(formik.errors.bilirubin8)}
                />
              </Grid>
            </Grid>
            {formik.values.bilirubinMonitoring4 !== 'nie' && <><Grid item xs={12} mt={2}>
              <Label size={'small'} isUpperCase={true} color={theme.colors.grey1} isRequired={true}
                     label={'kontynuacja monitorowania bilirubiny?'}/>
            </Grid>
              <Grid item xs={12}>
                <RadioForm>
                  <RadioBox isError={formik.touched.bilirubinMonitoring8 && Boolean(formik.errors.bilirubinMonitoring8)}
                            id={'bilirubinMonitoring81'} name={'bilirubinMonitoring8'}
                            value={'nie'}
                            checked={formik.values.bilirubinMonitoring8 === 'nie'}
                            onChange={formik.handleChange}/>
                  <RadioBox isError={formik.touched.bilirubinMonitoring8 && Boolean(formik.errors.bilirubinMonitoring8)}
                            id={'bilirubinMonitoring82'} name={'bilirubinMonitoring8'}
                            value={'tak'}
                            checked={formik.values.bilirubinMonitoring8 === 'tak'}
                            onChange={formik.handleChange}/>
                </RadioForm>
              </Grid>
            </>}
          </>}
          {(formik.values.durationOfTherapy !== '12 tygodni' && formik.values.durationOfTherapy !== '8 tygodni') && <>
            <Grid item xs={12} mt={4}>
              <Label size={'medium'} isUpperCase={true} color={theme.colors.grey1}
                     label={'w 12 tygodniu:'}/>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Label size={'small'} isUpperCase={true} color={theme.colors.grey1} label={'morfologia krwi'}/>
            </Grid>
            <Grid container gap={'20px'} flexWrap={'nowrap'} mt={2}>
              <Grid item xs={3}>
                <Input
                  name={"rbc12"}
                  type={"text"}
                  label={"RBC [mln/µl]"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.rbc12}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz wynik..."}
                  isError={formik.touched.rbc12 && Boolean(formik.errors.rbc12)}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  name={"hgb12"}
                  type={"text"}
                  label={"HGB [g/dl]"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.hgb12}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz wynik..."}
                  isError={formik.touched.hgb12 && Boolean(formik.errors.hgb12)}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  name={"htc12"}
                  type={"text"}
                  label={"HTC [%]"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.htc12}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz wynik..."}
                  isError={formik.touched.htc12 && Boolean(formik.errors.htc12)}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  name={"plt12"}
                  type={"text"}
                  label={"PLT [tys/µl]"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.plt12}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz wynik..."}
                  isError={formik.touched.plt12 && Boolean(formik.errors.plt12)}
                />
              </Grid>
            </Grid>
            <Grid container gap={'20px'} flexWrap={'nowrap'}>
              <Grid item xs={3}>
                <Input
                  name={"wbc12"}
                  type={"text"}
                  label={"WBC [tys/µl]"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.wbc12}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz wynik..."}
                  isError={formik.touched.wbc12 && Boolean(formik.errors.wbc12)}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  name={"mcv12"}
                  type={"text"}
                  label={"MCV [fl]"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.mcv12}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz wynik..."}
                  isError={formik.touched.mcv12 && Boolean(formik.errors.mcv12)}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  name={"mch12"}
                  type={"text"}
                  label={"MCH [pg]"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.mch12}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz wynik..."}
                  isError={formik.touched.mch12 && Boolean(formik.errors.mch12)}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  name={"mchc12"}
                  type={"text"}
                  label={"MCHC [g/dl]"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.mchc12}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz wynik..."}
                  isError={formik.touched.mchc12 && Boolean(formik.errors.mchc12)}
                />
              </Grid>
            </Grid>
            <Grid container display={'flex'} alignItems={'center'} gap={'20px'} flexWrap={'nowrap'} mt={3}>
              <Grid item xs={6}>
                <Input
                  name={"aiat12"}
                  type={"text"}
                  label={"OZNACZENIE AKTYWNOŚCI AIAT [U/l]"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.aiat12}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz wynik..."}
                  isError={formik.touched.aiat12 && Boolean(formik.errors.aiat12)}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  name={"bilirubin12"}
                  type={"text"}
                  label={"OZNACZENIE STĘŻENIA BILIRUBINY [mg/dl]"}
                  colorLabel={theme.colors.grey1}
                  value={formik.values.bilirubin12}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  borderColor={theme.colors.grey5}
                  placeholderColor={theme.colors.grey4}
                  background={theme.colors.white}
                  placeholder={"Wpisz wynik..."}
                  isError={formik.touched.bilirubin12 && Boolean(formik.errors.bilirubin12)}
                />
              </Grid>
            </Grid>
            {formik.values.bilirubinMonitoring8 !== 'nie' && formik.values.bilirubinMonitoring4 !== 'nie' && <><Grid
              item
              xs={12}
              mt={2}>
              <Label size={'small'} isUpperCase={true} color={theme.colors.grey1} isRequired={true}
                     label={'kontynuacja monitorowania bilirubiny?'}/>
            </Grid>
              <Grid item xs={12}>
                <RadioForm>
                  <RadioBox
                    isError={formik.touched.bilirubinMonitoring8 && Boolean(formik.errors.bilirubinMonitoring12)}
                    id={'bilirubinMonitoring121'} name={'bilirubinMonitoring12'}
                    value={'nie'}
                    checked={formik.values.bilirubinMonitoring12 === 'nie'}
                    onChange={formik.handleChange}/>
                  <RadioBox
                    isError={formik.touched.bilirubinMonitoring12 && Boolean(formik.errors.bilirubinMonitoring12)}
                    id={'bilirubinMonitoring122'} name={'bilirubinMonitoring12'}
                    value={'tak'}
                    checked={formik.values.bilirubinMonitoring12 === 'tak'}
                    onChange={formik.handleChange}/>
                </RadioForm>
              </Grid></>}
          </>}</>}
        {formik.values.simplifiedDiagram !== 'tak' && <><Grid item xs={12} mt={4}>
          <Label size={'medium'} isUpperCase={true} color={theme.colors.grey1}
                 label={'EOT - na zakończenie leczenia'}/>
        </Grid>
          <Grid item xs={12} mt={2}>
            <Label size={'small'} color={theme.colors.grey1}
                   label={'OZNACZENIE HCV RNA [IU/mL]'}/>
          </Grid>
          <Grid item xs={12}>
            <RadioForm>
              <RadioBox isError={formik.touched.hcvRnaAfterTreatment && Boolean(formik.errors.hcvRnaAfterTreatment)}
                        id={'hcvRnaAfterTreatment1'} name={'hcvRnaAfterTreatment'}
                        value={'niewykrywalne'}
                        checked={formik.values.hcvRnaAfterTreatment === 'niewykrywalne'}
                        onChange={formik.handleChange}/>
              <RadioBox isError={formik.touched.hcvRnaAfterTreatment && Boolean(formik.errors.hcvRnaAfterTreatment)}
                        id={'hcvRnaAfterTreatment2'} name={'hcvRnaAfterTreatment'}
                        value={'wykrywalne'}
                        checked={formik.values.hcvRnaAfterTreatment === 'wykrywalne'}
                        onChange={formik.handleChange}/>
            </RadioForm>
          </Grid>
          <Grid>
            <Input
              disabled={formik.values.hcvRnaAfterTreatment !== 'wykrywalne'}
              name={"hcvRnaAfterTreatmentDesc"}
              type={"text"}
              colorLabel={theme.colors.grey1}
              value={formik.values.hcvRnaAfterTreatmentDesc}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.hcvRnaAfterTreatmentDesc && Boolean(formik.errors.hcvRnaAfterTreatmentDesc)}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name={"aiatAfterTreatment"}
              type={"text"}
              label={"OZNACZENIE AKTYWNOŚCI AIAT [U/l]"}
              colorLabel={theme.colors.grey1}
              value={formik.values.aiatAfterTreatment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.aiatAfterTreatment && Boolean(formik.errors.aiatAfterTreatment)}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name={"abdominalUltrasoundAfterTreatment"}
              type={"text"}
              isUpperCase={true}
              label={"USG jamy brzusznej"}
              colorLabel={theme.colors.grey1}
              value={formik.values.abdominalUltrasoundAfterTreatment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.abdominalUltrasoundAfterTreatment && Boolean(formik.errors.abdominalUltrasoundAfterTreatment)}
            />
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              fontWeight={'700'}
              id={'abdominalUltrasoundAfterTreatmentCheckbox'}
              name={'abdominalUltrasoundAfterTreatmentCheckbox'}
              fontSize={'12px'}
              textColor={theme.colors.abbvie}
              label={'Oświadczam, że na załączonych zdjęciach nie znajdują sie dane pacjenta*'}
              checked={formik.values.abdominalUltrasoundAfterTreatmentCheckbox}
              onChange={() => formik.setFieldValue('abdominalUltrasoundAfterTreatmentCheckbox', !formik.values.abdominalUltrasoundAfterTreatmentCheckbox)}
            />
            <MultipleFileUpload id={'abdominalUltrasoundAfterTreatment'} files={abdominalUltrasoundAfterTreatmentFiles}
                                removeFile={removeAbdominalUltrasoundAfterTreatmentFile}
                                disabled={!formik.values.abdominalUltrasoundAfterTreatmentCheckbox}
                                addFile={addAbdominalUltrasoundAfterTreatmentFile}
                                maxFileSize={10}
                                extensions={['pdf', 'jpg', 'jpeg', 'png', 'gif']}
                                fileType={['application/pdf', 'image']}
                                fileSource={FileSource.STATE}
                                size={'small'}
            />
          </Grid>
        </>}
        <>
          <Grid item xs={12} mt={4}>
            <Label size={'medium'} isUpperCase={true} color={theme.colors.grey1}
                   label={'SVR12 - po 12 tygodniach od zakończenia leczenia'}/>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Input
              isRequired={true}
              maxWidth={'100%'}
              width={'100%'}
              name={"hcvRnaAfterTreatment12"}
              type={"text"}
              label={"OZNACZENIE HCV RNA METODĄ JAKOŚCIOWĄ"}
              colorLabel={theme.colors.grey1}
              value={formik.values.hcvRnaAfterTreatment12}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.hcvRnaAfterTreatment12 && Boolean(formik.errors.hcvRnaAfterTreatment12)}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              isRequired={true}
              name={"abdominalUltrasoundAfterTreatment12"}
              type={"text"}
              isUpperCase={true}
              label={"USG jamy brzusznej"}
              colorLabel={theme.colors.grey1}
              value={formik.values.abdominalUltrasoundAfterTreatment12}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderColor={theme.colors.grey5}
              placeholderColor={theme.colors.grey4}
              background={theme.colors.white}
              placeholder={"Wpisz wynik..."}
              isError={formik.touched.abdominalUltrasoundAfterTreatment12 && Boolean(formik.errors.abdominalUltrasoundAfterTreatment12)}
            />
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              fontWeight={'700'}
              id={'abdominalUltrasoundAfterTreatmentCheckbox12'}
              name={'abdominalUltrasoundAfterTreatmentCheckbox12'}
              fontSize={'12px'}
              textColor={theme.colors.abbvie}
              label={'Oświadczam, że na załączonych zdjęciach nie znajdują sie dane pacjenta*'}
              checked={formik.values.abdominalUltrasoundAfterTreatmentCheckbox12}
              onChange={() => formik.setFieldValue('abdominalUltrasoundAfterTreatmentCheckbox12', !formik.values.abdominalUltrasoundAfterTreatmentCheckbox12)}
            />
            <MultipleFileUpload id={'abdominalUltrasoundAfterTreatment12'}
                                files={abdominalUltrasoundAfterTreatmentFiles12}
                                removeFile={removeAbdominalUltrasoundAfterTreatmentFile12}
                                disabled={!formik.values.abdominalUltrasoundAfterTreatmentCheckbox12}
                                addFile={addAbdominalUltrasoundAfterTreatmentFile12}
                                maxFileSize={10}
                                extensions={['pdf', 'jpg', 'jpeg', 'png', 'gif']}
                                fileType={['application/pdf', 'image']}
                                fileSource={FileSource.STATE}
                                size={'small'}
            />
          </Grid>
        </>
      </Grid>
      <SaveDraftBtn values={formik.values}/>
    </Grid>
  )
})
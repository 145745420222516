import {CaseStatus} from "../interfaces/user/draftCase/draft";

export const filterByCurrentPage =
  <T>(currentPage: number, cardsPerPage: number) =>
    (_: T, index: number) =>
      index >= (currentPage - 1) * cardsPerPage && index < currentPage * cardsPerPage;

export const filterByStatus = (status: CaseStatus[]) => (element: any) =>
  status.length === 0 || status.includes(element.status);

export const filterByCoinfection = (coinfection: number[]) => (element: any) =>
  coinfection.length === 0 || coinfection.includes(element.coInfection);

export const searchByFilter = <T>(searchValue: string, targetKeys: string[]) => (element: T) => {
  const searchArray = searchValue
    .trim()
    .toLowerCase()
    .split(' ')

  const targetString = targetKeys.map(el => element[el as keyof T]).join(' ');

  return searchArray.every(searchWord => targetString.toLowerCase().includes(searchWord));
};
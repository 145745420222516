export const selectOptions = [
  {
    id: 0,
    title: 'Brak koinfekcji'
  },
  {
    id: 1,
    title: 'HIV + HCV'
  },
  {
    id: 2,
    title: 'HBV + HCV'
  }, {
    id: 3,
    title: 'HIV + HBV + HCV',
  }]
import {useNavigate, useParams} from "react-router-dom";
import {useCaseApi} from "../../../../../../apiHooks/useCaseApi";
import {useCaseStore, useProgressBarStore} from "../../../../../../store/hooks";
import {Stages} from "../../progressBar/ProgressBar.helper";
import {generateLink, UserLink} from "../../../../../../utils";

export const useFifthStage = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const {publishDraftCase} = useCaseApi();
  const progressBarStore = useProgressBarStore();
  const draftCaseStore = useCaseStore();

  const clickButtonHandler = async (type: string) => {
    progressBarStore.setTypeOfCase(type);
    if (type === 'publish' && draftCaseStore.case) {
      const res = await publishDraftCase(draftCaseStore.case.id)

      if (res) {
        progressBarStore.setCurrentStage(Stages.SIXTH);
      }
    } else if (type === 'draft') {
      progressBarStore.setCurrentStage(Stages.SIXTH);
    }
  }

  const navigateToView = () => {
    navigate(generateLink(UserLink.VIEW_CASE, id));
  }

  return {clickButtonHandler, navigateToView}

}
import styled from "styled-components";
import theme from "../../../../theme/theme";

export const Item = styled.div<{ $isError?: boolean, $isUpperCase?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 36px;
  font-size: 20px;

  & input {
    margin-top: 0;
    cursor: pointer;
    margin-right: 9px;
  }

  input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: ${theme.colors.white};
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid ${theme.colors.grey1};
  }

  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: ${theme.colors.abbvie};
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid ${theme.colors.grey1};
  }

  & label {
    color: ${props => props.$isError ? theme.colors.red : theme.colors.grey1};
    text-transform: ${props => props.$isUpperCase ? 'uppercase' : 'none'};
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 1.2px;
    cursor: pointer;
  }
`
import {useCaseStore, useHistoryStore, useProgressBarStore} from '../store/hooks';
import {useApiCall} from "./useApiCall";
import {useCallback} from "react";
import {ICase} from "../interfaces/user/draftCase/draft";
import {IFirstStage} from "../interfaces/user/draftCase/firstStage";
import {IFile, ISecondStage} from "../interfaces/user/draftCase/secondStage";
import {IFourthStage} from "../interfaces/user/draftCase/fouthStage";
import {IThirdStage} from "../interfaces/user/draftCase/thirdStage";
import {AxiosRequestConfig} from 'axios';

export const useCaseApi = () => {
  const {api} = useApiCall();
  const caseStore = useCaseStore();
  const progressBarStore = useProgressBarStore()
  const historyStore = useHistoryStore();

  const getCase = useCallback(
    async (id: number | string) => {
      const res: ICase = await api.apiInstance.get(`situation/${id}`);
      caseStore.setCase(res);
      return !!res
    },
    [api.apiInstance, caseStore]
  );

  const postDraftCase = useCallback(
    async (firstStage: IFirstStage) => {
      const res = await api.apiInstance.post('situation', firstStage);

      if (res?.id) {
        return res.id;
      }
      return false;
    },
    [api.apiInstance]
  );

  const putFirstStageDraftCase = useCallback(
    async (id: number, firstStage: IFirstStage) => {
      const res = await api.apiInstance.put(`situation/${id}/first`, firstStage);
      return !!res;
    },
    [api.apiInstance]
  );
  const putSecondStageDraftCase = useCallback(
    async (id: number, secondStage: ISecondStage) => {
      const res = await api.apiInstance.put(`situation/${id}/second`, secondStage);
      return !!res;
    },
    [api.apiInstance]
  );

  const putThirdStageDraftCase = useCallback(
    async (id: number, fourthStage: IThirdStage) => {
      const res = await api.apiInstance.put(`situation/${id}/third`, fourthStage);
      return !!res;
    },
    [api.apiInstance]
  );

  const putFourthStageDraftCase = useCallback(
    async (id: number, fourthStage: IFourthStage) => {
      const res = await api.apiInstance.put(`situation/${id}/fourth`, fourthStage);
      return !!res;
    },
    [api.apiInstance]
  );

  const postAbdominalFilesSecond = useCallback(
    async (id: number, file: File) => {
      const form = new FormData();
      form.append('file', file);
      const res: IFile[] = await api.apiInstance.post(`situation/${id}/abdominalFile/second`, form, true, {headers: {"Content-Type": "multipart/form-data"}});
      if (res) {
        if (progressBarStore.secondStage) {
          progressBarStore.setSecondStage({...progressBarStore.secondStage, abdominalUltrasoundFiles: res})
        }
      }
    },
    [api.apiInstance, progressBarStore]
  );

  const deleteAbdominalFilesSecond = useCallback(
    async (id: number, idFile: number | string) => {
      const res = await api.apiInstance.delete(`situation/${id}/abdominalFile/${idFile}/second`);
      if (res) {
        if (progressBarStore.secondStage) {
          progressBarStore.setSecondStage({...progressBarStore.secondStage, abdominalUltrasoundFiles: res})
        }
      }
    },
    [api.apiInstance, progressBarStore]
  );

  const postDrugInteractionFilesSecond = useCallback(
    async (id: number, file: File) => {
      const form = new FormData();
      form.append('file', file);
      const res: IFile[] = await api.apiInstance.post(`situation/${id}/potentialDrugInteractionFile/second`, form, true, {headers: {"Content-Type": "multipart/form-data"}});
      if (res) {
        if (progressBarStore.secondStage) {
          progressBarStore.setSecondStage({...progressBarStore.secondStage, potentialDrugInteractionFiles: res})
        }
      }
    },
    [api.apiInstance, progressBarStore]
  );
  const deleteDrugInteractionFilesSecond = useCallback(
    async (id: number, idFile: number | string) => {
      const res = await api.apiInstance.delete(`situation/${id}/potentialDrugInteractionFile/${idFile}/second`);
      if (res) {
        if (progressBarStore.secondStage) {
          progressBarStore.setSecondStage({...progressBarStore.secondStage, potentialDrugInteractionFiles: res})
        }
      }
    },
    [api.apiInstance, progressBarStore]
  );


  const postLiverFilesSecondStage = useCallback(
    async (id: number, file: File) => {
      const form = new FormData();
      form.append('file', file);
      const res: IFile[] = await api.apiInstance.post(`situation/${id}/liverFile/second`, form, true, {headers: {"Content-Type": "multipart/form-data"}});
      if (res) {
        if (progressBarStore.secondStage) {
          progressBarStore.setSecondStage({...progressBarStore.secondStage, liverBiopsyFiles: res})
        }
      }
    },
    [api.apiInstance, progressBarStore]
  );

  const deleteLiverFilesSecondStage = useCallback(
    async (id: number, idFile: number | string) => {
      const res = await api.apiInstance.delete(`situation/${id}/liverFile/${idFile}/second`);
      if (res) {
        if (progressBarStore.secondStage) {
          progressBarStore.setSecondStage({...progressBarStore.secondStage, liverBiopsyFiles: res})
        }
      }
    },
    [api.apiInstance, progressBarStore]
  );
  const postAbdominalFileAfterTreatmentThirdStage = useCallback(
    async (id: number, file: File) => {
      const form = new FormData();
      form.append('file', file);
      const res: IFile[] = await api.apiInstance.post(`situation/${id}/abdominalFile/third`, form, true, {headers: {"Content-Type": "multipart/form-data"}});
      if (res) {
        if (progressBarStore.thirdStage) {
          progressBarStore.setThirdStage({...progressBarStore.thirdStage, abdominalUltrasoundAfterTreatmentFiles: res})
        }
      }
    },
    [api.apiInstance, progressBarStore]
  );

  const deleteAbdominalFileAfterTreatmentThirdStage = useCallback(
    async (id: number, idFile: number | string) => {
      const res = await api.apiInstance.delete(`situation/${id}/abdominalFile/${idFile}/third`);
      if (res) {
        if (progressBarStore.thirdStage) {
          progressBarStore.setThirdStage({...progressBarStore.thirdStage, abdominalUltrasoundAfterTreatmentFiles: res})
        }
      }
    },
    [api.apiInstance, progressBarStore]
  );

  const postAbdominalFileAfterTreatment12ThirdStage = useCallback(
    async (id: number, file: File) => {
      const form = new FormData();
      form.append('file', file);
      const res: IFile[] = await api.apiInstance.post(`situation/${id}/abdominalFile12/third`, form, true, {headers: {"Content-Type": "multipart/form-data"}});
      if (res) {
        if (progressBarStore.thirdStage) {
          progressBarStore.setThirdStage({
            ...progressBarStore.thirdStage,
            abdominalUltrasoundAfterTreatmentFiles12: res
          })
        }
      }
    },
    [api.apiInstance, progressBarStore]
  );

  const deleteAbdominalFileAfterTreatment12ThirdStage = useCallback(
    async (id: number, idFile: number | string) => {
      const res = await api.apiInstance.delete(`situation/${id}/abdominalFile12/${idFile}/third`);
      if (res) {
        if (progressBarStore.thirdStage) {
          progressBarStore.setThirdStage({
            ...progressBarStore.thirdStage,
            abdominalUltrasoundAfterTreatmentFiles12: res
          })
        }
      }
    },
    [api.apiInstance, progressBarStore]
  );

  const postConclusionFileFourthStage = useCallback(
    async (id: number, file: File) => {
      const form = new FormData();
      form.append('file', file);
      const res: IFile[] = await api.apiInstance.post(`situation/${id}/conclusionFile/fourth`, form, true, {headers: {"Content-Type": "multipart/form-data"}});
      if (res) {
        if (progressBarStore.fourthStage) {
          progressBarStore.setFourthStage({...progressBarStore.fourthStage, conclusionFiles: res})
        }
      }
    },
    [api.apiInstance, progressBarStore]
  );

  const deleteConclusionFileFourthStage = useCallback(
    async (id: number, idFile: number | string) => {
      const res = await api.apiInstance.delete(`situation/${id}/conclusionFile/${idFile}/fourth`);
      if (res) {
        if (progressBarStore.fourthStage) {
          progressBarStore.setFourthStage({...progressBarStore.fourthStage, conclusionFiles: res})
        }
      }
    },
    [api.apiInstance, progressBarStore]
  );


  const publishDraftCase
    = useCallback(
    async (id: number) => {
      const res = await api.apiInstance.post(`situation/${id}/send-to-accept`, {});
      return !!res;
    },
    [api.apiInstance]
  );

  const getCaseComments = useCallback(
    async (id: number, isLoading: boolean = true) => {
      const res = await api.apiInstance.get(`situation/${id}/comments`, isLoading);
      historyStore.setHistory(res);
      return !!res
    },
    [historyStore, api.apiInstance]
  );

  const getCasePDF = useCallback(
    async (id: number) => {
      const config: AxiosRequestConfig = {responseType: 'blob'}
      return await api.apiInstance.get(`situation/${id}/zip`, true, config);
    },
    [api.apiInstance]
  );

  const saveDraft = useCallback(async (values: IFirstStage | ISecondStage | IThirdStage | IFourthStage, id?: number) => {
    const res = await api.apiInstance.put(`situation/${id}/draft`, values, true);
    if (res) {
      return !!res
    }
  }, [api.apiInstance])

  const sendQuestionEmail = useCallback(async (id: number, questionObj: any) => {
    const res = await api.apiInstance.post(`situation/${id}/email`, questionObj, true);
    if (res) {
      return !!res
    }
  }, [api.apiInstance])


  return {
    getCase,
    getCasePDF,
    postDraftCase,
    putFirstStageDraftCase,
    postAbdominalFilesSecond,
    deleteAbdominalFilesSecond,
    postLiverFilesSecondStage,
    deleteLiverFilesSecondStage,
    putSecondStageDraftCase,
    putThirdStageDraftCase,
    putFourthStageDraftCase,
    postAbdominalFileAfterTreatmentThirdStage,
    deleteAbdominalFileAfterTreatmentThirdStage,
    postConclusionFileFourthStage,
    deleteConclusionFileFourthStage,
    publishDraftCase,
    getCaseComments,
    deleteAbdominalFileAfterTreatment12ThirdStage,
    postAbdominalFileAfterTreatment12ThirdStage,
    saveDraft,
    sendQuestionEmail,
    deleteDrugInteractionFilesSecond,
    postDrugInteractionFilesSecond
  }
}
import { useCallback } from "react";
import { useApiCall } from "./useApiCall";
import { useAreasStore } from "../store/hooks";

export const useAreasApi = () => {
  const {api} = useApiCall();
  const areasStore = useAreasStore()
  const getAreas = useCallback(async () => {
    const res = await api.apiInstance.get('/areas');
    if (res) {
      areasStore.setAreas(res.items)
    }

  }, [api.apiInstance, areasStore])

  return {
    getAreas
  }
}
const theme = {
  colors: {
    orange: '#E65400',
    abbvie: '#071C47',
    blue2: '#466EAB',
    blue3: '#83B1FC',
    blue4: '#E8F0F8',
    red: '#A41313',
    yellow: '#E1E000',
    green: '#76BD22',
    white: '#FFFFFF',
    b3: '#F9FCFF',
    lightAbbvie: '#2C4476',
    color4: '#333333',
    grey1: '#646464',
    grey2: '#AFAFAF',
    grey3: '#F6F6F6',
    grey4: '#BDBDBD',
    grey5: '#E0E0E0',
    grey6: '#f2f2f2',
    violet: '#7E1A77',
    black: '#000000'
  },
  breakpoints: {
    extraLarge: 1535,
    large: 1199,
    medium: 899,
    small: 767,
    cell: 599
  }
};

export default theme;
import React, {FC} from "react";
import {Grid} from "@mui/material";
import {FileSource, FileUploadLayout} from "../../FileUploadLayout";
import {IFileUploadProps, IImage} from "../../../../../interfaces/user/image";


interface IProps extends IFileUploadProps {
  files: IImage[] | File[];
  addFile: (file: File | null) => Promise<void> | void;
  removeFile?: (id: number | string) => Promise<void> | void;
  fileType?: string[];
  fileSource?: FileSource;
  size?: 'small' | 'large'
  id: string
  disabled?: boolean
}


export const MultipleFileUpload: FC<IProps> = ({
                                                 disabled,
                                                 id,
                                                 files,
                                                 addFile,
                                                 removeFile,
                                                 fileType,
                                                 extensions,
                                                 maxFileSize,
                                                 fileSource,
                                                 size = 'small'
                                               }): JSX.Element => {

  return (
    <Grid item xs={12} position={'relative'} alignSelf='stretch' height={size === 'small' ? '58' : '75px'}>
      <FileUploadLayout
        id={id}
        disabled={disabled}
        fileType={fileType}
        extensions={extensions}
        maxFileSize={maxFileSize}
        files={files}
        addFile={addFile}
        removeFile={removeFile}
        fileSource={fileSource}
      />
    </Grid>
  )
}
import styled from 'styled-components';
import theme from "../../../../theme/theme";

export const MultipleSelectStyled = styled.div`
  height: 36px;
  width: 100%;
  margin-bottom: 16px;
  border-radius: unset;
  opacity: 0.7;

  & select {
    padding: 0 10px;
    opacity: 0.7;
  }

  & h5 {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  & .MuiInputBase-input {
    padding: 0 !important
  }

  & svg {
    top: 13px;
    right: 13px;
  }

  & .MuiInputBase-root {
    background-color: ${theme.colors.white};
    height: 36px;
    box-shadow: none;
  }
`;

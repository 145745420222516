import React, { useState } from 'react';
import { Grid } from '@mui/material';

import { Button } from '../../../../shared/button/Button';
import { statusColors, statusIcons, statusName } from '../../../../shared/user/cards/CaseCard';
import { Icon } from '../../../../shared/user/cards/CaseCard.styled';
import { TinyText } from '../ViewCase.styled';
import theme from '../../../../../theme/theme';
import { AdminTitle } from './TopViewCase.styled';
import { WritingComment } from './writingComment/WritingComment';
import { useAuthStore, useCaseStore } from '../../../../../store/hooks';
import { useCaseApi } from '../../../../../apiHooks';
import { useNavigate, useParams } from 'react-router-dom';
import { generateLink, UserLink } from '../../../../../utils';
import { checkPermission } from '../../../../../utils/checkPermission';
import { RoleFunction } from '../../../../../constants/projectUserRoles';
import { Comments } from './comments/Comments';
import { CaseStatus } from '../../../../../interfaces/user/draftCase/draft';

export const TopViewCase = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {getCasePDF} = useCaseApi();
  const [writingCommentVisible, setWritingCommentVisible] = useState(false);
  const caseStore = useCaseStore();
  const authStore = useAuthStore();
  const {roleFunction} = authStore;

  const {case: caseElement} = caseStore;

  const casePdfHandler = async () => {
    if (id) {
      const res = await getCasePDF(+id);
      const blob = new Blob([res], {type: 'application/zip'});
      const url = URL.createObjectURL(blob);
      window.open(url);
    }
  }

  const caseEditHandler = () => {
    navigate(generateLink(UserLink.EDIT_CASE, id))
  }

  if (!caseElement) {
    return <></>;
  }

  const statusIcon = statusIcons[caseElement.status];
  const statusColor = statusColors[caseElement.status];
  const statusNameTitle = statusName[caseElement.status].toUpperCase();

  return (<Grid container>
    <Grid item xs={3} display={'flex'} flexDirection={'column'}>
      <TinyText><strong>ID OPISU PRZYPADKU:</strong>{caseElement.customId}</TinyText>
      <TinyText><strong>AUTOR:</strong>{caseElement.createdBy}</TinyText>
      <TinyText><strong>ID PACJENTA:</strong>{caseElement.initials.toUpperCase()}</TinyText>
    </Grid>
    <Grid item xs={2}>
      <Grid container display={'flex'} flexDirection={'row'} gap={1} flexWrap={'nowrap'} justifyContent={'center'}>
        <Icon $position={'static'} color={statusColor}><img src={statusIcon} alt={'status'}/></Icon>
        <div>
          <TinyText><strong>STATUS:</strong></TinyText>
          <TinyText>{statusNameTitle}</TinyText>
        </div>
      </Grid>
    </Grid>
    <Grid item xs={7} display={'flex'} justifyContent={'flex-end'} gap={'20px'}>
      {checkPermission(roleFunction, [RoleFunction.ROLE_PARTICIPANT]) &&
        <Button size={'tiny'} text={'Zapytaj'} clickHandler={() => setWritingCommentVisible(prevState => !prevState)}
                width={'168px'} backgroundColor={theme.colors.blue2} color={'white'}/>}
      {checkPermission(roleFunction, [RoleFunction.ROLE_PUBLISHING]) && caseElement.status === CaseStatus.REJECT &&
        <Button clickHandler={caseEditHandler} size={'tiny'} text={'Edytuj'} width={'168px'}
                backgroundColor={theme.colors.orange} color={'white'}/>}
      <Button clickHandler={casePdfHandler} size={'tiny'} text={'Pobierz pdf'} width={'168px'}
              backgroundColor={theme.colors.blue2} color={'white'}/>
    </Grid>
    {caseElement.comments && caseElement.comments.length > 0 && <Grid xs={12} mt={'40px'}>
      <Comments comments={caseElement.comments}/>
    </Grid>}
    <Grid item xs={12} mt={'40px'}>
      <AdminTitle>
        {caseElement.title}
      </AdminTitle>
    </Grid>
    {writingCommentVisible && <WritingComment handleModalClose={() => setWritingCommentVisible(false)}/>}
  </Grid>)
}
import { ButtonContainerCoinfection } from './OtherCases.styled'
import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect } from "react";
import { useCasesStore } from "../../../../store/hooks";
import { useCasesApi } from "../../../../apiHooks";
import { UserLink } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import { COINFECTIONTYPE } from "../../../../constants/coinfection";

export const useOtherCases = () => {
  const casesStore = useCasesStore();
  const navigate = useNavigate();

  const {
    listOfOtherCases,
    isListOfOtherCasesLoaded,
    filteredListOfOtherCasesByCurrentPage,
    coinfections,
    searchValue,
    otherPageCount,
    currentPage,
    setCurrentPage
  } = casesStore;

  const {getListOfOtherCases} = useCasesApi();

  useEffect(() => {
    if (!isListOfOtherCasesLoaded) {
      if (listOfOtherCases === null) {
        void getListOfOtherCases()
      } else {
        void getListOfOtherCases(false)
      }
    }
  }, [getListOfOtherCases, listOfOtherCases, isListOfOtherCasesLoaded])

  useEffect(() => {
    return () => {
      casesStore.resetListOfOtherCases();
    }
  }, [casesStore])

  const addedCoinfection = (
    <ButtonContainerCoinfection>
      <FormControlLabel
        control={
          <Checkbox
            onChange={() => casesStore.switchCoinfectionFilters([COINFECTIONTYPE.NOCOINFECTION])}
            checked={coinfections.includes(COINFECTIONTYPE.NOCOINFECTION)}
          />
        }
        label={"bez koinfekcji"}
      />

      <FormControlLabel
        control={
          <Checkbox
            onChange={() => casesStore.switchCoinfectionFilters([COINFECTIONTYPE.HIVHCV, COINFECTIONTYPE.HBVHCV, COINFECTIONTYPE.HIVHBHCV])}
            checked={coinfections.some(type => [COINFECTIONTYPE.HIVHCV, COINFECTIONTYPE.HBVHCV, COINFECTIONTYPE.HIVHBHCV].includes(type))}
          />
        }
        label={"z koinfekcjami"}
      />
    </ButtonContainerCoinfection>
  );

  const addCase = () => {
    navigate(UserLink.ADD_CASE);
  }

  const onChangeSearchHandler = (value: string) => casesStore.setSearchValue(value);

  return {
    filteredListOfOtherCasesByCurrentPage,
    addedCoinfection,
    addCase,
    searchValue,
    onChangeSearchHandler,
    pageCount: otherPageCount,
    currentPage,
    setCurrentPage
  }
}
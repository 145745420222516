import {UserLayout} from '../../../shared/user/layout/UserLayout';
import {useOtherCases} from "./useOtherCases.vm";
import {FilterContainer, Section, NoCases} from './OtherCases.styled'
import {SearchBar} from "../../../shared/ui/searchBar/SearchBar";
import React from "react";
import {Grid} from "@mui/material";
import {CaseCard} from "../../../shared/user/cards/CaseCard";
import {MuiPagination} from "../../../shared/pagination/MuiPagination";
import {observer} from "mobx-react-lite";

export const OtherCases = observer(() => {
  const {
    filteredListOfOtherCasesByCurrentPage,
    addedCoinfection,
    searchValue,
    onChangeSearchHandler,
    pageCount,
    currentPage,
    setCurrentPage
  } = useOtherCases()
  return (<UserLayout> <FilterContainer>
    {addedCoinfection}
    <SearchBar filterValue={searchValue} filterChangeHandler={onChangeSearchHandler}/>
  </FilterContainer>
    <Section>
      {filteredListOfOtherCasesByCurrentPage && filteredListOfOtherCasesByCurrentPage.length > 0 ?
        <Grid container flexDirection={'column'} justifyContent={'space-between'} alignItems={'center'}>
          <Grid container gap={'20px'} display={'flex'}>
            {filteredListOfOtherCasesByCurrentPage.map(({
                                                          status,
                                                          id,
                                                          customId,
                                                          title,
                                                          updatedAt,
                                                          coInfection
                                                        }) => (
              <Grid item width={'100%'} maxWidth={'15%'} key={id}>
                <CaseCard
                  key={id}
                  id={id}
                  coInfection={coInfection}
                  status={status}
                  customId={customId}
                  updatedAt={updatedAt}
                  title={title}/>
              </Grid>
            ))}
          </Grid>
          <MuiPagination
            count={pageCount}
            page={currentPage}
            onChange={(e, value: number) => {
              setCurrentPage(value);
            }}/>
        </Grid>
        : <NoCases>Nie ma jeszcze żadnych dodanych opisów przypadków.</NoCases>}
    </Section>
  </UserLayout>)
})
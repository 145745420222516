import React, { FC } from 'react';
import { Input } from "../../../../shared/ui/input/Input";
import { Grid } from "@mui/material";
import { Button } from '../../../../shared/button/Button'
import { ReactComponent as ArrowRight } from './../../../../../assets/icon/arrowRight.svg'
import { formData, IFormDataChangePassword, } from "./changePasswordFormData";
import { useChangePasswordForm } from "./useChangePasswordForm.vm";
import theme from "../../../../../theme/theme";
import { RegisterViewingFormContainer } from './ChangePasswordForm.styled'
import { AuthLabel } from "../../../../shared/ui/authLabel/AuthLabel";

interface IProps {
  isRegister?: boolean;
}

export const ChangePasswordForm: FC<IProps> = () => {
  const {
    formik,
    confirmForm
  } = useChangePasswordForm()

  return <RegisterViewingFormContainer onSubmit={formik.handleSubmit}>
    <AuthLabel title={'zmiana hasła'} bcg={theme.colors.blue2}/>
    <Grid container justifyContent={'space-between'}>
      {formData.map((el: IFormDataChangePassword) =>
        (
          <Input key={el.name} label={el.label} name={el.name} type={el.type} value={formik.values[el.name]}
                 color={'white'}
                 isUpperCase={true}
                 onChange={formik.handleChange}
                 placeholder={el.placeholder}
                 isError={formik.touched[el.name] && Boolean(formik.errors[el.name])}
                 textError={formik.errors[el.name]}
          />
        )
      )}
    </Grid>
    <Button text={'nadaj hasło i zaloguj'} clickHandler={confirmForm}
            backgroundColor={theme.colors.abbvie}
            hoverColor={theme.colors.lightAbbvie}
            color={theme.colors.white}
            size={'small'} type={'submit'}
            maxWidth={'315px'} icon={<ArrowRight/>}/>
  </RegisterViewingFormContainer>
}


import styled from "styled-components";
import theme from "../../../../../theme/theme";

export const ButtonDraft = styled.button`
  border: none;
  height: 32px;
  background-color: transparent;
  padding: 0;
  text-transform: uppercase;
  color: ${theme.colors.abbvie};
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 14.4px */
  letter-spacing: 2.4px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`

export const DraftBtnContainer = styled.div`
  padding: 0 16px;
  height: 32px;
  position: fixed;
  bottom: 98px;
  display: flex;
  margin-right: -180px;
  justify-content: flex-end;

  svg {
    width: 13px;
    height: 13px;

    path {
      fill: ${theme.colors.abbvie};
    }
  }
`
import { ButtonDraft, DraftBtnContainer } from './SaveDraftBtn.styled'
import { ReactComponent as Pen } from "../../../../../assets/icon/edit.svg";
import React from "react";
import { IFirstStage } from "../../../../../interfaces/user/draftCase/firstStage";
import { ISecondStage } from "../../../../../interfaces/user/draftCase/secondStage";
import { IThirdStage } from "../../../../../interfaces/user/draftCase/thirdStage";
import { IFourthStage } from "../../../../../interfaces/user/draftCase/fouthStage";
import { useSaveDraftBtnVm } from "./useSaveDraftBtn.vm";
import { observer } from "mobx-react-lite";

interface IProps {
  values: IFirstStage | ISecondStage | IThirdStage | IFourthStage
}

export const SaveDraftBtn: React.FC<IProps> = observer(({values}) => {
  const {saveCaseHandler} = useSaveDraftBtnVm()

  return (
    <DraftBtnContainer>
      <ButtonDraft onClick={() => saveCaseHandler(values)}>zapisz szkic<Pen/></ButtonDraft>
    </DraftBtnContainer>
  )
})
import React, {FC, useState} from 'react';

import {Input} from '../../../../../shared/ui/input/Input';
import {ContentModal} from '../../../../../shared/modal/ContentModal';
import {
  ConfirmationTextModal
} from '../../../../../shared/modal/sharedModals/confirmationModal/ConfirmationModal.styled';
import {Button} from '../../../../../shared/button/Button';
import {CancelIcon} from '../../../../../shared/modal/modalIcons/CancelIcon';
import {AddedInfo, ButtonContainer, SmallParagraph} from './RejectCase.styled';
import theme from '../../../../../../theme/theme';

interface IProps {
  handleSave: (comment: string) => void;
}

export const RejectCase: FC<IProps> = ({handleSave}) => {
  const [comment, setComment] = useState('');
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [isError, setIsError] = useState(false);

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = e.target.value;

    const isEmptyValue = value === '';

    setIsButtonActive(!isEmptyValue);
    setIsError(isEmptyValue);
    setComment(value);
  };

  return (<ContentModal
      iconType={<CancelIcon/>}
      text={
        <ConfirmationTextModal>
          <p>Wpisz <b>komentarz uzasadniający</b> zwrócenie opisu przypadku medycznego do korekty.</p>
          <SmallParagraph>Komentarz zostanie automatycznie przekazany autorowi przypadku. Na jego podstawie autor będzie
            mógł poprawić OPIS PRZYPADKU i przesłać go ponownie do
            akceptacji. </SmallParagraph></ConfirmationTextModal>}>
      <>
        <Input
          size={'medium'}
          isRequired={true}
          label={'KOMENTARZ:'}
          type={'textarea'}
          onChange={(e) => onChange(e)}
          name={'comment'}
          placeholder={'Dodaj komentarz...'}
          maxWidth={'100%'}
          isError={isError}
        />
        <ButtonContainer>
          <AddedInfo>*Pola obowiązkowe</AddedInfo>
          <Button
            backgroundColor={isButtonActive ? 'black' : theme.colors.blue2}
            color={isButtonActive ? 'white' : theme.colors.white}
            text={'Zapisz i dodaj'}
            clickHandler={() => isButtonActive && handleSave(comment)}
            size={'small'}
            width={'188px'}/>
        </ButtonContainer>
      </>
    </ContentModal>
  );
};
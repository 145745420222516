import React from 'react'
import {
  CaseCardContainer,
  DataLarge,
  DataMiddle,
  Date,
  Icon,
  StyledButton,
  StyledNext,
  StyledStatus
} from "./CaseCard.styled";
import {dateStringify, generateLink, timeStringify, UserLink} from "../../../../utils";
import success from '../../../../assets/icon/success.svg'
import pending from '../../../../assets/icon/pending.svg'
import draft from '../../../../assets/icon/edit.svg'
import reject from '../../../../assets/icon/reject.svg'
import check from '../../../../assets/icon/doubleCheck.svg'
import search from '../../../../assets/icon/search.svg'
import theme from "../../../../theme/theme";
import {useNavigate} from "react-router-dom";
import {CaseStatus} from "../../../../interfaces/user/draftCase/draft";
import {coinfectonTypes} from "../../../../constants/coinfection";

interface IProps {
  id: number;
  status: CaseStatus;
  customId: string;
  coInfection: number;
  title: string;
  updatedAt: string;
  maxWidth?: string;
}

interface IStatusIcons {
  [key: string]: string;
}

interface IStatus {
  [key: string]: string;
}


export const statusIcons: IStatusIcons = {
  [CaseStatus.DRAFT]: draft,
  [CaseStatus.ACCEPT]: success,
  [CaseStatus.REJECT]: reject,
  [CaseStatus.SENT]: pending,
  [CaseStatus.TO_ACCEPT]: pending,
};

export const statusColors: IStatus = {
  [CaseStatus.DRAFT]: theme.colors.abbvie,
  [CaseStatus.ACCEPT]: theme.colors.green,
  [CaseStatus.REJECT]: theme.colors.orange,
  [CaseStatus.SENT]: theme.colors.yellow,
  [CaseStatus.TO_ACCEPT]: theme.colors.yellow
};

export const statusName: IStatus = {
  [CaseStatus.DRAFT]: 'szkic',
  [CaseStatus.ACCEPT]: 'zaakceptowany',
  [CaseStatus.REJECT]: 'do edycji',
  [CaseStatus.SENT]: 'oczekujący',
  [CaseStatus.TO_ACCEPT]: 'oczekujący'
}

const statusFurther: IStatus = {
  [CaseStatus.DRAFT]: 'dokończ',
  [CaseStatus.ACCEPT]: 'zobacz',
  [CaseStatus.REJECT]: 'popraw',
  [CaseStatus.SENT]: 'podgląd',
  [CaseStatus.TO_ACCEPT]: 'podgląd'
}

const statusFurtherIcon: IStatus = {
  [CaseStatus.DRAFT]: draft,
  [CaseStatus.ACCEPT]: check,
  [CaseStatus.REJECT]: reject,
  [CaseStatus.SENT]: search,
  [CaseStatus.TO_ACCEPT]: search,
}
export const CaseCard: React.FC<IProps> = ({id, status, customId, coInfection, title, updatedAt, maxWidth}) => {
  const navigate = useNavigate();
  const icon = statusIcons[status];
  const color = statusColors[status];
  const name = statusName[status];
  const furtherStep = statusFurther[status];
  const furtherIcon = statusFurtherIcon[status];

  const navigateToDraftCaseHandler = () => {
    if (status === CaseStatus.DRAFT) {
      navigate(generateLink(UserLink.EDIT_CASE, id))
    } else {
      navigate(generateLink(UserLink.VIEW_CASE, id))
    }
  }

  const convertCoinfection = () => {
    const foundElement = coinfectonTypes.find(el => el.optionId === coInfection)
    return foundElement?.value
  }

  return (
    <CaseCardContainer maxWidth={maxWidth}>
      <Icon color={color}><img src={icon} alt={'status'}/></Icon>
      <StyledStatus color={color}>{name}</StyledStatus>
      <Date>{timeStringify(updatedAt)} {` - `} {dateStringify(updatedAt)}</Date>
      <DataMiddle>
        Id: {customId}
      </DataMiddle>
      <DataLarge>
        {title}
      </DataLarge>
      <Date>
        {convertCoinfection()}
      </Date>
      <StyledButton onClick={navigateToDraftCaseHandler}>
        {furtherStep}
        <StyledNext><img src={furtherIcon} alt={'status'}/></StyledNext>
      </StyledButton>
    </CaseCardContainer>
  )
}



import React from 'react'
import { CheckboxContainer, Input, Label } from "./Checkbox.styled";

interface IProps {
  label: string
  checked?: boolean
  onChange: (e: any) => void;
  isError?: boolean;
  disabled?: boolean;
  id: string
  textColor?: string
  name?: string
  fontSize?: string
  fontWeight?: string
}

export const Checkbox: React.FC<IProps> = ({
                                             name,
                                             disabled,
                                             label,
                                             checked,
                                             onChange,
                                             isError,
                                             id,
                                             textColor,
                                             fontSize,
                                             fontWeight
                                           }) => {
  return (
    <CheckboxContainer>
      <Input name={name} disabled={disabled} type="checkbox" id={id} checked={checked}
             onChange={e => onChange(e.target.checked)}/>
      <Label $fontWeight={fontWeight} $fontSize={fontSize} checked={checked} htmlFor={id} $isError={isError}
             dangerouslySetInnerHTML={{__html: label}}
             $textColor={textColor}/>
    </CheckboxContainer>
  );
};
import { useProgressBarStore } from "../../../../../../store/hooks";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Stages } from "../../progressBar/ProgressBar.helper";
import { IFourthStage } from "../../../../../../interfaces/user/draftCase/fouthStage";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { useCaseApi } from "../../../../../../apiHooks/useCaseApi";
import { setAllFieldsTouched } from "../stages.helper";

export const validationSchema = yup.object().shape({
  infoAboutTherapy: yup
    .string()
    .required(),
  conclusion: yup
    .string()
    .required(),
  conclusionCheckbox: yup
    .boolean()
})

export const useFourthStageVm = () => {
  const [formInjection, setFormInjection] = useState<boolean | null>(null);
  const progressBarStore = useProgressBarStore();
  const {fourthStage, isClicked} = progressBarStore;
  const params = useParams()
  const {id} = params
  const {deleteConclusionFileFourthStage, postConclusionFileFourthStage} = useCaseApi()
  const formik = useFormik<IFourthStage>({
    enableReinitialize: true,
    initialValues: {
      infoAboutTherapy: '',
      conclusion: '',
      conclusionCheckbox: false
    },
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
    }
  })

  const {errors, setFieldValue, validateForm, setFieldTouched} = formik

  useEffect(() => {
    if (formInjection) {

      if (Object.keys(errors).length === 0) {
        progressBarStore.setMaxEditStage(Stages.FIFTH);
      } else {
        progressBarStore.setMaxEditStage(Stages.FOURTH);
      }
    }
  }, [errors, formInjection, progressBarStore])

  useEffect(() => {
    if (isClicked) {
      setAllFieldsTouched(formik.values, setFieldTouched)
      void validateForm()
    }
  }, [isClicked, validateForm, setFieldTouched, formik.values])

  useEffect(() => {
    if (progressBarStore.fourthStage && id) {
      setFieldValue('infoAboutTherapy', progressBarStore.fourthStage.infoAboutTherapy ?? '')
      setFieldValue('conclusion', progressBarStore.fourthStage.conclusion ?? '')
      setFieldValue('conclusionCheckbox', progressBarStore.fourthStage.conclusionCheckbox ?? false)
    }
  }, [id, progressBarStore.fourthStage, setFieldValue])

  useEffect(() => {
    if ((formik.dirty || Object.keys(formik.touched).length !== 0) && Object.keys(formik.errors).length === 0) {
      const fourthStageConvert: IFourthStage = {
        infoAboutTherapy: formik.values.infoAboutTherapy,
        conclusion: formik.values.conclusion,
        conclusionCheckbox: formik.values.conclusionCheckbox,
        conclusionFiles: progressBarStore.fourthStage ? progressBarStore.fourthStage.conclusionFiles : [],
      }
      setFormInjection(true);
      progressBarStore.setFourthStage(fourthStageConvert);
    } else {
      progressBarStore.setMaxEditStage(Stages.FOURTH);
    }
  }, [formik.errors, formik.values, progressBarStore, formik.touched, formik.dirty])

  const addConclusionFile = async (file: File | null) => {
    if (file && id) {
      await postConclusionFileFourthStage(+id, file);
    }
  }

  const removeConclusionFile = async (idFile: number | string) => {
    if (idFile && id) {
      await deleteConclusionFileFourthStage(+id, idFile);
    }
  }

  return {
    formik,
    conclusionFiles: fourthStage && fourthStage.conclusionFiles ? fourthStage.conclusionFiles : [],
    removeConclusionFile,
    addConclusionFile
  }
}
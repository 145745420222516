import styled, { css } from 'styled-components';
import theme from '../../../../theme/theme';
import { LabelProps } from "./ViewCase.helper";

export const TinyText = styled.p`
  color: ${theme.colors.abbvie};
  font-size: 11px;
  letter-spacing: 1.1px;

  strong {
    font-weight: 800;
    margin-right: 4px;
  }
`
export const NormalTextMarginContainer = styled.div`
  margin: 0 32px;
`

const styledText = css<{ $capitalize?: boolean }>`
  color: ${theme.colors.grey1};
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1.6px;
  line-height: 150%;
  text-transform: ${props => props.$capitalize && 'capitalize'};
`

export const NormalText = styled.p<{ $capitalize?: boolean }>`
  ${styledText};
`

export const NormalTextDiv = styled.div<{ $capitalize?: boolean }>`
  ${styledText};
`

export const GroupForm = styled.p`
  color: ${theme.colors.abbvie};
  text-align: left;
  text-transform: uppercase;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid rosybrown;
`

export const Label = styled.p<LabelProps>`
  color: ${theme.colors.blue2};
  margin-bottom: ${({$mb}) => $mb || $mb === 0 ? `${$mb * 4}px` : '12px'};
  font-size: ${({$size}) => `
    ${$size === 'small' ? '12px' : ''}
    ${($size === 'normal' || !$size) ? '16px' : ''}
    ${$size === 'large' ? '20px' : ''}
  `};
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;

  & span.unit {
    font-size: 12px;
    margin-left: 4px;
    text-transform: none;
  }
`

export const LabelSmall = styled.p`
  color: ${theme.colors.blue2};
  margin: 12px 0 4px 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 200%;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`
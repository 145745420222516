import { Input } from "../../../../shared/ui/input/Input";
import { Button } from "../../../../shared/button/Button";
import { useLoginForm } from './useLoginForm'
import { formData } from './logindata'
import theme from './../../../../../theme/theme'
import { ReactComponent as ArrowRight } from "../../../../../assets/icon/arrowRight.svg";
import { GeneralLink } from "../../../../../utils";
import { RegisterViewingFormContainer, StyledLink } from "./LoginForm.styled";
import { AuthLabel } from "../../../../shared/ui/authLabel/AuthLabel";
import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";

export const LoginForm = observer(() => {
  const {formik, confirmForm} = useLoginForm()

  return (
    <RegisterViewingFormContainer
      onSubmit={formik.handleSubmit}
    >
      <AuthLabel title={'logowanie'} bcg={theme.colors.blue2}/>
      <div>
        {formData.map(el =>
          <Input color={theme.colors.white}
                 key={el.name}
                 label={el.label}
                 name={el.name}
                 isUpperCase={true}
                 type={el.type}
                 value={formik.values[el.name]}
                 onChange={formik.handleChange}
                 placeholder={el.placeholder}
                 textError={formik.errors[el.name]}
                 isError={formik.touched[el.name] && Boolean(formik.errors[el.name])}
          />
        )}
      </div>
      <Grid width={'132px'} display={'flex'} flexDirection={'column'}
            justifyContent={'flex-end'} margin={'0 0 0 auto'}>
        <Button type='submit'
                clickHandler={confirmForm}
                text={'zaloguj'}
                backgroundColor={theme.colors.abbvie}
                hoverColor={theme.colors.lightAbbvie}
                color={theme.colors.white}
                size={'tiny'}
                maxWidth={'132px'} icon={<ArrowRight/>}
        />
        <StyledLink to={GeneralLink.RETRIEVE_PASSWORD}>
          Resetuj hasło</StyledLink>
      </Grid>
    </RegisterViewingFormContainer>
  )
})

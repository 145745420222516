import React from 'react';

import { generalLinks } from "./RoutesGuest";
import { UserLink } from "../../../../../utils";

import { MyCases } from '../../../../pages/user/myCases/MyCases';
import { OtherCases } from '../../../../pages/user/otherCases/OtherCases';
import { DraftCase } from "../../../../pages/user/draftCase/DraftCase";
import { Home } from "../../../../pages/user/home/Home";
import { Profile } from "../../../../pages/user/profile/Profile";
import { ViewCase } from '../../../../pages/user/viewCase/ViewCase';

export const onlyPublishingUserLinks = [
  {
    path: UserLink.HOME,
    element: <Home/>,
  },
  {
    path: UserLink.ADD_CASE,
    element: <DraftCase/>
  },
  {
    path: UserLink.VIEW_CASE,
    element: <ViewCase/>
  },
  {
    path: UserLink.EDIT_CASE,
    element: <DraftCase/>
  },
  {
    path: UserLink.MY_CASES,
    element: <MyCases/>
  },
  {
    path: UserLink.OTHER_CASES,
    element: <OtherCases/>
  },
  {
    path: UserLink.PROFILE,
    element: <Profile/>
  }
]

export const publishingUserLinksExtends = generalLinks.concat(onlyPublishingUserLinks);
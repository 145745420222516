import { UserLayout } from '../../../shared/user/layout/UserLayout';
import { TopViewCase } from './topViewCase/TopViewCase';
import { PatientSection } from './patientSection/PatientSection';
import { DiagnosisSection } from './diagnosisSection/DiagnosisSection';
import { QualificationSection } from './qualificationSection/QualificationSection';
import { TreatmentSection } from './treatmentSection/TreatmentSection';
import { useCaseApi } from '../../../../apiHooks';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCaseStore } from '../../../../store/hooks';
import { observer } from 'mobx-react-lite';

export const ViewCase = observer(() => {
  const caseStore = useCaseStore();
  const {getCase} = useCaseApi();
  const {id} = useParams();

  useEffect(() => {
    if (id) {
      void getCase(+id);
    }
  }, [getCase, id])

  useEffect(() => {
    return () => {
      caseStore.resetCase();
    }
  }, [caseStore])

  const isCase = Boolean(caseStore.case);

  return (
    (<UserLayout>
      {isCase && <>
        <TopViewCase/>
        <PatientSection/>
        <DiagnosisSection/>
        <QualificationSection/>
        <TreatmentSection/>
      </>}
    </UserLayout>)
  )
});
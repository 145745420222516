import { AuthLayout } from "../register/layout/RegisterLayout";
import background from "../../../../assets/img/background_blue.jpg";
import { LoginForm } from "./form/LoginForm";
import { Paragraph } from "./form/LoginForm.styled";
import { observer } from "mobx-react-lite";

const Login = observer(() => {
  return (
    <AuthLayout bcgImage={background}>
      <LoginForm/>
      <Paragraph>Serwis nie służy do zgłaszania zdarzeń niepożądanych. Jeśli chcesz zgłosić zdarzenie niepożądane, użyj
        dedykowanego serwisu https://alertpolska.pl</Paragraph>
    </AuthLayout>
  )
})

export default Login
import styled from "styled-components";
import theme from "../../../../../theme/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
`

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  width: 25%;
`

export const Line = styled.div`
  flex: 1 1 auto;
  position: absolute;
  top: 15px;
  left: calc(-50% + 15px);
  right: calc(50% + 15px);
  display: block;
  border-color: rgb(189, 189, 189);
  border-top-style: solid;
  border-top-width: 1px;
`

export const LastLine = styled.div`
  flex: 1 1 auto;
  position: absolute;
  top: 15px;
  left: calc(50% + 15px);
  right: calc(-50% + 15px);
  display: block;
  border-color: rgb(189, 189, 189);
  border-top-style: solid;
  border-top-width: 1px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  padding-right: 8px;
  flex: 1 1 0%;
  position: relative;
  align-items: center;
`

export const Step = styled.div<{ $color: string }>`
  padding-right: 0;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background-color: ${props => props.$color};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StepName = styled.div`
  text-align: center;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  padding-top: 8px;
  color: ${theme.colors.abbvie}
`


export const Title = styled.h1`
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 38.4px */
  letter-spacing: 6.4px;
  text-transform: uppercase;
  color: ${theme.colors.abbvie};
  align-self: center;

  span {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3.2px;
  }
`
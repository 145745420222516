import React, { FC } from 'react';
import { Grid } from "@mui/material";
import { observer } from 'mobx-react-lite';
import { Button } from "../../../../shared/button/Button";
import theme from "../../../../../theme/theme";
import {
  Column,
  Container,
  Item,
  LastLine,
  Line,
  Step,
  StepName,
  Title
} from "./DraftLayout.styled";
import { stages } from "../progressBar/ProgressBar.helper";
import { ReactComponent as Thick } from "../../../../../assets/icon/thick.svg";
import { IStage } from "../../../../../interfaces/user/draftCase/progressBar";
import { useDraftLayout } from './useDraftLayout.vm'

export interface IDraftLayout {
  validNext: boolean;
  stage: IStage;
}

const data = [1, 2, 3, 4]

export const DraftLayout: FC<IDraftLayout> = observer(({validNext, stage}) => {
  const {
    isPrevVisible,
    prevArrowHandler,
    isNextVisible,
    nextArrowHandler,
    currentStage,
    component
  } = useDraftLayout({validNext, stage})

  return (
    <Grid container position={'relative'} justifyContent={'center'}>
      <Grid item xs={12} display={'flex'} mb={2}>
        <Grid item xs={3} display={'flex'}>{isPrevVisible &&
          <Button clickHandler={prevArrowHandler} text={'< wstecz'} size={'tiny'} width={'126px'}
                  backgroundColor={theme.colors.abbvie}
                  hoverColor={theme.colors.lightAbbvie}
                  fontWeight={400} color={theme.colors.white}/>}
        </Grid>
        <Grid item xs={6} display={'flex'} justifyContent={'center'}>
          <Title>{stages[currentStage - 1]?.isStepper && (
            <span>{`${currentStage} / ${stages.filter(el => el.isStepper).length}`}</span>)}&nbsp;{stages[currentStage - 1]?.name}</Title>
        </Grid>
        <Grid item xs={3} display={'flex'} justifyContent={'flex-end'}>
          {isNextVisible &&
            <Button
              clickHandler={nextArrowHandler}
              text={'dalej >'}
              size={'tiny'}
              width={'126px'}
              backgroundColor={theme.colors.abbvie}
              hoverColor={theme.colors.lightAbbvie}
              fontWeight={400}
              color={theme.colors.white}/>}
        </Grid>
      </Grid>
      <Container>
        {stages.map((el, index) => el.isStepper && <Item key={el.name}>
          <>
            <Line/>
            <Column>
              <Step
                $color={el.stage === currentStage ? theme.colors.blue3 : el.stage < currentStage ? theme.colors.green : theme.colors.grey2}><Thick/></Step>
              <StepName>{el.name}</StepName>
            </Column>
            {data.length - 1 === index && <LastLine/>}
          </>
        </Item>)}
      </Container>
      {component}
    </Grid>

  )
})
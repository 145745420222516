import {useCallback} from 'react';
import {useAuthStore} from '../store/hooks';
import {useApiCall} from './useApiCall';
import {IUserLoginResponse} from "../interfaces/admin/user/userLoginResponse";
import {useParams} from "react-router-dom";

export const useAuthApi = () => {
  const {api} = useApiCall();

  const authStore = useAuthStore();
  const params = useParams()
  const {token} = params;

  const loginUser = useCallback(
    async (username: string, password: string, pwz: number) => {

      const res: IUserLoginResponse = await api.apiInstance.post('login_check', {
        pwz,
        username,
        password
      }, true);

      if (res) {
        authStore.appLogin(res);
        return res;
      }
      return false
    },
    [api.apiInstance, authStore]
  );

  const checkAuth = useCallback(
    async () => {
      const res: IUserLoginResponse = await api.apiInstance.get('is-auth');
      if (res.role) {
        authStore.appLogin(res);
      } else {
        authStore.isSessionExpired = true;
      }
      authStore.isUserLoad = true;
    },
    [api.apiInstance, authStore]
  );

  const serverLogoutUser = useCallback(async () => {
    const res = await api.apiInstance.post('logout', {});
    if (res) {
      await checkAuth();
      return true;
    }

  }, [checkAuth, api.apiInstance]);

  const registerPublishUser = useCallback(async (token?: string, data?: any) => {
    const res = await api.apiInstance.post(`registration/publishing/${token}`, data);
    if (res) {
      return true
    }
  }, [api.apiInstance])

  const registerViewingUser = useCallback(async (data?: any) => {
    const res = await api.apiInstance.post('registration/participant', data);
    if (res) {
      return true
    }
  }, [api.apiInstance])

  const register = useCallback(async (password: string) => {
    const res = await api.apiInstance.post(`registration/${token}`, {password}, true);
    if (res) {
      return true
    }

  }, [api.apiInstance, token])

  const changePassword = useCallback(async (password: string) => {
    const res = await api.apiInstance.post(`reset-password/${token}`, {password}, true);
    if (res) {
      return true
    }

  }, [api.apiInstance, token])

  const resetPassword = useCallback(async (email: string) => {
    const res = await api.apiInstance.post('reset-password', {email}, true);
    if (res) {
      return true
    }
  }, [api.apiInstance])

  const verifyToken = useCallback(async (token: string) => {
    const res = await api.apiInstance.get(`registration/verify/${token}`, true);
    if (res) {
      authStore.emailInfoVerify(res)
    }

  }, [api.apiInstance, authStore])

  return {
    checkAuth,
    loginUser,
    serverLogoutUser,
    register,
    registerPublishUser,
    registerViewingUser,
    resetPassword,
    changePassword,
    verifyToken
  };
};

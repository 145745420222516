import { useParams } from "react-router-dom";
import { useAuthStore } from "../../../../../../store/hooks";
import { useCaseApi } from "../../../../../../apiHooks";
import { useState } from "react";

export const useWritingCommentVm = (handleModalClose: () => void) => {
  const params = useParams()
  const authStore = useAuthStore()
  const {sendQuestionEmail} = useCaseApi()
  const [questionContent, setQuestionContent] = useState('')

  const sendQuestion = async () => {
    if (params.id) {
      const questionobject = {
        fullName: authStore.fullName,
        email: authStore.user,
        message: questionContent
      }
      const res = await sendQuestionEmail(+params.id, questionobject)
      if (res) {
        handleModalClose()
        setQuestionContent('')
      }
    }
  }

  return {
    sendQuestion,
    questionContent,
    setQuestionContent
  }
}
import React, { MouseEvent } from 'react'
import { Grid } from "@mui/material";
import { FormikValues } from 'formik'
import { IFormData } from "../registerPublishUser/form/registerFormData";
import { Input } from "../../../../shared/ui/input/Input";
import { Checkbox } from "../../../../shared/ui/checkbox/Checkbox";
import { ReactComponent as ArrowRight } from './../../../../../assets/icon/arrowRight.svg'
import { RegisterFormContainer } from "./RegisterForm.styled";
import { Button } from "../../../../shared/button/Button";
import theme from "../../../../../theme/theme";
import { AuthLabel } from "../../../../shared/ui/authLabel/AuthLabel";
import rulesPDF from './../../../../../../src/files/rules.pdf';
import policyPDF from './../../../../../../src/files/policy.pdf';

interface IProps {
  formik: FormikValues,
  isChecked: boolean;
  isErrorCheckbox: boolean
  setChecked: (checked: boolean) => void
  confirmForm: (e: MouseEvent<HTMLButtonElement>) => Promise<void>
  emailInfo?: string
  labelColor?: string
  buttonTextColor?: string
  buttonBcgColor?: string
  formData: any
}

export const RegisterForm: React.FC<IProps> = ({
                                                 formik,
                                                 isChecked,
                                                 isErrorCheckbox,
                                                 setChecked,
                                                 confirmForm,
                                                 labelColor,
                                                 buttonBcgColor,
                                                 buttonTextColor,
                                                 formData,
                                                 emailInfo
                                               }) => {
  return (
    <RegisterFormContainer autoComplete="new-password" onSubmit={formik.handleSubmit}
                           $buttonTextColor={buttonTextColor}>
      <AuthLabel title={'Rejestracja: '} bcg={theme.colors.violet} subTitle={emailInfo}/>
      <Grid container justifyContent={'space-between'}>
        {formData.map((el: IFormData) =>
          el.name === 'affiliation' || el.name === 'pwz' ?
            (
              <Grid item xs={6} pr={el.name === 'pwz' ? 2 : 0} key={el.name}>
                <Input color={labelColor} label={el.label} name={el.name} type={el.type}
                       value={formik.values[el.name]}
                       onChange={formik.handleChange}
                       isUpperCase={true}
                       placeholder={el.placeholder}
                       textError={formik.errors[el.name]}
                       isError={formik.touched[el.name] && Boolean(formik.errors[el.name])}
                />
              </Grid>
            ) : (
              <Input color={labelColor} key={el.name} label={el.label} name={el.name} type={el.type}
                     value={formik.values[el.name]}
                     isUpperCase={true}
                     onChange={formik.handleChange}
                     placeholder={el.placeholder}
                     textError={formik.errors[el.name]}
                     isError={formik.touched[el.name] && Boolean(formik.errors[el.name])}
              />
            )
        )}
      </Grid>
      <Grid container>
        <Grid item xs={7}>
          <Checkbox id={'rules'} fontSize={'10px'}
                    fontWeight={'300'}
                    textColor={isErrorCheckbox ? theme.colors.red : labelColor}
                    label={'Przeczytałem/am i akceptuję <br/>' +
                      `<a  href=${rulesPDF} target="_blank" rel="noreferrer">regulamin</a> i ` +
                      `<a href=${policyPDF} target="_blank" rel="noreferrer"> politykę prywatności</a>`}
                    checked={isChecked}
                    isError={isErrorCheckbox}
                    onChange={setChecked}/>
        </Grid>

        <Grid item xs={5}>
          <Button text={'zarejestruj'} clickHandler={confirmForm}
                  backgroundColor={buttonBcgColor}
                  color={buttonTextColor}
                  size={'tiny'} type={'submit'}
                  maxWidth={'132px'} icon={<ArrowRight/>}/>
        </Grid>
      </Grid>
    </RegisterFormContainer>
  )
}

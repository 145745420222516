import { useRootStore } from '../RootStateContext';

export const useProgressBarStore = () => {
  const rootStore = useRootStore();

  if (!rootStore?.progressBarStore) {
    throw new Error('progressBarStore store should be defined');
  }

  return rootStore.progressBarStore;
};

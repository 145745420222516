import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { adminLinksExtends } from './routesByRole/RoutesAdmin';
import { guestLinksExtends } from './routesByRole/RoutesGuest';
import { adminAcceptingScientificLinksExtends } from './routesByRole/RoutesAdminAcceptingScientific';
import { publishingUserLinksExtends } from './routesByRole/RoutesPublishingUser';
import { viewingUserLinksExtends } from './routesByRole/RoutesViewingUser';
import { GlobalLoader } from '../globalLoader/GlobalLoader';
import { useAuthStore } from '../../../../store/hooks';
import { RoleFunction } from '../../../../constants/projectUserRoles';

export const AppRouting = observer(() => {
  const authStore = useAuthStore();
  switch (authStore.roleFunction) {
    case RoleFunction.ROLE_ADMIN:
      return <RouterProvider router={createBrowserRouter(adminLinksExtends)} fallbackElement={<GlobalLoader/>}/>;
    case RoleFunction.ROLE_ACCEPTING:
      return <RouterProvider router={createBrowserRouter(adminAcceptingScientificLinksExtends)}
                             fallbackElement={<GlobalLoader/>}/>;
    case RoleFunction.ROLE_SCIENTIFIC:
      return <RouterProvider router={createBrowserRouter(adminAcceptingScientificLinksExtends)}
                             fallbackElement={<GlobalLoader/>}/>;
    case RoleFunction.ROLE_PUBLISHING:
      return <RouterProvider router={createBrowserRouter(publishingUserLinksExtends)}
                             fallbackElement={<GlobalLoader/>}/>;
    case RoleFunction.ROLE_PARTICIPANT:
      return <RouterProvider router={createBrowserRouter(viewingUserLinksExtends)} fallbackElement={<GlobalLoader/>}/>;
    default:
      return <RouterProvider router={createBrowserRouter(guestLinksExtends)} fallbackElement={<GlobalLoader/>}/>;
  }
});

import * as yup from "yup";

export const placesOfDetectionSelect = [{
  id: 1,
  title: 'POZ'
}, {
  id: 2,
  title: 'Szpital'
}, {
  id: 3,
  title: 'Poradnia'

}, {
  id: 4,
  title: 'Akcja Screeningowa'
}, {
  id: 5,
  title: 'Laboratorium'
}, {
  id: 6,
  title: 'Punkt anonimowego testowania'
}, {
  id: 7,
  title: 'Inne'
}]

export const previouslyTreatedCountSelect = [{
  id: 1,
  title: '1'
}, {
  id: 2,
  title: '2'
}, {
  id: 3,
  title: '3'

}, {
  id: 4,
  title: '4'
}, {
  id: 5,
  title: '5'
}, {
  id: 6,
  title: '6'
}, {
  id: 7,
  title: '7'
}, {
  id: 8,
  title: '8'
}, {
  id: 9,
  title: '9'
}, {
  id: 10,
  title: '10'
}]

export const metavirSelect = [{
  id: 'F0',
  title: 'F0'
}, {
  id: 'F0-F1',
  title: 'F0-F1'
}, {
  id: 'F1',
  title: 'F1'
}, {
  id: 'F2',
  title: 'F2'
}, {
  id: 'F3',
  title: 'F3'
}, {
  id: 'F3-F4',
  title: 'F3-F4'
}, {
  id: 'F4',
  title: 'F4'
}, {
  id: 'brak informacji',
  title: 'brak informacji'
}]

export const rnaUnitSelect = [
  {id: 'IU/ml', title: 'IU/ml'},
  {id: 'kopii/ml', title: 'kopii/ml'}
]

export const genotypeSelect = [{
  id: 'GT1 (bez subgenotypu)',
  title: 'GT1 (bez subgenotypu)'
},
  {

    id: 'GT1a',
    title: 'GT1a'
  },
  {
    id: 'GT1b',
    title: 'GT1b'
  },
  {
    id: 'GT2',
    title: 'GT2'
  },
  {
    id: 'GT3',
    title: 'GT3'
  },
  {
    id: 'GT4',
    title: 'GT4'
  },
  {
    id: 'GT5',
    title: 'GT5'
  }, {
    id: 'GT6',
    title: 'GT6'
  },
  {
    id: 'GT mieszany',
    title: 'GT mieszany'
  },
  {
    id: 'GT nieznany',
    title: 'GT nieznany'
  }


]

export const validationSchema = yup.object().shape({
  dateOfDetection: yup
    .string()
    .test('dateOfDetection', 'Should not be empty', function (value) {
      return !!value
    }),
  placeOfDetection: yup
    .number()
    .min(0)
    .required(),
  routeOfInfection: yup
    .string()
    .required(),
  routeOfInfectionDesc: yup
    .string()
    .test('isRouteOfInfectionKnown', 'Pole routeOfInfectionDesc jest wymagane, gdy routeOfInfection jest znana.', function (value) {
      const routeOfInfection = this.parent.routeOfInfection;
      if (routeOfInfection === 'znana droga zakażenia') {
        return !!value;
      }
      return true;
    }),
  scaleScore: yup
    .string()
    .required(),
  metavirDesc: yup
    .string()
    .test('isSelectedScaleScore', 'Pole metavirDesc jest wymagane, gdy scaleScore jest w skali metavir.', function (value) {
      const scaleScore = this.parent.scaleScore;
      if (scaleScore === 'wynik w skali METAVIR') {
        return !!value;
      }
      return true;
    }),
  apriDesc: yup
    .string()
    .test('isSelectedScaleScore', 'Pole apriDesc jest wymagane, gdy scaleScore jest apri.', function (value) {
      const scaleScore = this.parent.scaleScore;
      if (scaleScore === 'APRI lub FIB-4') {
        return !!value;
      }
      return true;
    }),
  clinicalSignsOfCirrhosis: yup
    .string(),
  extrahepaticManifestationsOfHCV: yup
    .string(),
  hcvRna: yup
    .string(),
  hcvRnaUnit: yup
    .string()
    .test('is-not-empty', 'hcvRnaUnit is required if hcvRna is filled', function (value) {
      const hcvRna = this.resolve(yup.ref('hcvRna'));
      if (hcvRna) {
        return !!value;
      }
      return true;
    }),
  rbc: yup
    .string()
    .required(),
  hgb: yup
    .string()
    .required(),
  htc: yup
    .string()
    .required(),
  plt: yup
    .string()
    .required(),
  wbc: yup
    .string()
    .required(),
  mcv: yup
    .string()
    .required(),
  mch: yup
    .string()
    .required(),
  mchc: yup
    .string()
    .required(),
  aiat: yup
    .string()
    .required(),
  albumin: yup
    .string()
    .required(),
  bilirubin: yup
    .string()
    .required(),
  prothrombin: yup
    .string()
    .required(),
  prothrombinUnit: yup
    .string()
    .required(),
  creatinine: yup
    .string()
    .required(),
  egfr: yup
    .string()
    .required(),
  antiHiv: yup
    .string()
    .required(),
  hbs: yup
    .string()
    .required(),
  abdominalUltrasound: yup
    .string(),
  abdominalUltrasoundCheckbox: yup
    .boolean(),
  liverFibrosis: yup
    .string()
    .required(),
  fibroSkanDesc: yup
    .string()
    .test('isSelectedliverFibrosis1', 'Pole fibroSkanDesc jest wymagane, gdy liverFibrosis jest FibroSkan/Elastografia.', function (value) {
      const liverFibrosis = this.parent.liverFibrosis;
      if (liverFibrosis === 'FIBROSKAN/ELASTIGRAFIA [kPa]') {
        return !!value;
      }
      return true;
    }),
  apriLiverDesc: yup
    .string()
    .test('isSelectedliverFibrosis2', 'Pole apriLiverDesc jest wymagane, gdy liverFibrosis jest APRI.', function (value) {
      const liverFibrosis = this.parent.liverFibrosis;
      if (liverFibrosis === 'APRI') {
        return !!value;
      }
      return true;
    }),
  fibLiverDesc: yup
    .string()
    .test('isSelectedliverFibrosis3', 'Pole fibLiverDesc jest wymagane, gdy liverFibrosis jest FIB-4.', function (value) {
      const liverFibrosis = this.parent.liverFibrosis;
      if (liverFibrosis === 'FIB-4') {
        return !!value;
      }
      return true;
    }),
  liverBiopsy: yup
    .boolean(),
  liverBiopsyDesc: yup
    .string()
    .test('isSelectedLiverBiopsy', 'Pole liverBiopsyDesc jest wymagane, gdy liverBiopsy jest true', function (value) {
      const liverBiopsy = this.parent.liverBiopsy;
      if (liverBiopsy === true) {
        return !!value;
      }
      return true;
    }),
  liverBiopsyCheckbox: yup
    .boolean(),
  hcvGenotype: yup
    .string(),
  hcvGenotypeMix: yup
    .string()
    .test('ishcvGenotypeMix', 'Pole hcvGenotypeMix jest wymagane, gdy hcvGenotype jest GT mieszany', function (value) {
      const liverBiopsy = this.parent.hcvGenotype;
      if (liverBiopsy === 'GT mieszany') {
        return !!value;
      }
      return true;
    }),
  presenceOfComorbidities: yup
    .string(),
  dataHiv: yup
    .string(),
  dataHbv: yup
    .string(),
  previousHistory: yup
    .string()
    .required(),
  otherElements: yup
    .array()
    .min(1)
    .required(),
  otherElementsDesc: yup
    .string()
    .test('isSelectedOther', 'Pole otherElementsDesc jest wymagane, gdy otherElements zawiera inne', function (value) {
      const otherElements = this.parent.otherElements;
      if (otherElements?.includes('inne')) {
        return !!value;
      }
      return true;
    }),
  patientPreviouslyTreatedWithAntivirals: yup
    .string()
    .required(),
  patientPreviouslyTreatedCount: yup
    .number()
    .when('patientPreviouslyTreatedWithAntivirals', {
      is: 'tak',
      then: (schema) => schema.min(1),
    }),
  patientPreviouslyTreatedItems: yup
    .array()
    .of(
      yup.object().shape({
        year: yup.string().required('Rok jest wymagany'),
        description: yup.string().required('Rok jest wymagany'),
        response: yup.string().required('Rok jest wymagany')
      })
    ),
  drugs: yup
    .array()
    .min(1)
    .of(
      yup.object().shape({
        drugName: yup.string().required('drugName jest wymagany'),
        drugDose: yup.string().required('drugDose jest wymagany'),
      }))
    .required(),
  potentialDrugInteractions: yup
    .string()
    .required(),
})
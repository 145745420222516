import { MouseEvent, ReactNode, useCallback, useEffect, useState } from 'react'
import * as yup from "yup";
import { RegexRules } from "../../../../utils";
import { useFormik } from "formik";
import { ValidationMessages } from '../../../../constants/validationMessages'
import { useModal } from "../../modal/useModal.vm";
import { IUserForm } from "../../../../interfaces/admin/user/userForm";
import { useRolesApi } from "../../../../apiHooks/useRolesApi";
import { useRolesStore } from "../../../../store/hooks/useRolesStore";
import { IOption } from '../../../../interfaces/common/form/option';
import { useAreasStore } from "../../../../store/hooks";
import { useAreasApi } from "../../../../apiHooks";
import { SelectChangeEvent } from "@mui/material";

export const validationSchema = yup.object().shape({
  fullName: yup
    .string()
    .required(),
  email: yup
    .string()
    .matches(RegexRules.EMAIL)
    .required(),
  phone: yup
    .string()
    .matches(/^(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}$/u)
});

export const useUserForm = (actionHandler: (userForm: IUserForm) => Promise<string | null>, userForm?: IUserForm | null) => {
  const rolesApi = useRolesApi();
  const rolesStore = useRolesStore()
  const areasStore = useAreasStore()
  const {getRoles} = rolesApi;
  const {getAreas} = useAreasApi()
  const {roles, isRolesLoaded} = rolesStore;
  const {areas, isAreasLoaded} = areasStore;
  const {handleModal, isModalOpened} = useModal()
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [message, setMessage] = useState('')
  const [selectValue, setValue] = useState(-1)
  const [selectedAreas, setSelectedAreas] = useState<number[]>([])
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: userForm ? userForm.fullName : '',
      email: userForm ? userForm.email : '',
      phone: userForm ? userForm.phone : '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, {resetForm}) => {

      if (selectValue !== -1) {
        const userFormNew: IUserForm = {
          areas: userForm ? userForm.areas : selectedAreas,
          id: userForm ? userForm.id : -1,
          roleId: selectValue,
          fullName: values.fullName,
          email: values.email,
          phone: values.phone.toString()
        }
        const message = await actionHandler(userFormNew);
        if (message === null) {
          values.fullName = userForm ? userForm.fullName : '';
          values.email = userForm ? userForm.email : '';
          values.phone = userForm ? userForm.phone : '';
          setSelectedAreas([])
        } else {
          setMessage(message);
        }

        resetForm()
      }
    }
  })

  const createErrorMessage = useCallback(() => {
    if (Object.keys(formik.errors).length > 0 || selectValue === -1 || selectedAreas.length === 0) {
      return ValidationMessages.INVALID_OR_EMPTY_FORM + ' ' + ValidationMessages.INVALID_FORM;
    }

    return ""
  }, [formik.errors, selectValue, selectedAreas])

  useEffect(() => {
    setValue(userForm ? userForm.roleId : -1)
  }, [userForm])

  useEffect(() => {
    void getAreas()
  }, [getAreas])

  useEffect(() => {
    if (formik.submitCount !== 0) {
      setMessage(createErrorMessage());
      const isValid = Object.keys(formik.errors).length === 0 && selectValue !== -1;
      setIsButtonActive(isValid);
    } else {
      const isValid = !!formik.values.email && !!formik.values.fullName && selectValue !== -1;
      setIsButtonActive(isValid);
    }
  }, [createErrorMessage, formik.errors, formik.submitCount, selectValue, formik.values.email, formik.values.fullName])

  useEffect(() => {
    if (!isRolesLoaded) {
      void getRoles(false)
    }

  }, [isRolesLoaded, getRoles])

  useEffect(() => {
    return () => rolesStore.clearRoles();
  }, [rolesStore])

  const selectOption = (value: number | string) => {

    const convertValue = typeof value === 'string' ? Number.parseInt(value) : value;
    if (convertValue) {
      setValue(convertValue)
    }
  }
  const selectAreasOptions: IOption[] = areas ? [...areas.map(area => ({title: area.fullTitle, id: area.id}))] : [];
  const handleChange = (event: SelectChangeEvent<number[]>, child: ReactNode) => {
    const selectedValues = event.target.value as number[];

    if (selectedValues.includes(0)) {
      if (selectedAreas.includes(0)) {
        setSelectedAreas(selectedValues.filter(value => value !== 0));
      } else {
        if (selectAreasOptions) {
          setSelectedAreas([...selectAreasOptions.map((option: IOption) => option.id), 0]);
        }
      }
    } else {
      if (selectedAreas.includes(0)) {
        setSelectedAreas([]);
      } else {
        setSelectedAreas(selectedValues);
      }
    }
  };

  const confirmForm = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    formik.handleSubmit()
  }

  const selectOptions: IOption[] = roles ? roles.map(role => ({title: role.title, id: role.id})) : [];

  return {
    formik,
    selectOptions,
    isButtonActive,
    confirmForm,
    message,
    selectOption,
    selectValue,
    handleModal,
    isModalOpened,
    selectAreasOptions,
    areas, isAreasLoaded, handleChange, selectedAreas
  }
}
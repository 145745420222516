import React from 'react'
import { IOptionString } from '../../../../interfaces/common/form/option';
import { RequiredStar, SelectContainer, StyledSelect } from "./SelectString.styled";
import { Label } from '../label/Label';

interface IProps {
  selectValue: string;
  elementName: string;
  setValue: (value: string) => void;
  optionsArray: IOptionString[];
  defaultValue?: string;
  label?: string;
  isRequired?: boolean;
  isError?: boolean;
  disabled?: boolean;
  color?: string;
  colorLabel?: string;
  borderColor?: string;
  background?: string;
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
}

export const SelectString: React.FC<IProps> = ({
                                                 label,
                                                 isError,
                                                 isRequired,
                                                 selectValue,
                                                 elementName,
                                                 defaultValue,
                                                 setValue,
                                                 optionsArray,
                                                 color,
                                                 colorLabel,
                                                 borderColor,
                                                 background,
                                                 onBlur,
                                                 disabled
                                               }) => {
  return (
    <SelectContainer>
      {label && (
        <Label color={colorLabel} size={'small'} label={<>
          {label}
          {isRequired && <RequiredStar color={colorLabel}>*</RequiredStar>}
        </>}/>
      )}
      <StyledSelect
        name={elementName}
        value={selectValue}
        onBlur={onBlur}
        disabled={disabled}
        onChange={(e) => setValue(e.target.value)}
        id={elementName}
        $border={borderColor}
        $background={background}
        $color={color}
        $isError={isError || false}
      >
        <option value={''}>{defaultValue}</option>
        {optionsArray?.map((option, index: number) => (
          <option key={index} value={option.id}>
            {option.title}
          </option>
        ))}
      </StyledSelect>
    </SelectContainer>
  )
}

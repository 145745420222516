import { FooterElement, FooterElements, FooterStyled, Separator } from './Footer.styled';
import { Container } from '../UserLayout.styled';

export const Footer = () => {
  return <FooterStyled>
    <Container>
      <FooterElements>
        <FooterElement>
          <a href={'/'}>Kontakt</a>
        </FooterElement>
        <Separator>|</Separator>
        <FooterElement>
          <a href={require('./../../../../../../src/files/policy.pdf')} target={'_blank'} rel="noreferrer">Polityka
            prywatności</a>
        </FooterElement>
        <Separator>|</Separator>
        <FooterElement>
          <a href={require('./../../../../../../src/files/rules.pdf')} target={'_blank'} rel="noreferrer">Regulamin
            serwisu</a>
        </FooterElement>
        <Separator>|</Separator>
        <FooterElement>
          <a href={'https://alertpolska.pl/'} target={'_blank'} rel='noreferrer'>Zgłoś działanie niepożądane</a>
        </FooterElement>
      </FooterElements>
    </Container>
  </FooterStyled>
}
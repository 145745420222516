import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './components/App';
import {ErrorBoundary} from 'react-error-boundary';
import {RootStateProvider} from './store/RootStateContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <RootStateProvider>
        <ErrorBoundary fallback={<p>Something went wrong. Try again later.</p>} >
            <App />
        </ErrorBoundary>
    </RootStateProvider>
);
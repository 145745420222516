import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useWindowDimensionsStore } from '../../../store/hooks';
import { OnlyDesktop } from '../onlyDesktop/OnlyDesktop';
import { useWindowDimensions } from './useWindowDimensions';

export const WindowDimensionsController = observer(() => {
  const windowDimensionsStore = useWindowDimensionsStore();
  const {width, height} = windowDimensionsStore;
  const {currentWindowWidth, currentWindowHeight} = useWindowDimensions();

  const countOrientation = useCallback(() => {
    if (currentWindowWidth !== width) {
      windowDimensionsStore.setWidth(currentWindowWidth);
      windowDimensionsStore.setDisplaySize(currentWindowWidth);
    }
    if (currentWindowHeight !== height) {
      windowDimensionsStore.setHeight(currentWindowHeight);
    }
    windowDimensionsStore.setDisplayOrientation(currentWindowWidth, currentWindowHeight);
  }, [currentWindowWidth, currentWindowHeight, width, height, windowDimensionsStore]);

  useEffect(() => {
    countOrientation();
  }, [countOrientation]);

  if (currentWindowWidth <= 1279) {
    return <OnlyDesktop/>;
  } else {
    return null;
  }
});

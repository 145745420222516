import React, { FC } from "react";

import { LabelContainer, LabelInsideContainer } from "./Label.styled";
import { ILabelFieldsetProps } from '../../../../interfaces/ui/uiInfo'

export interface IProps extends ILabelFieldsetProps {
  isRequired?: boolean;
  supLabel?: JSX.Element;
  content?: string | JSX.Element;
}

export const Label: FC<IProps> = ({
                                    supLabel,
                                    isUpperCase = true,
                                    color,
                                    label,
                                    isRequired,
                                    content,
                                    size,
                                    textAlign
                                  }) => {
  return (
    <LabelContainer $isUpperCase={isUpperCase} color={color} size={size} $textAlign={textAlign}>
      <LabelInsideContainer>
        <div>{label}{supLabel}{isRequired && <span>*</span>}</div>
      </LabelInsideContainer>
      {content}
    </LabelContainer>
  )
}
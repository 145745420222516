import React from 'react';
import {RouteObject} from 'react-router-dom';

import {AdminLink} from '../../../../../utils';
import {Cases} from '../../../../pages/admin/cases/Cases';
import {HistoryItems} from '../../../../pages/admin/cases/historyItems/HistoryItems';
import {PublishingUsers} from '../../../../pages/admin/publishingUsers/PublishingUsers';
import {generalLinks} from './RoutesGuest';
import {Redirector} from '../../../../pages/admin/redirector/Redirector';
import {ViewCase} from '../../../../pages/user/viewCase/ViewCase';

export const adminAcceptingScientificLinks : RouteObject[] = [
  {
    path: AdminLink.REDIRECTOR,
    element: <Redirector/>
  },
  {
    path: AdminLink.CASES,
    element: <Cases/>
  },
  {
    path: AdminLink.HISTORY,
    element: <HistoryItems/>
  },
  {
    path: AdminLink.PUBLISHING_USERS,
    element: <PublishingUsers/>
  },
  {
    path: AdminLink.VIEW_CASE,
    element: <ViewCase/>
  },
]


export const adminAcceptingScientificLinksExtends = adminAcceptingScientificLinks.concat(generalLinks);